import {useApi} from "../../lib/ApiContext";
import React, {useState} from "react";
import {ChevronDown} from "react-feather";
import {GrundanspruchItemDialog} from "./GrundanspruchItemDialog";
import {useDialogs} from "../../lib/DialogsContext";

export function BoardItem({item, highlight, loadItems, reloadList}) {
  const api = useApi();
  const [isMenuActive, setIsMenuActive] = useState(false);
  const dialogs = useDialogs();

  const createHandleRemoveItem = item => () => {
    if (window.confirm('Wirklich löschen?')) {
      api.boardItems().deleteBoardItemItem(item.boardItemId)
      .then(() => loadItems())
      .catch(err => console.error(err));
    }
  };

  const contextMenu = <div className="is-pulled-right" style={{marginRight: '-0.7rem', marginTop: '-0.7rem'}}>
    <div className={`dropdown is-right ${isMenuActive ? 'is-active' : ''}`} onClick={() => setIsMenuActive(isActive => !isActive)}>
      <div className="dropdown-trigger">
        <button className="button is-small is-text" aria-haspopup="true" aria-controls={`item_menu_${item.boardItemId}`}>
          <span className="icon is-small"><ChevronDown size={14}/></span>
        </button>
      </div>
      <div className="dropdown-menu" id={`item_menu_${item.boardItemId}`} role="menu">
        <div className="dropdown-content has-background-grey-lighter">
          <a onClick={createHandleRemoveItem(item)} className="dropdown-item">Aus Liste entfernen</a>
        </div>
      </div>
    </div>
  </div>;

  const handleOnClick = () => {
    dialogs.open(<GrundanspruchItemDialog boardItemId={item.boardItemId} reloadList={reloadList} onClose={dialogs.close}/>);
  }

  return <div className={`board-item is-grundanspruch box mb-2 is-size-7 ${highlight && 'has-background-success-light'}`}>
    {contextMenu}
    <h5 className="heading">{item.grundanspruch.code.full}</h5>
    <p onClick={handleOnClick}>{item.grundanspruch.texts[0]}</p>
  </div>
}
