import { useApi } from "../../lib/ApiContext";
import React, { useEffect, useState } from "react";
import { GrundanspruecheList } from "../Curriculum/GrundanspruecheList";

export function AddGrundanspruchDialog({boardListId, pupilId, onListChange, activeFachbereich = null, onClose}) {
  const api = useApi();

  const [pupil, setPupil] = useState(null);

  const loadPupil = () => {
    api.pupils().getPupilItem(pupilId).then(rsp => setPupil(rsp.data)).catch(err => console.error(err));
  };

  useEffect(() => {
    loadPupil();
  }, []);

  let title = 'Lade ...';
  if (pupil) {
    if (activeFachbereich) {
      title = `Grundanspruch zu ${activeFachbereich.name} hinzufügen`;
    } else {
      title = `Grundanspruch hinzufügen`;
    }
  }

  return <div className="modal is-active">
    <div className="modal-background" onClick={onClose}/>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{title}</p>
        <button className="delete" aria-label="close" onClick={onClose}/>
      </header>
      <section className="modal-card-body">
        {pupil !== null && <GrundanspruecheList boardListId={boardListId} pupil={pupil} loadPupil={loadPupil} onListChange={onListChange} activeFachbereich={activeFachbereich}/>}
      </section>
    </div>
  </div>
}