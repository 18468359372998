import React, {createRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import {BoardItem} from "./BoardItem";

export function DraggableBoardItem({list, itemIndexes, item, changeItemPosition, loadItems, reloadList}) {
  const domRef = createRef();

  const [{isDragging}, dragRef] = useDrag({
    item: {
      type: 'board-item',
      boardItemId: item.boardItemId,
      boardListId: list.boardListId,
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{isOver, canDrop}, dropRef] = useDrop({
    accept: 'board-item',
    hover: ({boardListId, boardItemId}, monitor) => {
      if (boardListId !== list.boardListId || boardItemId === item.boardItemId) {
        return; // ignore other lists and self
      }

      const dragIndex = itemIndexes[boardItemId];
      const hoverIndex = itemIndexes[item.boardItemId];

      //console.log('item', boardItemId, '(', itemIndexes[boardItemId], ')', 'is hovering over', item.boardItemId, '(', itemIndexes[item.boardItemId], ')');

      const hoverBoundingRect = domRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      //console.log('move item to after', hoverIndex);

      changeItemPosition(boardItemId, hoverIndex);
    },
    canDrop: ({boardListId, boardItemId}) => boardListId === list.boardListId && boardItemId !== item.boardItemId,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const dndRef = el => {
    dragRef(el);
    dropRef(el);
  };

  return <div ref={domRef}>
    <div ref={dndRef}>
      <div style={{visibility: isDragging ? 'hidden' : 'visible'}}>
        <BoardItem item={item} reloadList={reloadList} highlight={isOver && canDrop} loadItems={loadItems}/>
      </div>
    </div>
  </div>
}
