import {DialogSubtitle, MarkdownText, SmallTextButton} from "../../components";
import React, {useEffect, useState} from "react";
import {useApi} from "../../lib/ApiContext";
import {Documents} from "../../components/Documents";
import {Form, FormErrors, TextareaInput} from "../../components/form";
import {formatDate, formatShortDate, generateDates} from "../../lib/time";

export function ScheduleDialog({scheduleId, pupil, onClose}) {
  const api = useApi();
  const [schedule, setSchedule] = useState(null);
  const [journal, setJournal] = useState(null);

  const loadSchedule = () => {
    api.schedules().getScheduleItem(scheduleId)
    .then(rsp => rsp.data)
    .then(schedule => {
      setSchedule(schedule);
      return schedule;
    })
    .then(schedule => loadJournal(schedule))
    .catch(err => console.error(err));
  };

  const reloadJournal = () => loadJournal(schedule);

  const loadJournal = schedule => {
    api.journal().getJournalEntryCollection(pupil.pupilId, undefined, schedule.endsAt, undefined, schedule.startsAt)
    .then(rsp => setJournal(rsp.data))
    .catch(err => console.error(err));
  };

  useEffect(() => {
    loadSchedule();
  }, []);

  const isLoaded = schedule !== null && journal !== null;

  return <div className="modal is-active">
    <div className="modal-background" onClick={onClose}/>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{isLoaded ? `${schedule.label} (${formatShortDate(schedule.startsAt)} - ${formatShortDate(schedule.endsAt)})` : 'Lade ...'}</p>
        <button className="delete" aria-label="close" onClick={onClose}/>
      </header>
      <section className="modal-card-body">
        {isLoaded && <Tabs schedule={schedule} journal={journal} pupil={pupil} loadSchedule={loadSchedule} reloadJournal={reloadJournal}/>}
      </section>
    </div>
  </div>;
}

function Tabs({schedule, journal, pupil, loadSchedule, reloadJournal}) {
  const tabs = {
    Planung: <PlanTab schedule={schedule} loadSchedule={loadSchedule}/>,
    Journal: <JournalTab schedule={schedule} pupil={pupil} journal={journal} reloadJournal={reloadJournal}/>,
  };
  
  const [tab, setTab] = useState(Object.keys(tabs)[0]);

  return <>
    <div className="tabs is-boxed">
      <ul>
        {Object.keys(tabs).map(label => <li key={label} className={tab === label ? 'is-active' : ''}><a onClick={() => setTab(label)}>{label}</a></li>)}
      </ul>
    </div>
    {tabs[tab]}
  </>
}

function PlanTab({schedule, loadSchedule}) {
  const api = useApi();

  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({
    plan: schedule.plan,
  });
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    setIsSaving(true);
    setErrors({});

    api.schedules()
    .patchScheduleItem(schedule.scheduleId, data)
    .then(() => loadSchedule())
    .then(() => setIsEditing(false))
    .catch(api.createErrorHandler(setErrors))
    .finally(() => setIsSaving(false));
  };

  const handleNewFile = upload => {
    api.schedules().patchScheduleItem(schedule.scheduleId, {
      files: schedule.files.map(upload => `/uploads/${upload.uploadId}`).concat(`/uploads/${upload.uploadId}`),
    })
    .then(() => loadSchedule())
    .catch(api.createErrorHandler(setErrors))
  };

  return <>
    <DialogSubtitle first>Planung</DialogSubtitle>
    {isEditing && <Form data={data} errors={errors} onChange={setData}>
      <FormErrors/>
      <TextareaInput name="plan" rows={15}/>
      <div className="buttons">
        <button className={`button is-small is-primary ${isSaving ? 'is-loading' : ''}`} disabled={isSaving} onClick={handleSave}>Speichern</button>
        <button className="button is-small is-text" disabled={isSaving} onClick={() => setIsEditing(false)}>Verwerfen</button>
      </div>
    </Form>}

    {!isEditing && <div>
      <MarkdownText text={schedule.plan}/>
      <SmallTextButton label="Editieren" type="edit" onClick={() => setIsEditing(true)}/>
    </div>}

    <DialogSubtitle>Unterrichtsmaterial</DialogSubtitle>
    <Documents uploads={schedule.files} onUploaded={handleNewFile} tag="resource" onUploadRemoved={() => loadSchedule()}/>

    <DialogSubtitle>Arbeiten</DialogSubtitle>
    <Documents uploads={schedule.files} onUploaded={handleNewFile} tag="work" onUploadRemoved={() => loadSchedule()}/>
  </>
}

function JournalTab({schedule, journal, pupil, reloadJournal}) {
  const daysInSchedule = generateDates(schedule.startsAt, schedule.endsAt);

  const journalByDate = {};
  for (const entry of journal) {
    journalByDate[entry.date] = entry;
  }

  return <>
    <DialogSubtitle>Journal</DialogSubtitle>
    {daysInSchedule.map(date => {
      const entry = journalByDate[date] || null;

      return <div key={date}>
        <JournalEntry date={date} text={entry ? entry.text : ''} entryId={entry ? entry.journalEntryId : null}
                      files={entry ? entry.files : []} pupilId={pupil.pupilId} reloadJournal={reloadJournal}/>
      </div>;
    })}

    {schedule.journal ? <MarkdownText text={schedule.journal}/> : null}
  </>
}

function JournalEntry({pupilId, entryId, date, text, files, reloadJournal}) {
  const api = useApi();

  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({
    text: text,
  });
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    setIsSaving(true);
    setErrors({});

    if (entryId) {
      api.journal()
      .patchJournalEntryItem(entryId, data)
      .then(() => setIsEditing(false))
      .then(() => reloadJournal())
      .catch(api.createErrorHandler(setErrors))
      .finally(() => setIsSaving(false));
    } else {
      api.journal()
      .postJournalEntryCollection({
        ...data,
        pupil: `/pupils/${pupilId}`,
        date,
      })
      .then(() => setIsEditing(false))
      .then(() => reloadJournal())
      .catch(api.createErrorHandler(setErrors))
      .finally(() => setIsSaving(false));
    }
  };

  const handleDelete = () => {
    if (window.confirm('Wirklich löschen?')) {
      api.journal()
      .deleteJournalEntryItem(entryId)
      .then(() => setIsEditing(false))
      .then(() => reloadJournal())
      .catch(api.createErrorHandler(setErrors))
      .finally(() => setIsSaving(false));
    }
  };

  const handleNewFile = upload => {
    api.journal().patchJournalEntryItem(entryId, {
      files: files.map(upload => `/uploads/${upload.uploadId}`).concat(`/uploads/${upload.uploadId}`),
    })
    .then(() => reloadJournal())
    .catch(api.createErrorHandler(setErrors))
  };

  return <div>
    <div className="mt-4 mt-1 is-flex is-align-items-center">
      <h5 className="title is-6 mt-0 mb-0">{formatDate(date)}</h5>
      {entryId !== null && <div className="ml-3">
        <SmallTextButton type="edit" onClick={() => setIsEditing(true)}/>
        <SmallTextButton type="delete" onClick={handleDelete}/>
      </div>}
    </div>

    {isEditing && <Form data={data} errors={errors} onChange={setData}>
      <FormErrors/>
      <TextareaInput name="text" rows={10}/>
      <div className="buttons">
        <button className={`button is-small is-primary ${isSaving ? 'is-loading' : ''}`} disabled={isSaving} onClick={handleSave}>Speichern</button>
        <button className="button is-small is-text" disabled={isSaving} onClick={() => setIsEditing(false)}>Verwerfen</button>
      </div>
    </Form>}

    {!isEditing && entryId !== null && <>
      <MarkdownText text={text}/>
      <Documents mode="list" uploads={files} onUploaded={handleNewFile} onUploadRemoved={() => reloadJournal()}/>
    </>}
    {!isEditing && entryId === null && <SmallTextButton label="Eintrag erfassen" type="add" onClick={() => setIsEditing(true)}/>}
  </div>
}