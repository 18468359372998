import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { useApi, useDialogs, useUser } from "../lib/contexts";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { BoardList } from "./Board/BoardList";
import { ActionButton, Loader, PageTitle } from "../components";
import Layout from "./Layout";
import { Link, useHistory } from "react-router-dom";
import { Search } from "react-feather";
import { AddGrundanspruchDialog } from "./Board/AddGrundanspruchDialog";

export default function BoardScreen() {
  const api = useApi();
  const user = useUser();
  const params = useParams();
  const dialogs = useDialogs();
  const history = useHistory();

  const pupils = user.household.pupils;
  const selectedPupil = pupils.filter(p => p.slug === params.pupilSlug)[0];

  const activeFachbereichId = params.fachbereichId || null;

  const [searchFilter, setSearchFilter] = useState('');
  const [fachbereiche, setFachbereiche] = useState(null);

  const lists = user.household.boardLists;

  // each list starts with a seed of 0. when a list is reloaded from another list we increase the seed which triggers a re-render.
  const [listSeeds, setListSeeds] = useState(Object.fromEntries(user.household.boardLists.map(list => [list.boardListId, 0])));
  const reloadList = listId => {
    setListSeeds(listSeeds => ({
      ...listSeeds,
      [listId]: listSeeds[listId] + 1,
    }))
  };

  useEffect(() => {
    api.fachbereiche().getFachbereichCollection().then(rsp => setFachbereiche(rsp.data)).catch(err => console.log(err));
  }, []);

  if (fachbereiche === null) {
    return <Layout activeMenuKey="board">
      <Loader/>
    </Layout>
  }

  let activeFachbereich = null;
  if (fachbereiche.length > 0 && activeFachbereichId) {
    activeFachbereich = fachbereiche.filter(f => f.fachbereichId === activeFachbereichId)[0];
  }

  const pageTitle = 'Board ' + (activeFachbereich !== null ? `» ${activeFachbereich.name}` : '');

  const handleAddGrundanspruch = () => {
    const handleListChange = listId => {
      reloadList(listId);
    }

    dialogs.open(<AddGrundanspruchDialog activeFachbereich={activeFachbereich} pupilId={selectedPupil.pupilId} onListChange={handleListChange} onClose={dialogs.close}/>);
  };

  return <Layout activeMenuKey="board">
    <section id="header">
      <div className="select is-fullwidth">
        <select className="has-text-centered" value={activeFachbereichId?.toString()} onChange={e => {
          let url = `/${selectedPupil.slug}`;
          if (e.target.value !== '') {
            url += `/board/${e.target.value}`;
          }
          history.push(url);
        }}>
          <option value="">Alle Fachbereiche</option>
          {fachbereiche.map(fachbereich => (
            <option key={fachbereich.fachbereichId} value={fachbereich.fachbereichId}>{fachbereich.code.full}</option>
          ))}
        </select>
      </div>

      <div className="level">
        <div className="level-left level-menu">
          <div className="level-item">
            <Link to={`/${selectedPupil.slug}`} title="Alle Fachbereiche"
                  className={activeFachbereichId === null ? 'is-active' : ''}>Alle Fachbereiche</Link>
          </div>

          {fachbereiche.map(fachbereich => {
            const url = `/${selectedPupil.slug}/board/${fachbereich.fachbereichId}`;

            return <div key={fachbereich.fachbereichId} className="level-item">
              <Link key={fachbereich.fachbereichId} to={url} title={fachbereich.name}
                    className={activeFachbereichId === fachbereich.fachbereichId ? 'is-active' : ''}>{fachbereich.code.full}</Link>
            </div>
          })}
        </div>
        <div className="level-right">
          <div className="level-item">
            <Search size={20}/>
          </div>
          <div className="level-item">
            <input type="text" className="input is-text" placeholder="Textsuche ..." value={searchFilter} onChange={e => setSearchFilter(e.target.value)}/>
          </div>
        </div>
      </div>
    </section>
    
    <section id="content">
      <PageTitle title={pageTitle} actionButtons={<ActionButton label="Grundanspruch hinzufügen" onClick={handleAddGrundanspruch}/>}/>

      <div id="board">
        <DndProvider backend={HTML5Backend}>
          <div className="is-flex is-flex-direction-row">
            {lists.map(list => <BoardList key={list.boardListId} pupil={selectedPupil} list={list} listSeed={listSeeds[list.boardListId]}
                                          reloadList={reloadList} searchFilter={searchFilter} activeFachbereich={activeFachbereich}/>)}
          </div>
        </DndProvider>
      </div>
    </section>
  </Layout>
}



