import React from 'react';
import {useHistory} from "react-router-dom";
import {useUser} from "../lib/contexts";

export default function HomeScreen() {
  const user = useUser();
  const pupils = user.household.pupils;
  const history = useHistory();

  history.push(`/${pupils[0].slug}`);

  return null;
}



