import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {LogOut} from "react-feather";

export default function UserNotFoundScreen() {
  const {user} = useAuth0();
  const {logout} = useAuth0();

  return <>
    <section className="hero is-fullheight-with-navbar">
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="columns">
            <div className="column is-4 is-offset-4">
              <p className="mb-6" style={{fontSize: '3rem'}}>
                <span id="text_logo"><span className="inner">ko<strong>monk</strong></span></span>
              </p>

              <article className="message is-medium is-danger">
                <div className="message-body">
                  Es wurde leider kein Benutzer mit der E-Mail Adresse <strong>{user.email}</strong> gefunden.
                </div>
              </article>

              <a onClick={() => logout({returnTo: window.location.origin})} className="button is-large is-rounded">
                <LogOut className="mr-2"/> Abmelden
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}
