import {eachDayOfInterval, format} from "date-fns";

export const LOCALE = 'de-DE';

export function generateDates(start, end) {
  return eachDayOfInterval({
    start: new Date(start),
    end: new Date(end),
  }).map(date => format(date, 'yyyy-MM-dd'));
}

export function formatDateTime(str) {
  return formatDate(str) + ' ' + formatTime(str);
}

export function formatDate(str) {
  const date = new Date(str);

  return date.toLocaleDateString(LOCALE, {day: 'numeric', month: 'short', year: 'numeric', weekday: 'short'});
}

export function formatShortDate(str) {
  const date = new Date(str);

  return date.toLocaleDateString(LOCALE, {day: 'numeric', month: 'numeric', year: 'numeric'});
}

export function formatDay(str) {
  const date = new Date(str);

  return date.toLocaleDateString(LOCALE, {day: 'numeric', month: 'long', weekday: 'short'});
}

export function formatLongDay(str) {
  const date = new Date(str);

  return date.toLocaleDateString(LOCALE, {day: 'numeric', month: 'long', weekday: 'long', year: 'numeric'});
}

export function formatTime(str) {
  const date = new Date(str);

  return date.toLocaleTimeString(LOCALE, {hour: '2-digit', minute: '2-digit'});
}

export function formatPeriod(fromStr, toStr) {
  const from = new Date(fromStr);
  const to = new Date(toStr);
  
  const format = {day: 'numeric', month: 'long', year: 'numeric'};
  const toFormatted = to.toLocaleString(LOCALE, format);
  
  if (from.toLocaleString(LOCALE, format) === toFormatted) {
    return toFormatted;
  }
  
  if (from.getFullYear() === to.getFullYear()) {
    delete format.year;
  }

  return from.toLocaleDateString(LOCALE, format) + ' - ' + toFormatted;
}

export function formatTimeDiff(fromStr, toStr) {
  const from = new Date(fromStr);
  const to = new Date(toStr);
  
  let min = Math.floor((to.getTime() - from.getTime()) / 1000 / 60);
  let hrs = Math.floor(min / 60);
  min = min - hrs * 60;
  
  if (hrs === 0) {
    return min + ' min';
  }
  
  if (min === 0) {
    return hrs + ' h';
  }
  
  if (min < 10) {
    min = '0' + min;
  }
  
  return hrs + ' h ' + min;
}