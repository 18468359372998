import {DialogSubtitle, MarkdownText, SmallTextButton} from "../../components";
import React, {Fragment, useEffect, useState} from "react";
import {Form, FormErrors, TextareaInput} from "../../components/form";
import {useApi} from "../../lib/ApiContext";
import {formatDateTime} from "../../lib/time";
import {Clock, User} from "react-feather";
import {Documents} from "../../components/Documents";
import {useUser} from "../../lib/UserContext";

export function GrundanspruchItemDialog({boardItemId, reloadList, onClose}) {
  const api = useApi();

  const [item, setItem] = useState(null);

  const loadItem = () => {
    api.boardItems().getBoardItemItem(boardItemId).then(rsp => {
      setItem(rsp.data);
    }).catch(err => console.error(err));
  };

  useEffect(() => {
    loadItem();
  }, []);

  return <div className="modal is-active">
    <div className="modal-background" onClick={onClose}/>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{item ? item.grundanspruch.code.full : 'Lade ...'}</p>
        <button className="delete" aria-label="close" onClick={onClose}/>
      </header>
      <section className="modal-card-body">
        {item !== null && <DialogBody item={item} loadItem={loadItem} reloadList={reloadList} onClose={onClose}/>}
      </section>
    </div>
  </div>;
}

function DialogBody({item, loadItem, reloadList, onClose}) {
  const api = useApi();
  const user = useUser();

  const boardLists = user.household.boardLists;

  const {grundanspruch} = item;
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleNoteSaved = () => {
    setIsAddingComment(false);
    loadItem();
  };

  const handleNewFile = upload => {
    api.boardItems().patchBoardItemItem(item.boardItemId, {
      files: item.files.map(upload => `/uploads/${upload.uploadId}`).concat(`/uploads/${upload.uploadId}`),
    })
    .then(() => loadItem())
    .catch(err => console.error(err));
  };

  const createHandleRemoveNote = note => () => {
    if (window.confirm('Wirklich löschen?')) {
      api.boardItemNotes().deleteBoardItemNoteItem(note.boardItemNoteId)
      .then(() => loadItem())
      .catch(err => console.error(err));
    }
  };

  const handleMoveItem = e => {
    const newListId = e.target.value;
    const oldListId = item.list.boardListId;

    setIsSaving(true);
    api.boardItems().patchBoardItemItem(item.boardItemId, {
      list: `/board_lists/${newListId}`,
      sequence: 0,
    })
    .then(() => loadItem())
    .then(() => reloadList(newListId))
    .then(() => reloadList(oldListId))
    .catch(err => console.error(err))
    .finally(() => setIsSaving(false));
  };

  const handleRemoveItem = () => {
    if (window.confirm('Wirklich von Board entfernen? Allfällige Kommentare und Uploads gehen verloren.')) {
      setIsSaving(true);
      api.boardItems().deleteBoardItemItem(item.boardItemId)
      .then(() => reloadList(item.list.boardListId))
      .catch(err => console.error(err))
      .finally(() => onClose());
    }
  };

  return <>
    <div className="modal-actions">
      <div>
        <div className={`select is-small is-text ${isSaving ? 'is-loading' : ''}`}>
          <select value={item.list.boardListId} onChange={handleMoveItem} disabled={isSaving}>
            {boardLists.map(boardList => <option key={boardList.boardListId} value={boardList.boardListId}>{boardList.name}</option>)}
          </select>
        </div>
      </div>
      <div className="has-background-danger-light">
        <button className={`button is-small is-danger ${isSaving ? 'is-loading' : ''}`} disabled={isSaving} onClick={handleRemoveItem}>
          Entfernen
        </button>
      </div>
    </div>

    <DialogSubtitle first>Grundanspruch</DialogSubtitle>

    {grundanspruch.texts.map((text, idx) => <Fragment key={idx}>
      {idx !== 0 && <hr className="mt-1 mb-1"/>}
      <p>{text}</p>
    </Fragment>)}

    <DialogSubtitle>Dokumente</DialogSubtitle>
    <Documents uploads={item.files} onUploaded={handleNewFile} onUploadRemoved={() => loadItem()}/>

    <DialogSubtitle>Notizen / Kommentare / Fortschritt</DialogSubtitle>
    {isAddingComment ? <NoteForm item={item} onSaved={handleNoteSaved}/> : <div>
      <SmallTextButton label="Kommentar hinzufügen ..." onClick={() => setIsAddingComment(true)}/>
    </div>}
    {item.notes.map(note => <div key={note.boardItemNoteId} className="box p-1 mt-4">
      <MarkdownText text={note.text}/>
      <div className="heading is-inline-flex is-align-items-center">
        <User size={12} className="has-text-grey-light mr-1"/>
        <span className="mr-4">{note.author.name}</span>
        <Clock size={12} className="has-text-grey-light mr-1"/>
        <span className="mr-4">{formatDateTime(note.addedAt)}</span>
        <SmallTextButton label="Löschen" onClick={createHandleRemoveNote(note)} type="delete"/>
      </div>
    </div>)}
  </>
}

function NoteForm({item, onSaved}) {
  const api = useApi();

  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState({
    item: `/board_items/${item.boardItemId}`,
    text: '',
  });
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    setIsSaving(true);
    setErrors({});

    api.boardItemNotes()
    .postBoardItemNoteCollection(data)
    .then(() => onSaved())
    .catch(api.createErrorHandler(setErrors))
    .finally(() => setIsSaving(false));
  };

  return <Form data={data} errors={errors} onChange={setData}>
    <FormErrors/>
    <TextareaInput name="text" placeholder="Kommentar ..." rows={3}/>
    <div className="buttons">
      <button className={`button is-small is-primary ${isSaving ? 'is-loading' : ''}`} disabled={data.text.length === 0 || isSaving} onClick={handleSave}>
        Speichern
      </button>
    </div>
  </Form>
}
