import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {useApi, useDialogs, useUser} from "../lib/contexts";

import Layout from "./Layout";
import {Loader, PageTitle} from "../components";
import {ImageModal} from "../components/Documents";
import {formatLongDay} from "../lib/time";

export default function PicturesScreen() {
  const api = useApi();
  const dialogs = useDialogs();
  const user = useUser();
  const params = useParams();

  const pupils = user.household.pupils;
  const selectedPupil = pupils.filter(p => p.slug === params.pupilSlug)[0];

  const [files, setFiles] = useState(null);

  const loadFiles = () => {
    api.files().getUploadCollection().then(rsp => setFiles(rsp.data)).catch(err => console.log(err));
  };

  useEffect(() => {
    loadFiles();
  }, []);

  if (files === null) {
    return <Layout activeMenuKey="gallery">
      <Loader/>
    </Layout>
  }

  const imagesByDate = {};

  for (const file of files) {
    if (!file.image) {
      continue;
    }

    let pupil = null;
    let label = null;

    if (file.boardItems.length > 0) {
      const grundanspruch = file.boardItems[0].grundanspruch;
      pupil = file.boardItems[0].pupil;
      label = 'Grundanspruch ' + grundanspruch.code.full;
    } else if (file.schedules.length > 0) {
      pupil = file.schedules[0].pupil;
      label = 'Plan ' + file.schedules[0].label;
    } else if (file.journalEntries.length > 0) {
      pupil = file.journalEntries[0].pupil;
      label = 'Journal-Eintrag ' + formatLongDay(file.journalEntries[0].date);
    }

    if (!pupil || pupil.pupilId !== selectedPupil.pupilId) {
      continue;
    }

    const key = formatLongDay(file.uploadedAt);
    if (imagesByDate[key] === undefined) {
      imagesByDate[key] = [];
    }

    imagesByDate[key].push({
      label,
      date: file.uploadedAt,
      url: process.env.REACT_APP_API_DOMAIN + file.contentUrl,
    });
  }

  const createOnImageClick = image => () => dialogs.open(<ImageModal url={image.url} title={image.label} onClick={dialogs.close}/>);

  return <Layout activeMenuKey="gallery">
    <section id="content">
      <PageTitle title="Fotos"/>

      {Object.keys(imagesByDate).map(date => <div key={date}>
        <h2 className="title is-5">{date}</h2>

        <div className="columns is-multiline mb-5">
          {imagesByDate[date].map((image, idx) => <div key={idx} className="column is-4">
            <figure className="image is-1by1 is-clickable" onClick={createOnImageClick(image)}>
              <img src={image.url} alt={image.label} title={image.label} style={{objectFit: 'cover'}}/>
            </figure>
            <div className="heading">{image.label}</div>
          </div>)}
        </div>
      </div>)}
    </section>
  </Layout>
}
