import React, {Fragment} from 'react';
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import {useDialogs, useUser} from "../lib/contexts";
import {Calendar, FileText, Flag, Image, List, LogOut, Trello} from "react-feather";
import {useAuth0} from "@auth0/auth0-react";

export default function Layout({activeMenuKey, children}) {
  const user = useUser();
  const params = useParams();
  const dialogs = useDialogs();
  const {logout} = useAuth0();

  const pupils = user.household.pupils;
  const selectedPupil = pupils.filter(p => p.slug === params.pupilSlug)[0];

  const handlePupilClick = () => {
    dialogs.open(<PupilChooserDialog pupils={pupils} onClose={dialogs.close}/>);
  }

  const openedDialogs = dialogs.getAll();

  return <>
    {openedDialogs.length > 0 && openedDialogs.map((dialog, idx) => <Fragment key={idx}>{dialog}</Fragment>)}

    <section id="layout">
      <aside>
        <div id="user" className="is-clickable" onClick={handlePupilClick}>
          <img src={require(`../assets/images/${selectedPupil.avatar}`).default} width={40} height={40} alt={`Avatar von ${selectedPupil.name}`}/>
          <span>{selectedPupil.name}</span>
        </div>
        <div id="menu">
          <Link to={`/${selectedPupil.slug}`} className={activeMenuKey === 'curriculum' ? 'is-active' : ''}>
            <Flag size={30}/> Lehrplan
          </Link>
          <Link to={`/${selectedPupil.slug}/board`} className={activeMenuKey === 'board' ? 'is-active' : ''}>
            <Trello size={30}/> Board
          </Link>
          <Link to={`/${selectedPupil.slug}/planung`} className={activeMenuKey === 'schedules' ? 'is-active' : ''}>
            <Calendar size={30}/> Planung
          </Link>
          <Link to={`/${selectedPupil.slug}/themen`} className={activeMenuKey === 'topics' ? 'is-active' : ''}>
            <List size={30}/> Themen
          </Link>
          <Link to={`/${selectedPupil.slug}/fotos`} className={activeMenuKey === 'gallery' ? 'is-active' : ''}>
            <Image size={30}/> Fotos
          </Link>
          <Link to={`/${selectedPupil.slug}/berichte`} className={activeMenuKey === 'reports' ? 'is-active' : ''}>
            <FileText size={30}/> Berichte
          </Link>
          <a onClick={() => logout({returnTo: window.location.origin})}>
            <LogOut size={30}/> Abmelden
          </a>
        </div>
      </aside>

      <nav id="desktop_nav">
        <div className="tabs is-centered is-fullwidth is-toggle">
          <ul>
            <li className={activeMenuKey === 'curriculum' ? 'is-active' : ''}>
              <Link to={`/${selectedPupil.slug}`}>
                <Flag size={30}/>
              </Link>
            </li>
            <li className={activeMenuKey === 'board' ? 'is-active' : ''}>
              <Link to={`/${selectedPupil.slug}/board`}>
                <Trello size={30}/>
              </Link>
            </li>
            <li className={activeMenuKey === 'schedules' ? 'is-active' : ''}>
              <Link to={`/${selectedPupil.slug}/planung`}>
                <Calendar size={30}/>
              </Link>
            </li>
            <li className={activeMenuKey === 'topics' ? 'is-active' : ''}>
              <Link to={`/${selectedPupil.slug}/themen`}>
                <List size={30}/>
              </Link>
            </li>
            <li className={activeMenuKey === 'gallery' ? 'is-active' : ''}>
              <Link to={`/${selectedPupil.slug}/fotos`}>
                <Image size={30}/>
              </Link>
            </li>
            <li className={activeMenuKey === 'reports' ? 'is-active' : ''}>
              <Link to={`/${selectedPupil.slug}/berichte`}>
                <FileText size={30}/>
              </Link>
            </li>
            <li>
              <a onClick={() => logout({returnTo: window.location.origin})}>
                <LogOut size={30}/>
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <main>
        {children}
      </main>
    </section>
  </>
}

function PupilChooserDialog({pupils, onClose}) {
  return <div className="modal is-active">
    <div className="modal-background" onClick={onClose}/>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">Schüler wechseln</p>
        <button className="delete" aria-label="close" onClick={onClose}/>
      </header>
      <section className="modal-card-body">
        {pupils.map(pupil => <div key={pupil.pupilId}>
          <Link to={`/${pupil.slug}`} onClick={onClose}>
            <div className="box has-background-primary has-text-white is-size-3 mb-3 is-flex is-align-items-center">
              <img src={require(`../assets/images/${pupil.avatar}`).default} width={40} height={40} className="ml-2 mr-5"/>
              {pupil.name}
            </div>
          </Link>
        </div>)}
      </section>
    </div>
  </div>
}
