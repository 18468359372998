import {useApi} from "../../lib/ApiContext";
import React, {useEffect, useState} from "react";
import {useDrop} from "react-dnd";
import {deepCopy} from "../../lib/helpers";
import {AddGrundanspruchDialog} from "./AddGrundanspruchDialog";
import {Info, Plus} from "react-feather";
import {DraggableBoardItem} from "./DraggableBoardItem";
import {useDialogs} from "../../lib/DialogsContext";

export function BoardList({searchFilter, pupil, list, listSeed, reloadList, activeFachbereich}) {
  const api = useApi();
  const [items, setItems] = useState(null);
  const [error, setError] = useState(null);
  const dialogs = useDialogs();

  const loadItems = () => {
    api.boardLists().getBoardListItem(list.boardListId).then(rsp => setItems(rsp.data.items)).catch(err => setError(err));
  };

  useEffect(() => {
    loadItems();
  }, [listSeed]);

  const isLoading = items === null && error === null;

  let filteredItems = items;

  if (items) {
    filteredItems = filteredItems.filter(i => i.pupil.pupilId === pupil.pupilId);
  }

  if (items && activeFachbereich !== null) {
    filteredItems = filteredItems.filter(item => item.grundanspruch.fachbereichId === activeFachbereich.fachbereichId);
  }

  if (items && searchFilter !== '') {
    filteredItems = filteredItems.filter(item => {
      const search = searchFilter.toLowerCase();

      if (item.grundanspruch.code.full.toLowerCase().includes(search)) {
        return true;
      }

      return item.grundanspruch.texts.join(' ').toLowerCase().includes(search);
    });
  }

  const itemIndexes = {};
  if (items !== null) {
    for (const [idx, item] of items.entries()) {
      itemIndexes[item.boardItemId] = idx;
    }
  }

  const changeItemPosition = (boardItemId, newIndex) => {
    const currentIndex = itemIndexes[boardItemId];

    const itemsCopy = deepCopy(items);
    itemsCopy.splice(newIndex, 0, itemsCopy.splice(currentIndex, 1)[0])
    setItems(itemsCopy);
  };

  const storeItemPositions = () => {
    api.boardLists().boardListReorderList(list.boardListId, {
      sequence: items.map(item => `/board_items/${item.boardItemId}`),
    }).then(() => loadItems())
    .catch(err => setError(err));
  };

  const moveItemFromtOtherList = (boardItemId, oldBoardListId) => {
    api.boardItems().patchBoardItemItem(boardItemId, {
      list: `/board_lists/${list.boardListId}`,
      sequence: 0,
    }).then(() => {
      loadItems();
      reloadList(oldBoardListId);
    }).catch(err => console.error(err));
  };

  const [{isOver, isFromOtherList}, dropRef] = useDrop({
    accept: 'board-item',
    drop: ({boardItemId, boardListId}) => {
      if (list.boardListId === boardListId) {
        storeItemPositions();
      } else {
        //console.log('dropped item of list', boardListId, 'on list', list.boardListId);
        moveItemFromtOtherList(boardItemId, boardListId);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      isFromOtherList: monitor.getItem() && monitor.getItem().boardListId !== list.boardListId,
    }),
  });

  const handleAddGrundanspruch = () => {
    dialogs.open(<AddGrundanspruchDialog boardListId={list.boardListId} pupilId={pupil.pupilId} activeFachbereich={activeFachbereich}
                                         onListChange={() => loadItems()} onClose={dialogs.close}/>)
  }

  return <div ref={dropRef}>
    <div className={`board-list mr-5 is-flex-shrink-0`} style={{width: 320}}>
      <div className="is-flex is-align-items-center mb-3">
        <h3 className="title is-6 pr-3 mb-0 has-text-grey-dark">{list.name}</h3>
        <span className="is-clickable" title={list.description}><Info size={14}/></span>
      </div>

      {isLoading ? 'Lade ...' : <div>
        {error !== null && <div>{error.message}</div>}
        {filteredItems !== null && <>
          {isFromOtherList && <div className="board-item is-dropzone box p-2 is-size-7">
            In diese Liste verschieben
          </div>}

          {filteredItems.map(item => <DraggableBoardItem key={item.boardItemId} list={list} itemIndexes={itemIndexes} item={item} changeItemPosition={changeItemPosition}
                                                         reloadList={reloadList} loadItems={loadItems}/>)}
          
          <button onClick={handleAddGrundanspruch} className="button is-small is-text is-fullwidth is-justify-content-flex-start is-align-items-center p-2">
            <Plus size={12} className="mr-1"/> Grundanspruch hinzufügen
          </button>
        </>}
      </div>}
    </div>
  </div>
}
