import {useApi} from "../../lib/ApiContext";
import React, {useState} from "react";
import {Field, Form, FormErrors, TextareaField, TextField} from "../../components/form";
import {useUser} from "../../lib/UserContext";

export function AddTopicDialog({onTopicAdded, onClose}) {
  const api = useApi();
  const user = useUser();

  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState({
    title: '',
    abstract: '',
    description: null,
    household: `/households/${user.household.householdId}`,
  });
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    setIsSaving(true);
    setErrors({});

    api.topics().postTopicCollection(data)
    .then(rsp => onTopicAdded(rsp.data))
    .then(() => onClose())
    .catch(api.createErrorHandler(setErrors))
    .finally(() => setIsSaving(false));
  };

  return <div className="modal is-active">
    <div className="modal-background" onClick={onClose}/>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{data.title || 'Thema ohne Namen'}</p>
        <button className="delete" aria-label="close" onClick={onClose}/>
      </header>
      <section className="modal-card-body">
        <Form data={data} errors={errors} onChange={setData}>
          <FormErrors/>
          <TextField label="Titel" name="title"/>
          <TextField label="Zusammenfassung" name="abstract"/>
          <TextareaField label="Beschreibung" name="description" rows={10}/>
          <Field optional>
            <div className="buttons">
              <button className={`button is-primary ${isSaving ? 'is-loading' : ''}`} disabled={isSaving} onClick={handleSave}>Speichern</button>
              <button className="button is-text" disabled={isSaving} onClick={onClose}>Abbrechen</button>
            </div>
          </Field>
        </Form>
      </section>
    </div>
  </div>
}