import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from "react-router";
import { useApi, useUser } from "../lib/contexts";
import { Loader, PageTitle } from "../components";
import Layout from "./Layout";
import { Link, useHistory } from "react-router-dom";
import { Search } from "react-feather";
import { GrundanspruecheList } from "./Curriculum/GrundanspruecheList";

export default function CurriculumScreen() {
  const api = useApi();
  const user = useUser();
  const params = useParams();
  const history = useHistory();

  const pupils = user.household.pupils;
  const selectedPupil = pupils.filter(p => p.slug === params.pupilSlug)[0];

  const activeFachbereichId = params.fachbereichId || null;

  const [searchFilter, setSearchFilter] = useState('');
  const [fachbereiche, setFachbereiche] = useState(null);

  const [pupil, setPupil] = useState(null);
  const loadPupil = () => {
    api.pupils().getPupilItem(selectedPupil.pupilId).then(rsp => setPupil(rsp.data)).catch(err => console.error(err));
  };

  useEffect(() => {
    loadPupil();
    api.fachbereiche().getFachbereichCollection().then(rsp => setFachbereiche(rsp.data)).catch(err => console.log(err));
  }, [selectedPupil.pupilId]);

  let activeFachbereich = useMemo(() => {
    if (fachbereiche !== null && fachbereiche.length > 0 && activeFachbereichId) {
      return fachbereiche.filter(f => f.fachbereichId === activeFachbereichId)[0];
    }
    return null;
  }, [fachbereiche, activeFachbereichId]);

  if (fachbereiche === null || pupil === null) {
    return <Layout activeMenuKey="curriculum">
      <Loader/>
    </Layout>
  }

  const pageTitle = 'Lehrplan ' + (activeFachbereich !== null ? `» ${activeFachbereich.name}` : '');

  return <Layout activeMenuKey="curriculum">
    <section id="header">
      <div className="select is-fullwidth">
        <select className="has-text-centered" value={activeFachbereichId?.toString()} onChange={e => {
          let url = `/${selectedPupil.slug}`;
          if (e.target.value !== '') {
            url += `/fachbereiche/${e.target.value}`;
          }
          history.push(url);
        }}>
          <option value="">Alle Fachbereiche</option>
          {fachbereiche.map(fachbereich => (
            <option key={fachbereich.fachbereichId} value={fachbereich.fachbereichId}>{fachbereich.code.full}</option>
          ))}
        </select>
      </div>

      <div className="level">
        <div className="level-left level-menu">
          <div className="level-item">
            <Link to={`/${selectedPupil.slug}`} title="Alle Fachbereiche"
                  className={activeFachbereichId === null ? 'is-active' : ''}>Alle Fachbereiche</Link>
          </div>

          {fachbereiche.map(fachbereich => {
            const url = `/${selectedPupil.slug}/fachbereiche/${fachbereich.fachbereichId}`;

            return <div key={fachbereich.fachbereichId} className="level-item">
              <Link key={fachbereich.fachbereichId} to={url} title={fachbereich.name}
                    className={activeFachbereichId === fachbereich.fachbereichId ? 'is-active' : ''}>{fachbereich.code.full}</Link>
            </div>
          })}
        </div>
        <div className="level-right">
          <div className="level-item">
            <Search size={20}/>
          </div>
          <div className="level-item">
            <input type="text" className="input is-text" placeholder="Textsuche ..." value={searchFilter} onChange={e => setSearchFilter(e.target.value)}/>
          </div>
        </div>
      </div>
    </section>

    <section id="content">
      <PageTitle title={pageTitle}/>
      <GrundanspruecheList activeFachbereich={activeFachbereich} pupil={pupil} loadPupil={loadPupil} onListChange={() => {}}/>
    </section>
  </Layout>
}



