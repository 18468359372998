import _ from "lodash";

export function deepMerge(existingData, newData) {
  return _.merge(existingData, newData);
}

export function deepCopy(data) {
  return _.cloneDeep(data);
}

export function deepCopyAndSet(data, key, value) {
  const copy = _.cloneDeep(data);

  _.set(copy, key, value);

  return copy;
}

export function deepCopyAndPush(data, key, value) {
  const copy = _.cloneDeep(data);

  const arr = _.get(copy, key, []);
  arr.push(value);
  _.set(copy, key, arr);

  return copy;
}

export function deepGet(data, key) {
  return _.get(data, key, null);
}

export function natsort() {
  const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

  return collator.compare;
}