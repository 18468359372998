import {useApi} from "../../lib/ApiContext";
import React, {useEffect, useState} from "react";
import {DateInput, Field, Form, FormErrors, TextareaField, TextField} from "../../components/form";
import {addMonths, addWeeks, endOfMonth, format, startOfMonth, startOfWeek, subDays} from "date-fns";
import {Calendar} from "react-feather";
import de from 'date-fns/locale/de';

const defaultPlan = `
**Mathematik**
- _tbd_

**Deutsch**
- _tbd_

**NMG**
- _tbd_

**TTG/BG**
- _tbd_

**Sport**
- _tbd_

**Musik**
- _tbd_
`;

export function AddScheduleDialog({pupil, onScheduleAdded, onClose}) {
  const api = useApi();
  const [isQuickDateActive, setIsQuickDateActive] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState({
    startsAt: '2020-01-01',
    endsAt: '2020-01-01',
    label: '',
    plan: defaultPlan.trim(),
    pupil: `/pupils/${pupil.pupilId}`,
  });
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    setIsSaving(true);
    setErrors({});

    api.schedules().postScheduleCollection(data)
    .then(rsp => onScheduleAdded(rsp.data))
    .then(() => onClose())
    .catch(api.createErrorHandler(setErrors))
    .finally(() => setIsSaving(false));
  };

  const handleDatePreset = (type, offset) => () => {
    setDatePreset(type, offset);
  };

  const setDatePreset = (type, offset) => {
    let startsAt, endsAt, label;

    switch (type) {
      case 'week':
        startsAt = addWeeks(startOfWeek(new Date(), {weekStartsOn: 1}), offset);
        endsAt = subDays(addWeeks(startsAt, 1), 1);
        label = `KW ${format(startsAt, 'I yyyy', {locale: de})}`;
        break;

      case 'month':
        startsAt = addMonths(startOfMonth(new Date()), offset);
        endsAt = endOfMonth(startsAt);
        label = `${format(startsAt, 'MMMM yyyy', {locale: de})}`;
        break;

      default:
        return;
    }

    setData({
      ...data,
      startsAt: format(startsAt, 'yyyy-MM-dd'),
      endsAt: format(endsAt, 'yyyy-MM-dd'),
      label,
    });
  };

  useEffect(() => {
    setDatePreset('week', 1);
  }, []);

  return <div className="modal is-active">
    <div className="modal-background" onClick={onClose}/>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{data.label || 'Wochenplan ohne Namen'}</p>
        <button className="delete" aria-label="close" onClick={onClose}/>
      </header>
      <section className="modal-card-body">
        <Form data={data} errors={errors} onChange={setData}>
          <FormErrors/>
          <Field label="Zeitraum">
            <DateInput name="startsAt"/>
            <div className="field is-narrow">bis</div>
            <DateInput name="endsAt"/>
            <div className="field is-narrow">
              <div className={`dropdown is-right ${isQuickDateActive && 'is-active'}`} onClick={() => setIsQuickDateActive(status => !status)}>
                <div className="dropdown-trigger">
                  <button className="button is-rounded is-outlined" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span className="icon is-small"><Calendar size={14}/></span>
                  </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                  <div className="dropdown-content">
                    <a href="#" className="dropdown-item" onClick={handleDatePreset('week', 0)}>Diese Woche</a>
                    <a href="#" className="dropdown-item" onClick={handleDatePreset('week', 1)}>Nächste Woche</a>
                    <a href="#" className="dropdown-item" onClick={handleDatePreset('week', 2)}>In zwei Wochen</a>
                    <a href="#" className="dropdown-item" onClick={handleDatePreset('month', 0)}>Diesen Monat</a>
                    <a href="#" className="dropdown-item" onClick={handleDatePreset('month', 1)}>Nächsten Monat</a>
                  </div>
                </div>
              </div>
            </div>
          </Field>
          <TextField label="Beschriftung" name="label"/>
          <TextareaField label="Planung" name="plan" rows={10}/>
          <Field optional>
            <div className="buttons">
              <button className={`button is-primary ${isSaving ? 'is-loading' : ''}`} disabled={isSaving} onClick={handleSave}>Speichern</button>
              <button className="button is-text" disabled={isSaving} onClick={onClose}>Abbrechen</button>
            </div>
          </Field>
        </Form>
      </section>
    </div>
  </div>
}