import React from 'react';
import {useParams} from "react-router";
import {useUser} from "../lib/contexts";

import Layout from "./Layout";
import {Link} from "react-router-dom";
import ProgressReport from "./Report/ProgressReport";

export default function ReportsScreen() {
  const user = useUser();
  const params = useParams();

  const pupils = user.household.pupils;
  const selectedPupil = pupils.filter(p => p.slug === params.pupilSlug)[0];

  const activeReportKey = params.reportKey || 'progress';

  const reports = {
    progress: <ProgressReport pupil={selectedPupil}/>
  };

  return <Layout activeMenuKey="reports">
    <section id="header">
      <div className="level">
        <div className="level-left level-menu">
          <div className="level-item">
            <Link to={`/${selectedPupil.slug}`} className={activeReportKey === 'progress' ? 'is-active' : ''}>Grundansprüche</Link>
          </div>
        </div>
      </div>
    </section>
    <section id="content">
      {reports[activeReportKey]}
    </section>
  </Layout>
}
