import React, {Fragment, useRef, useState} from "react";
import {defaultStyles, FileIcon} from 'react-file-icon';
import {useApi} from "../lib/ApiContext";
import {ErrorBox, SmallTextButton} from "./index";
import {ChevronDown} from "react-feather";
import {useDialogs} from "../lib/DialogsContext";

export function Documents({mode = 'boxes', uploads = [], tag = null, cover, onCoverChange = null, onUploaded, onUploadRemoved}) {
  const api = useApi();
  const dialogs = useDialogs();

  const fileRef = useRef();
  const [error, setError] = useState(null);

  let filteredUploads = uploads;
  if (tag) {
    filteredUploads = filteredUploads.filter(u => u.tags && u.tags.includes(tag));
  }

  const handleAddDocument = () => {
    fileRef.current.click();
  };

  const handleDocumentSelected = () => {
    setError(null);

    const files = fileRef.current.files;
    if (!files.length) {
      return;
    }

    api.uploads().postUploadCollection(files[0])
    .then(rsp => {
      onUploaded(rsp.data);

      return rsp.data;
    })
    .then(upload => {
      if (tag) {
        api.uploads().patchUploadItem(upload.uploadId, {tags: [tag]}).catch(err => setError(err.message));
      }
    })
    .catch(err => setError(err.message));
  };

  const handleDeleteUpload = upload => {
    if (window.confirm('Wirklich löschen?')) {
      api.uploads().deleteUploadItem(upload.uploadId)
      .then(() => onUploadRemoved(upload))
      .catch(err => console.error(err));
    }
  };

  const annotatedUploads = filteredUploads.map(upload => {
    const isCover = cover && cover.uploadId === upload.uploadId;
    const url = process.env.REACT_APP_API_DOMAIN + upload.contentUrl;
    const onImageClick = () => dialogs.open(<ImageModal url={url} title={upload.originalName} onClick={dialogs.close}/>);
    const contextMenu = <ContextMenu upload={upload} isCover={isCover} onCoverChange={onCoverChange} onDeleteUpload={handleDeleteUpload}/>

    return {upload, isCover, url, onImageClick, contextMenu};
  });

  return <>
    {error !== null && <ErrorBox message={error}/>}

    {filteredUploads.length > 0 && <div className={`uploads is-${mode}`}>
      {mode === 'boxes' && <div className="columns is-multiline is-gapless mb-0">
        {annotatedUploads.map(({upload, isCover, url, onImageClick, contextMenu}) => <div key={upload.uploadId} className="column is-one-fifth">
          <BoxItem upload={upload} url={url} isCover={isCover} contextMenu={contextMenu} onImageClick={onImageClick}/>
        </div>)}
      </div>}

      {mode === 'list' && <div className="mb-0">
        {annotatedUploads.map(({upload, isCover, url, onImageClick, contextMenu}) => <Fragment key={upload.uploadId}>
          <ListItem upload={upload} url={url} isCover={isCover} contextMenu={contextMenu} onImageClick={onImageClick}/>
        </Fragment>)}
      </div>}
    </div>}

    <div>
      <input ref={fileRef} type="file" onChange={handleDocumentSelected} style={{width: 0, display: 'none'}}/>
      <SmallTextButton label="Dokument hinzufügen ..." type="upload" onClick={handleAddDocument}/>
    </div>
  </>;
}

function BoxItem({upload, url, isCover, onImageClick, contextMenu}) {
  return <div className="upload">
    {contextMenu}
    {upload.image && <UploadImage url={url} title={upload.originalName} isCover={isCover} onClick={onImageClick}/>}
    {!upload.image && <div className="is-clickable has-text-centered" onClick={() => window.open(url)}>
      <p className="pt-2"><UploadIcon upload={upload}/></p>
      <p className="heading p-1">{upload.originalName}</p>
    </div>}
  </div>
}

function ListItem({upload, url, isCover, onImageClick, contextMenu}) {
  return <div className="upload is-flex is-align-items-center">
    <div className="is-clickable pr-2">
      {upload.image && <UploadImage url={url} title={upload.originalName} isCover={isCover} onClick={onImageClick}/>}
      {!upload.image && <UploadIcon onClick={() => window.open(url)} upload={upload}/>}
    </div>
    <div className="has-text-weight-bold has-text-grey pr-2 is-clickable" onClick={upload.image ? onImageClick : () => window.open(url)}>{upload.originalName}</div>
    <div>{contextMenu}</div>
  </div>
}

function UploadImage({url, title, isCover, onClick}) {
  return <figure className={`image is-16by9 is-clickable ${isCover ? 'is-cover' : ''}`} onClick={onClick}>
    <img src={url} alt={title} title={title}/>
  </figure>
}

function UploadIcon({upload}) {
  switch (upload.mimeType) {
    case 'application/pdf':
      return <FileIcon extension="pdf" type="acrobat" {...defaultStyles.pdf} />
    case 'application/vnd.oasis.opendocument.text' :
      return <FileIcon extension="odt" type="document" {...defaultStyles.odt} />
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' :
      return <FileIcon extension="xlsx" type="spreadsheet" {...defaultStyles.odt} />
    case 'text/plain' :
      return <FileIcon extension="txt" {...defaultStyles.txt} />
    default:
      return null;
  }
}

function ContextMenu({upload, isCover, onCoverChange, onDeleteUpload}) {
  const [isMenuActive, setIsMenuActive] = useState(false);

  return <div className="upload-context-menu">
    <div className={`dropdown is-right ${isMenuActive ? 'is-active' : ''}`} onClick={() => setIsMenuActive(isActive => !isActive)}>
      <div className="dropdown-trigger">
        <button className="button is-small is-text" aria-haspopup="true" aria-controls={`upload_menu_${upload.uploadId}`}>
          <span className="icon is-small"><ChevronDown size={14}/></span>
        </button>
      </div>
      <div className="dropdown-menu" id={`upload_menu_${upload.uploadId}`} role="menu">
        <div className="dropdown-content has-background-grey-lighter">
          {upload.image && onCoverChange !== null && <a onClick={() => onCoverChange(isCover ? null : upload)} className="dropdown-item">
            {isCover ? 'Nicht als Cover verwenden' : 'Als Cover verwenden'}
          </a>}
          <a onClick={() => onDeleteUpload(upload)} className="dropdown-item">Datei entfernen</a>
        </div>
      </div>
    </div>
  </div>
}

export function ImageModal({url, title, onClick}) {
  return <div className="modal is-active" onClick={onClick}>
    <div className="modal-background"/>
    <div className="modal-content">
      <p className="image">
        <img src={url} alt={title} title={title}/>
      </p>
    </div>
    <button className="modal-close is-large" aria-label="close"/>
  </div>
}