import React, {useContext, forwardRef, useRef} from "react";
import DateTimePicker from "react-datetime-picker";
import {LOCALE} from "../lib/time";
import {deepCopyAndSet, deepGet} from "../lib/helpers";
import CreatableSelect from 'react-select/creatable';
import {AlertCircle} from "react-feather";
import {ErrorBox} from "./index";
import DatePicker from "react-datepicker";
import {format} from "date-fns";

export const FormContext = React.createContext(null);

export function useForm() {
  const form = useContext(FormContext);
  if (!form) {
    throw new Error('useForm() called outside a <Form> component');
  }

  return form;
}

export function Form({data, errors, onChange, children}) {
  const setValue = (key, val) => {
    onChange(deepCopyAndSet(data, key, val));
  };

  const getValue = key => {
    const val = deepGet(data, key);

    return val || '';
  };

  const onInputChange = event => {
    let value = event.target.value;
    if (value === '' && event.target.required === false) {
      value = null;
    }
    setValue(event.target.name, value);
  };

  return <FormContext.Provider value={{data, errors, onInputChange, setValue, getValue}}>
    {children}
  </FormContext.Provider>
}

export function FormErrors() {
  const {errors} = useForm();

  if (!errors.form_errors) {
    return null;
  }

  return <ErrorBox message={errors.form_errors}/>;
}

export function Field({label, optional = false, children}) {
  return <div className={"field is-horizontal " + (optional ? 'is-optional' : 'is-required')}>
    <div className="field-label is-normal">
      <label className="label">{label}</label>
    </div>
    <div className="field-body is-align-items-center">
      {children}
    </div>
  </div>
}

export function SelectField({label, optional = false, ...props}) {
  return <Field label={label} optional={optional}>
    <SelectInput {...props}/>
  </Field>
}

export function SelectInput({name, options, ...props}) {
  const {errors, setValue, getValue} = useForm();
  const isError = !!errors[name];

  const arrayOptions = [];
  for (const [value, label] of Object.entries(options)) {
    arrayOptions.push({label, value});
  }

  // react-select needs the full object, not just the selected key/value
  const value = getValue(name);
  const selectedOption = {value, label: options[value]};

  return <div className="field">
    <CreatableSelect options={arrayOptions.sort((a, b) => a.label.localeCompare(b.label))}
                     name={name}
                     value={selectedOption}
                     onChange={option => setValue(name, option.value)}
                     {...props}
    />
    {isError && <p className="help is-danger">{errors[name]}</p>}
  </div>
}

export function TextField({label, optional = false, ...props}) {
  return <Field label={label} optional={optional}>
    <TextInput optional={optional} {...props}/>
  </Field>
}

export function TextInput({name, optional = false, flex = 1, ...props}) {
  const {errors, onInputChange, getValue} = useForm();
  const isError = !!errors[name];

  return <div className="field" style={{flex}}>
    <div className="control has-icons-right">
      <input className={'input' + (isError ? ' is-danger' : '')} type="text" name={name} value={getValue(name)}
             onChange={onInputChange} required={!optional} {...props}/>
      {!optional && <span className="icon is-small is-right" title="Required">
        <AlertCircle size={18}/>
      </span>}
    </div>
    {isError && <p className="help is-danger">{errors[name]}</p>}
  </div>
}

export function TextareaField({label, optional = false, ...props}) {
  return <Field label={label} optional={optional}>
    <TextareaInput {...props}/>
  </Field>
}

export function TextareaInput({name, optional = false, ...props}) {
  const {errors, onInputChange, getValue} = useForm();
  const isError = !!errors[name];

  return <div className="field">
    <div className="control">
      <textarea className={'textarea' + (isError ? ' is-danger' : '')} placeholder="..."
                name={name} value={getValue(name)} required={!optional}
                onChange={onInputChange} {...props}/>
    </div>
    {isError && <p className="help is-danger">{errors[name]}</p>}
  </div>
}

export function DateField({label, optional = false, ...props}) {
  return <Field label={label} optional={optional}>
    <DateInput {...props}/>
  </Field>
}

export function DateInput({name}) {
  const {errors, setValue, getValue} = useForm();
  const isError = !!errors[name];

  // https://github.com/Hacker0x01/react-datepicker/issues/862#issuecomment-534736357
  const ref = useRef();
  const DateInputInput = forwardRef(({value, onClick}, ref) => <input ref={ref} type="text" className="input has-text-centered"
                                                                      value={value} onClick={onClick} readOnly
                                                                      style={{width: '120px'}}/>);

  return <div className="field is-narrow">
    <div className="control">
      <DatePicker dateFormat="dd.MM.yyyy"
                  selected={new Date(getValue(name))}
                  onChange={date => setValue(name, format(date, 'yyyy-MM-dd'))}
                  customInput={<DateInputInput ref={ref}/>}
                  showWeekNumbers
      />
    </div>
    {isError && <p className="help is-danger">{errors[name]}</p>}
  </div>
}

export function DateTimeField({label, optional = false, ...props}) {
  return <Field label={label} optional={optional}>
    <DateTimeInput {...props}/>
  </Field>
}

export function DateTimeInput({name}) {
  const {errors, setValue, getValue} = useForm();
  const isError = !!errors[name];

  return <div className="field">
    <div className="control">
      <DateTimePicker name={name}
                      value={new Date(getValue(name))}
                      onChange={date => setValue(name, date.toISOString())}
                      calendarIcon={null}
                      locale={LOCALE}
                      showLeadingZeros
                      disableClock={true}
                      clearIcon={null}/>
    </div>
    {isError && <p className="help is-danger">{errors[name]}</p>}
  </div>
}