import React, {useContext} from "react";
import {
  BoardItemApi,
  BoardItemNoteApi,
  BoardListApi,
  FachbereichApi,
  GrundanspruchApi,
  HouseholdApi, JournalEntryApi,
  KompetenzApi,
  KompetenzbereichApi,
  PupilApi, ScheduleApi,
  ThemenaspektApi,
  TopicApi, UploadApi,
  UserApi
} from "../sdk";

export const ApiContext = React.createContext({});

export function useApi() {
  const config = useContext(ApiContext);

  return {
    pupils() {
      return new PupilApi(config);
    },
    boardLists() {
      return new BoardListApi(config);
    },
    boardItems() {
      return new BoardItemApi(config);
    },
    boardItemNotes() {
      return new BoardItemNoteApi(config);
    },
    users() {
      return new UserApi(config);
    },
    households() {
      return new HouseholdApi(config);
    },
    topics() {
      return new TopicApi(config);
    },
    files() {
      return new UploadApi(config);
    },
    schedules() {
      return new ScheduleApi(config);
    },
    journal() {
      return new JournalEntryApi(config);
    },
    grundansprueche() {
      return new GrundanspruchApi(config);
    },
    kompetenzen() {
      return new KompetenzApi(config);
    },
    themenaspekte() {
      return new ThemenaspektApi(config);
    },
    kompetenzbereiche() {
      return new KompetenzbereichApi(config);
    },
    fachbereiche() {
      return new FachbereichApi(config);
    },
    uploads() {
      return new UploadApi(config);
    },
    createErrorHandler(setErrors) {
      return err => setErrors(this.extractErrors(err))
    },
    extractErrors(err) {
      console.log('Form error', err);

      if (!err.response || !err.response.data) {
        return {
          form_errors: err.message,
        };
      }

      const data = err.response.data;

      if (!data.violations) {
        return {
          form_errors: data.title + ': ' + data.detail,
        };
      }

      const errors = {};
      for (const violation of data.violations) {
        errors[violation.propertyPath] = violation.message;
      }

      return errors;
    }
  }
}