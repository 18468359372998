import React, {useEffect, useState} from "react";
import {useApi, useDialogs} from "../../lib/contexts";
import {Field, Form, FormErrors, TextareaField, TextField} from "../../components/form";
import {DialogSubtitle, MarkdownText, SmallTextButton} from "../../components";
import {Documents} from "../../components/Documents";
import {AddGrundanspruchDialog} from "./AddGrundanspruchDialog";
import {natsort} from "../../lib/helpers";

export function TopicDialog({topicId, onTopicChanged, onClose}) {
  const api = useApi();

  const [topic, setTopic] = useState(null);

  const loadTopic = () => {
    api.topics().getTopicItem(topicId)
    .then(rsp => setTopic(rsp.data))
    .catch(err => console.error(err));
  };

  useEffect(() => {
    loadTopic();
  }, []);

  return <div className="modal is-active">
    <div className="modal-background" onClick={onClose}/>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{topic ? topic.title : 'Lade ...'}</p>
        <button className="delete" aria-label="close" onClick={onClose}/>
      </header>
      <section className="modal-card-body">
        {topic !== null && <DialogBody topic={topic} loadTopic={loadTopic} onTopicChanged={onTopicChanged} onClose={onClose}/>}
      </section>
    </div>
  </div>;
}

function DialogBody({topic, loadTopic, onTopicChanged, onClose}) {
  const api = useApi();
  const dialogs = useDialogs();

  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({
    title: topic.title,
    abstract: topic.abstract,
    description: topic.description || null,
  });
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    setIsSaving(true);
    setErrors({});

    api.topics()
    .patchTopicItem(topic.topicId, data)
    .then(() => setIsEditing(false))
    .then(() => loadTopic())
    .then(() => onTopicChanged())
    .catch(api.createErrorHandler(setErrors))
    .finally(() => setIsSaving(false));
  };

  const handleNewFile = upload => {
    api.topics().patchTopicItem(topic.topicId, {
      files: topic.files.map(upload => `/uploads/${upload.uploadId}`).concat(`/uploads/${upload.uploadId}`),
    })
    .then(() => loadTopic())
    .then(() => onTopicChanged())
    .catch(err => console.error(err));
  };

  const handleCoverChange = upload => {
    api.topics().patchTopicItem(topic.topicId, {
      cover: upload === null ? null : `/uploads/${upload.uploadId}`,
    })
    .then(() => loadTopic())
    .then(() => onTopicChanged())
    .catch(err => console.error(err));
  };

  const handleRemoveTopic = () => {
    if (window.confirm('Das Thema wirklich entfernen? Allfällige Kommentare und Uploads gehen verloren.')) {
      setIsSaving(true);
      api.topics().deleteTopicItem(topic.topicId)
      .then(() => onTopicChanged())
      .catch(err => console.error(err))
      .finally(() => onClose());
    }
  };

  const handleManageGrundansprueche = () => {
    dialogs.open(<AddGrundanspruchDialog topicId={topic.topicId} onTopicChanged={loadTopic} onClose={dialogs.close}/>)
  };
  
  const sorter = natsort();
  const grundansprueche = topic.grundansprueche.sort((a, b) => sorter(a.code.full, b.code.full));
  
  return <>
    <div className="modal-actions">
      <div className="has-background-danger-light">
        <button className={`button is-small is-danger ${isSaving ? 'is-loading' : ''}`} disabled={isSaving} onClick={handleRemoveTopic}>
          Entfernen
        </button>
      </div>
    </div>

    {isEditing ? <Form data={data} errors={errors} onChange={setData}>
      <FormErrors/>
      <TextField label="Titel" name="title"/>
      <TextField label="Zusammenfassung" name="abstract"/>
      <TextareaField label="Beschreibung" name="description" rows={10}/>
      <Field optional>
        <div className="buttons">
          <button className={`button is-small is-primary ${isSaving ? 'is-loading' : ''}`} disabled={isSaving} onClick={handleSave}>Speichern</button>
          <button className="button is-small is-text" disabled={isSaving} onClick={() => setIsEditing(false)}>Verwerfen</button>
        </div>
      </Field>
    </Form> : <>
      {data.description ? <div>
        <p className="has-text-weight-bold is-size-3 mb-4 has-text-grey">{topic.abstract}</p>

        <DialogSubtitle first>Beschreibung</DialogSubtitle>
        <MarkdownText text={data.description}/>
        <SmallTextButton label="Editieren" type="edit" onClick={() => setIsEditing(true)}/>
      </div> : <div className="is-clickable" onClick={() => setIsEditing(true)}>
        <em className="has-text-grey">Klicken zum editieren ...</em>
      </div>}
    </>}

    <DialogSubtitle>Dokumente</DialogSubtitle>
    <Documents uploads={topic.files} cover={topic.cover} onCoverChange={handleCoverChange} onUploaded={handleNewFile} onUploadRemoved={() => onTopicChanged()}/>

    <DialogSubtitle>Grundansprüche</DialogSubtitle>
    {grundansprueche.map(grundanspruch => <div key={grundanspruch.grundanspruchId} className="mb-2">
      <span className="has-text-weight-bold has-text-grey">{grundanspruch.code.full}</span> {grundanspruch.texts[0]}
    </div>)}
    <p>
      <SmallTextButton label="Grundansprüche hinzufügen/entfernen" type="edit" onClick={handleManageGrundansprueche}/>
    </p>
  </>;
}