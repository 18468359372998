import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {LogIn} from "react-feather";

export default function LoginScreen() {
  const {loginWithRedirect} = useAuth0();

  return <div id="login">
    <div className="is-flex is-align-items-center is-justify-content-center is-fullheight-with-navbar">
      <div className="has-text-centered">
        <div style={{backgroundColor: '#fff', width: '20rem', height: '20rem', borderRadius: '10rem'}}>
          <img src={require('../assets/images/kangaroo.svg').default} alt="komonk"/>
        </div>

        <p className="mt-4 mb-6 is-size-1 has-text-weight-bold">
          komonk
        </p>

        <a onClick={() => loginWithRedirect()} className="button is-large is-rounded is-primary">
          <LogIn className="mr-2"/> Anmelden
        </a>

        <div className="is-size-7 mt-6 has-text-white">
          Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
        </div>
      </div>
    </div>
  </div>
}
