import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {useApi, useDialogs, useUser} from "../lib/contexts";

import Layout from "./Layout";
import {ActionButton, Loader, PageTitle} from "../components";
import {formatDate} from "../lib/time";
import {ScheduleDialog} from "./Schedule/ScheduleDialog";
import {AddScheduleDialog} from "./Schedule/AddScheduleDialog";

export default function SchedulesScreen() {
  const api = useApi();
  const user = useUser();
  const params = useParams();
  const dialogs = useDialogs();

  const pupils = user.household.pupils;
  const selectedPupil = pupils.filter(p => p.slug === params.pupilSlug)[0];

  const [schedules, setSchedules] = useState(null);

  const loadSchedules = () => {
    api.schedules().getScheduleCollection().then(rsp => setSchedules(rsp.data)).catch(err => console.log(err));
  };

  useEffect(() => {
    loadSchedules();
  }, []);

  if (schedules === null) {
    return <Layout activeMenuKey="schedules">
      <Loader/>
    </Layout>
  }

  const pupilSchedules = schedules.filter(s => s.pupil.pupilId === selectedPupil.pupilId);

  const createHandleRowClick = schedule => () => {
    dialogs.open(<ScheduleDialog pupil={selectedPupil} scheduleId={schedule.scheduleId} onClose={dialogs.close}/>);
  };

  const handleAddSchedule = () => {
    dialogs.open(<AddScheduleDialog pupil={selectedPupil} onScheduleAdded={() => loadSchedules()} onClose={dialogs.close}/>);
  };

  return <Layout activeMenuKey="schedules">
    <section id="content">
      <PageTitle title="Planung" actionButtons={<ActionButton label="Planung starten" onClick={handleAddSchedule}/>}/>

      <div className="box is-radiusless">
        <table className="table is-striped is-hoverable is-fullwidth">
          <thead>
          <tr>
            <th>Zeitraum</th>
            <th>Von</th>
            <th>Bis</th>
          </tr>
          </thead>
          <tbody>
          {pupilSchedules.map(schedule => <tr key={schedule.scheduleId} className="is-clickable" onClick={createHandleRowClick(schedule)}>
            <th>{schedule.label}</th>
            <td>{formatDate(schedule.startsAt)}</td>
            <td>{formatDate(schedule.endsAt)}</td>
          </tr>)}
          </tbody>
        </table>
      </div>
    </section>
  </Layout>
}
