import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Edit2, Plus, Trash, Upload } from "react-feather";
import gfm from 'remark-gfm';
import ReactMarkdown from "react-markdown";

export function DialogSubtitle({ children, first = false }) {
  return <h4 className={`title is-size-5 mb-2 ${!first ? 'mt-4' : ''}`}>{children}</h4>
}

export function PageTitle({ title, actionButtons }) {
  return <div className="columns mb-3 is-gapless is-align-items-center">
    <div className="column is-narrow">
      <h1 className="title is-4 mb-2 mr-6">{title}</h1>
    </div>
    <div className="column">
      <div className="mb-2">
      {actionButtons}
      </div>
    </div>
  </div>
  
  return <div className="is-flex-widescreen is-align-items-center mb-5">
    <h1 className="title is-4 mb-0 mr-6">{title}</h1>
    {actionButtons}
  </div>
}

export function ActionButton({ label, onClick, type = 'add' }) {
  const iconProps = {
    className: label ? 'mr-1' : null,
    size: 12,
  };

  let icon = <Plus {...iconProps}/>;
  if (type === 'delete') {
    icon = <Trash {...iconProps}/>;
  } else if (type === 'upload') {
    icon = <Upload {...iconProps}/>;
  } else if (type === 'edit') {
    icon = <Edit2 {...iconProps}/>;
  } else if (type === 'add') {
    icon = <Plus {...iconProps}/>;
  }

  return <button onClick={onClick} className="button is-primary is-outlined is-justify-content-flex-start is-align-items-center p-2">
    {icon} {label}
  </button>
}

export function SmallTextButton({ label, onClick, type = 'add' }) {
  const iconProps = {
    className: label ? 'mr-1' : null,
    size: 12,
  };

  let icon = <Plus {...iconProps}/>;
  if (type === 'delete') {
    icon = <Trash {...iconProps}/>;
  } else if (type === 'upload') {
    icon = <Upload {...iconProps}/>;
  } else if (type === 'edit') {
    icon = <Edit2 {...iconProps}/>;
  } else if (type === 'add') {
    icon = <Plus {...iconProps}/>;
  }

  return <button onClick={onClick} className="button is-small is-text is-justify-content-flex-start is-align-items-center p-2">
    {icon} {label}
  </button>
}

export function MarkdownText({ text }) {
  return <div className="content mb-1">
    <ReactMarkdown linkTarget="_blank" source={text} plugins={[gfm]}/>
  </div>
}

export function Loader({ opacity = 0 }) {
  return <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10000, backgroundColor: `rgba(255,255,255,${opacity})` }}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
      <Icon icon="spinner" spin style={{ fontSize: '4rem', color: '#111' }}/>
    </div>
  </div>
}

export function Icon(props) {
  return <FontAwesomeIcon fixedWidth {...props} />
}

export function ErrorBox({ title, message }) {
  return <article className="message is-danger is-small">
    <div className="message-header"><p>{title || 'Oops'}</p></div>
    <div className="message-body">{message}</div>
  </article>
}