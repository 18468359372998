import React, {useContext, useState} from "react";

const DialogsContext = React.createContext(null);

export function useDialogs() {
  return useContext(DialogsContext);
}

export function DialogsProvider({children}) {
  const [stack, setStack] = useState([]);
  
  const value = {
    open: function(dialog) {
      setStack([...stack, dialog])
    },
    close: function() {
      setStack(stack.slice(-1));
    },
    closeAll: function() {
      setStack([]);
    },
    getAll: function() {
      return stack;
    },
  };
  
  return <DialogsContext.Provider value={value}>
    {children}
  </DialogsContext.Provider>;
}