import {useApi} from "../../lib/ApiContext";
import React, {Fragment, useEffect, useState} from "react";
import {DialogSubtitle} from "../../components";
import {useUser} from "../../lib/UserContext";
import {CheckCircle, Circle, X} from "react-feather";
import {natsort} from "../../lib/helpers";

export function GrundanspruecheList({pupil, loadPupil, onListChange, boardListId, activeFachbereich}) {
  const api = useApi();
  const user = useUser();

  const boardLists = user.household.boardLists;

  const addedBoardItemsByGrundanspruchIds = {};
  for (const boardItem of pupil.boardItems) {
    addedBoardItemsByGrundanspruchIds[boardItem.grundanspruch.grundanspruchId] = boardItem;
  }

  const [isSaving, setIsSaving] = useState(false);

  const createHandleAddGrundanspruch = (listId, grundanspruchId) => () => {
    setIsSaving(true);
    api.boardItems().postBoardItemCollection({
      list: `/board_lists/${listId}`,
      pupil: `/pupils/${pupil.pupilId}`,
      grundanspruch: `/grundansprueche/${grundanspruchId}`,
      sequence: 0,
    })
    .then(() => loadPupil())
    .then(() => onListChange(listId))
    .catch(err => console.error(err))
    .finally(() => setIsSaving(false));
  };

  const createHandleMoveGrundanspruch = (boardItemId, oldListId, newListId) => () => {
    setIsSaving(true);
    api.boardItems().patchBoardItemItem(boardItemId, {
      list: `/board_lists/${newListId}`,
      sequence: 0,
    })
    .then(() => loadPupil())
    .then(() => onListChange(newListId))
    .then(() => onListChange(oldListId))
    .catch(err => console.error(err))
    .finally(() => setIsSaving(false));
  };

  const createHandleRemoveGrundanspruch = (listId, boardItemId) => () => {
    if (window.confirm('Wirklich von Board entfernen? Allfällige Kommentare und Uploads gehen verloren.')) {
      setIsSaving(true);
      api.boardItems().deleteBoardItemItem(boardItemId)
      .then(() => loadPupil())
      .then(() => onListChange(listId))
      .catch(err => console.error(err))
      .finally(() => setIsSaving(false));
    }
  };

  const [selectedZyklus, setSelectedZyklus] = useState(pupil.zyklus.toString());
  const [grundansprueche, setGrundansprueche] = useState([]);

  const [selectedFachbereichId, setSelectedFachbereichId] = useState('');
  const [fachbereiche, setFachbereiche] = useState([]);

  const [selectedKompetenzbereichId, setSelectedKompetenzbereichId] = useState('*');
  const [kompetenzbereiche, setKompetenzbereiche] = useState([]);
  
  const [filteredBoardListId, setFilteredBoardListId] = useState('');

  const [searchFilter, setSearchFilter] = useState('');

  const selectFachbereich = fachbereichId => {
    setSelectedFachbereichId(fachbereichId);
    resetKompetenzbereich();
    if (fachbereichId === '') {
      return;
    }
    api.kompetenzbereiche().getKompetenzbereichCollection(fachbereichId)
    .then(rsp => {
      const sorter = natsort();

      setKompetenzbereiche(rsp.data.sort((a, b) => sorter(a.code.id, b.code.id)));
    })
    .then(() => selectKompetenzbereich(selectedKompetenzbereichId, fachbereichId))
    .catch(err => console.log(err));
  }

  const handleFachbereichChange = e => {
    selectFachbereich(e.target.value);
  };

  const resetKompetenzbereich = () => {
    setKompetenzbereiche([]);
    setSelectedKompetenzbereichId('*');
    resetGrundansprueche();
  };

  const selectKompetenzbereich = (kompetenzbereichId, fachbereichId) => {
    setSelectedKompetenzbereichId(kompetenzbereichId);
    resetGrundansprueche();

    if (kompetenzbereichId === '*') {
      kompetenzbereichId = undefined;
      if (!fachbereichId) {
        fachbereichId = selectedFachbereichId;
      }
    }

    api.grundansprueche()
    .getGrundanspruchCollection(undefined, undefined, kompetenzbereichId, undefined, fachbereichId)
    .then(rsp => setGrundansprueche(rsp.data))
    .catch(err => console.log(err));
  };

  const handleKompetenzbereichChange = e => {
    selectKompetenzbereich(e.target.value);
  };
  
  const handleBoardListFilterChange = e => {
    setFilteredBoardListId(e.target.value);
  }

  const handleZyklusChange = e => {
    setSelectedZyklus(e.target.value);
  };

  const resetGrundansprueche = () => {
    setGrundansprueche([]);
  };

  useEffect(() => {
    if (activeFachbereich) {
      selectFachbereich(activeFachbereich.fachbereichId);
    } else {
      api.fachbereiche().getFachbereichCollection().then(rsp => setFachbereiche(rsp.data)).catch(err => console.log(err));
    }
  }, [activeFachbereich]);

  let filteredGrundansprueche = grundansprueche.filter(g => g.zyklen[0] === parseInt(selectedZyklus));

  filteredGrundansprueche = filteredGrundansprueche.filter(grundanspruch => {
    const search = searchFilter.toLowerCase();

    if (grundanspruch.code.full.toLowerCase().includes(search)) {
      return true;
    }

    return grundanspruch.texts.join(' ').toLowerCase().includes(search);
  });
  
  if (!boardListId && filteredBoardListId !== '') {
    filteredGrundansprueche = filteredGrundansprueche.filter(grundanspruch => {
      const boardItem = addedBoardItemsByGrundanspruchIds[grundanspruch.grundanspruchId] || null;
      
      if (filteredBoardListId === '0' && boardItem === null) {
        return true;
      }
      
      return boardItem && boardItem.list.boardListId === parseInt(filteredBoardListId);
    });
  }

  const grundanspruecheByThemenaspektId = {};
  const themenaspekteById = {};
  for (const grundanspruch of filteredGrundansprueche) {
    const themenaspekt = grundanspruch.kompetenz.themenaspekt;
    const key = themenaspekt.code.id;
    if (grundanspruecheByThemenaspektId[key] === undefined) {
      grundanspruecheByThemenaspektId[key] = [];
      themenaspekteById[key] = themenaspekt;
    }
    grundanspruecheByThemenaspektId[key].push(grundanspruch);
  }

  const kompetenzbereicheById = {};
  for (const kompetenzbereich of kompetenzbereiche) {
    kompetenzbereicheById[kompetenzbereich.kompetenzbereichId] = kompetenzbereich;
  }

  return <>
    <form className="mb-6">
      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">Zyklus</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <div className="select">
                <select className="has-text-centered" value={selectedZyklus} onChange={handleZyklusChange}>
                  <option value="1">1. Zyklus (KiGa - 2. Klasse)</option>
                  <option value="2">2. Zyklus (3. - 6. Klasse)</option>
                  <option value="3">3. Zyklus (7. - 9. Klasse)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      {activeFachbereich === null && <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">Fachbereich</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <div className="select">
                <select value={selectedFachbereichId} onChange={handleFachbereichChange}>
                  <option value="">--- Bitte wählen ---</option>
                  {fachbereiche.map(fachbereich => <option key={fachbereich.fachbereichId} value={fachbereich.fachbereichId}>{fachbereich.name}</option>)}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>}

      {kompetenzbereiche.length > 0 && <>
        <div className="field is-horizontal">
          <div className="field-label">
            <label className="label">Kompetenzbereich</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <div className="select">
                  <select value={selectedKompetenzbereichId} onChange={handleKompetenzbereichChange}>
                    <option value="*">Alle</option>
                    {kompetenzbereiche.map(kompetenzbereich => <option key={kompetenzbereich.kompetenzbereichId} value={kompetenzbereich.kompetenzbereichId}>
                      {kompetenzbereich.code.id} | {kompetenzbereich.name}
                    </option>)}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label">
            <label className="label">Textsuche</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control has-icons-right">
                <input className="input" placeholder="Suchen ..."
                       value={searchFilter} onChange={e => setSearchFilter(e.target.value)}/>
                <span className="icon is-small is-right is-clickable" onClick={() => setSearchFilter('')}>
                <X size={12}/>
              </span>
              </div>
            </div>
          </div>
        </div>
      </>}
      
      {!boardListId && <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">Fortschritt</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <div className="select">
                <select value={filteredBoardListId} onChange={handleBoardListFilterChange}>
                  <option value="">--- Bitte wählen ---</option>
                  <option value="0">Offen</option>
                  {boardLists.map(boardList => <option key={boardList.boardListId} value={boardList.boardListId}>{boardList.name}</option>)}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </form>

    {Object.keys(grundanspruecheByThemenaspektId).map(themenaspektId => <Fragment key={themenaspektId}>
      <DialogSubtitle>{themenaspekteById[themenaspektId].code.id} | {themenaspekteById[themenaspektId].name}</DialogSubtitle>

      <table className="grundansprueche-table table is-fullwidth is-striped is-hoverable is-narrow mb-6">
        <thead>
        <tr>
          <th className="is-size-7 has-text-centered">Zyklus</th>
          {selectedKompetenzbereichId === '*' && <th className="is-size-7 has-text-centered">Kompetenzbereich</th>}
          <th className="is-size-7" colSpan={2}>Grundanspruch</th>
          {boardListId
          ? <th className="is-size-7"/>
          : <>
            <th className="is-size-7 rotate"><span>Offen</span></th>
            {boardLists.map(boardList => <th key={boardList.boardListId} className="is-size-7 rotate">
              <span>{boardList.name}</span>
            </th>)}
          </>}
        </tr>
        </thead>
        <tbody>
        {grundanspruecheByThemenaspektId[themenaspektId].map(grundanspruch => {
          let actions;
          const boardItem = addedBoardItemsByGrundanspruchIds[grundanspruch.grundanspruchId] || null;

          if (boardListId) {
            if (boardItem) {
              actions = <td>
                <button onClick={createHandleRemoveGrundanspruch(boardListId, boardItem.boardItemId)}
                        className={`button is-small is-danger ${isSaving ? 'is-loading' : ''}`}
                        disabled={isSaving}>
                  Von "{boardItem.list.name}" entfernen
                </button>
              </td>;
            } else {
              actions = <td>
                <button onClick={createHandleAddGrundanspruch(boardListId, grundanspruch.grundanspruchId)}
                        className={`button is-small is-primary ${isSaving ? 'is-loading' : ''}`}
                        disabled={isSaving}>
                  Hinzufügen
                </button>
              </td>;
            }
          } else {
            actions = <>
              <td className="is-size-7 has-text-centered">
                {boardItem
                ? <button className={`button is-small is-rounded is-text ${isSaving ? 'is-loading' : ''}`}
                          onClick={createHandleRemoveGrundanspruch(boardItem.list.boardListId, boardItem.boardItemId)}>
                  <Circle size={14}/>
                </button>
                : <button className={`button is-small is-rounded is-success is-disabled is-not-clickable ${isSaving ? 'is-loading' : ''}`}>
                  <CheckCircle size={14}/>
                </button>}
              </td>
              {boardLists.map(boardList => <td key={boardList.boardListId} className="is-size-7 has-text-centered">
                {boardItem && boardList.boardListId === boardItem.list.boardListId
                ? <button className={`button is-small is-rounded is-success is-disabled is-not-clickable ${isSaving ? 'is-loading' : ''}`}>
                  <CheckCircle size={14}/>
                </button>
                : <button className={`button is-small is-rounded is-text ${isSaving ? 'is-loading' : ''}`}
                          onClick={boardItem
                          ? createHandleMoveGrundanspruch(boardItem.boardItemId, boardItem.list.boardListId, boardList.boardListId)
                          : createHandleAddGrundanspruch(boardList.boardListId, grundanspruch.grundanspruchId)}>
                  <Circle size={14}/>
                </button>}
              </td>)}
            </>
          }

          return <tr key={grundanspruch.grundanspruchId}>
            <td className="is-size-7 has-text-centered">{grundanspruch.zyklen.join(', ')}</td>
            {selectedKompetenzbereichId === '*' && <td className="is-size-7 has-text-grey">{kompetenzbereicheById[grundanspruch.kompetenzbereichId].name}</td>}
            <td className="is-size-7 has-text-grey">{grundanspruch.code.full}</td>
            <td className="is-size-7">
              {grundanspruch.texts.map((text, idx) => <Fragment key={idx}>
                {idx !== 0 && <hr className="mt-1 mb-1"/>}
                <p>{text}</p>
              </Fragment>)}
            </td>
            {actions}
          </tr>
        })}
        </tbody>
      </table>
    </Fragment>)}
  </>
}
