import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {useApi, useAuth, UserContext} from "../lib/contexts";
import {Loader} from "../components";

import HomeScreen from "./HomeScreen";
import CurriculumScreen from "./CurriculumScreen";
import BoardScreen from "./BoardScreen";
import SchedulesScreen from "./SchedulesScreen";
import TopicsScreen from "./TopicsScreen";
import ReportsScreen from "./ReportsScreen";
import UserNotFoundScreen from "./UserNotFoundScreen";
import PicturesScreen from "./PicturesScreen";

export default function Navigator() {
  const api = useApi();
  const {user: authUser} = useAuth();
  const [user, setUser] = useState();
  const [isUserNotFound, setIsUserNotFound] = useState(false);

  useEffect(() => {
    api.users().getUserCollection(authUser.email, null, ['user']).then(({data}) => {
      if (data.length === 1) {
        setUser(data[0]);
      } else {
        setIsUserNotFound(true);
      }
    }).catch(err => console.error(err));
  }, []);

  if (isUserNotFound) {
    return <UserNotFoundScreen/>
  }

  if (!user) {
    return <Loader/>;
  }

  return <UserContext.Provider value={user}>
    <Router>
      <Switch>
        <Route path="/:pupilSlug/berichte" exact component={ReportsScreen}/>
        <Route path="/:pupilSlug/fotos" exact component={PicturesScreen}/>

        <Route path="/:pupilSlug/planung/:scheduleId" component={SchedulesScreen}/>
        <Route path="/:pupilSlug/planung" exact component={SchedulesScreen}/>

        <Route path="/:pupilSlug/themen/:topicId/:topicName" component={TopicsScreen}/>
        <Route path="/:pupilSlug/themen" exact component={TopicsScreen}/>

        <Route path="/:pupilSlug/board/:fachbereichId" component={BoardScreen}/>
        <Route path="/:pupilSlug/board" component={BoardScreen}/>
        
        <Route path="/:pupilSlug/fachbereiche/:fachbereichId" component={CurriculumScreen}/>
        <Route path="/:pupilSlug" exact component={CurriculumScreen}/>

        <Route path="/" exact component={HomeScreen}/>
      </Switch>
    </Router>
  </UserContext.Provider>;
}