import React, {Fragment, useEffect, useState} from "react";
import {DialogSubtitle} from "../../components";
import {X} from "react-feather";
import {useApi} from "../../lib/ApiContext";
import {natsort} from "../../lib/helpers";
import { useUser } from "../../lib/UserContext";
import { useParams } from "react-router";

export function AddGrundanspruchDialog({topicId, onTopicChanged, onClose}) {
  const api = useApi();

  const [topic, setTopic] = useState(null);

  const loadTopic = () => {
    api.topics().getTopicItem(topicId)
    .then(rsp => setTopic(rsp.data))
    .catch(err => console.error(err));
  };

  useEffect(() => {
    loadTopic();
  }, []);

  return <div className="modal is-active">
    <div className="modal-background" onClick={onClose}/>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{topic ? `Grundansprüche von ${topic.title} verwalten` : 'Lade ...'}</p>
        <button className="delete" aria-label="close" onClick={onClose}/>
      </header>
      <section className="modal-card-body">
        {topic !== null && <DialogBody topic={topic} loadTopic={loadTopic} onTopicChanged={onTopicChanged} />}
      </section>
    </div>
  </div>;
}

function DialogBody({topic, loadTopic, onTopicChanged}) {
  const user = useUser();
  const params = useParams();
  const api = useApi();
  
  const pupils = user.household.pupils;
  const pupil = pupils.filter(p => p.slug === params.pupilSlug)[0];

  const [isSaving, setIsSaving] = useState(false);

  const addGrundanspruecheById = {};
  for (const grundanspruch of topic.grundansprueche) {
    addGrundanspruecheById[grundanspruch.grundanspruchId] = grundanspruch;
  }

  const grundanspruchIris = topic.grundansprueche.map(g => `/grundansprueche/${g.grundanspruchId}`);

  const createHandleAddGrundanspruch = grundanspruchId => () => {
    setIsSaving(true);
    api.topics().patchTopicItem(topic.topicId, {
      grundansprueche: grundanspruchIris.concat(`/grundansprueche/${grundanspruchId}`),
    })
    .then(() => loadTopic())
    .then(() => onTopicChanged())
    .catch(err => console.error(err))
    .finally(() => setIsSaving(false));
  };

  const createHandleRemoveGrundanspruch = grundanspruchId => () => {
    setIsSaving(true);
    api.topics().patchTopicItem(topic.topicId, {
      grundansprueche: grundanspruchIris.filter(g => g !== `/grundansprueche/${grundanspruchId}`),
    })
    .then(() => loadTopic())
    .then(() => onTopicChanged())
    .catch(err => console.error(err))
    .finally(() => setIsSaving(false));
  };

  const [selectedZyklus, setSelectedZyklus] = useState(pupil.zyklus.toString());
  const [grundansprueche, setGrundansprueche] = useState([]);

  const [selectedFachbereichId, setSelectedFachbereichId] = useState('');
  const [fachbereiche, setFachbereiche] = useState([]);

  const [selectedKompetenzbereichId, setSelectedKompetenzbereichId] = useState('*');
  const [kompetenzbereiche, setKompetenzbereiche] = useState([]);

  const [searchFilter, setSearchFilter] = useState('');

  const selectFachbereich = fachbereichId => {
    setSelectedFachbereichId(fachbereichId);
    resetKompetenzbereich();
    if (fachbereichId === '') {
      return;
    }
    api.kompetenzbereiche().getKompetenzbereichCollection(fachbereichId)
    .then(rsp => {
      const sorter = natsort();

      setKompetenzbereiche(rsp.data.sort((a, b) => sorter(a.code.id, b.code.id)));
    })
    .then(() => selectKompetenzbereich(selectedKompetenzbereichId, fachbereichId))
    .catch(err => console.log(err));
  }

  const handleFachbereichChange = e => {
    selectFachbereich(e.target.value);
  };

  const resetKompetenzbereich = () => {
    setKompetenzbereiche([]);
    setSelectedKompetenzbereichId('*');
    resetGrundansprueche();
  };

  const selectKompetenzbereich = (kompetenzbereichId, fachbereichId) => {
    setSelectedKompetenzbereichId(kompetenzbereichId);
    resetGrundansprueche();

    if (kompetenzbereichId === '*') {
      kompetenzbereichId = undefined;
      if (!fachbereichId) {
        fachbereichId = selectedFachbereichId;
      }
    }

    api.grundansprueche()
    .getGrundanspruchCollection(undefined, undefined, kompetenzbereichId, undefined, fachbereichId)
    .then(rsp => setGrundansprueche(rsp.data))
    .catch(err => console.log(err));
  };

  const handleKompetenzbereichChange = e => {
    selectKompetenzbereich(e.target.value);
  };

  const handleZyklusChange = e => {
    setSelectedZyklus(e.target.value);
  };

  const resetGrundansprueche = () => {
    setGrundansprueche([]);
  };

  useEffect(() => {
    api.fachbereiche().getFachbereichCollection().then(rsp => setFachbereiche(rsp.data)).catch(err => console.log(err));
  }, []);

  let filteredGrundansprueche = grundansprueche.filter(g => g.zyklen[0] === parseInt(selectedZyklus));

  filteredGrundansprueche = filteredGrundansprueche.filter(grundanspruch => {
    const search = searchFilter.toLowerCase();

    if (grundanspruch.code.full.toLowerCase().includes(search)) {
      return true;
    }

    return grundanspruch.texts.join(' ').toLowerCase().includes(search);
  });

  const grundanspruecheByThemenaspektId = {};
  const themenaspekteById = {};
  for (const grundanspruch of filteredGrundansprueche) {
    const themenaspekt = grundanspruch.kompetenz.themenaspekt;
    const key = themenaspekt.code.id;
    if (grundanspruecheByThemenaspektId[key] === undefined) {
      grundanspruecheByThemenaspektId[key] = [];
      themenaspekteById[key] = themenaspekt;
    }
    grundanspruecheByThemenaspektId[key].push(grundanspruch);
  }

  const kompetenzbereicheById = {};
  for (const kompetenzbereich of kompetenzbereiche) {
    kompetenzbereicheById[kompetenzbereich.kompetenzbereichId] = kompetenzbereich;
  }

  return <>
    <form className="mb-6">
      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">Zyklus</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <div className="select">
                <select className="has-text-centered" value={selectedZyklus} onChange={handleZyklusChange}>
                  <option value="1">1. Zyklus (KiGa - 2. Klasse)</option>
                  <option value="2">2. Zyklus (3. - 6. Klasse)</option>
                  <option value="3">3. Zyklus (7. - 9. Klasse)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">Fachbereich</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <div className="select">
                <select value={selectedFachbereichId} onChange={handleFachbereichChange}>
                  <option value="">--- Bitte wählen ---</option>
                  {fachbereiche.map(fachbereich => <option key={fachbereich.fachbereichId} value={fachbereich.fachbereichId}>{fachbereich.name}</option>)}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      {kompetenzbereiche.length > 0 && <>
        <div className="field is-horizontal">
          <div className="field-label">
            <label className="label">Kompetenzbereich</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <div className="select">
                  <select value={selectedKompetenzbereichId} onChange={handleKompetenzbereichChange}>
                    <option value="*">Alle</option>
                    {kompetenzbereiche.map(kompetenzbereich => <option key={kompetenzbereich.kompetenzbereichId} value={kompetenzbereich.kompetenzbereichId}>
                      {kompetenzbereich.code.id} | {kompetenzbereich.name}
                    </option>)}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label">
            <label className="label">Textsuche</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control has-icons-right">
                <input className="input" placeholder="Suchen ..."
                       value={searchFilter} onChange={e => setSearchFilter(e.target.value)}/>
                <span className="icon is-small is-right is-clickable" onClick={() => setSearchFilter('')}>
                        <X size={12}/>
                      </span>
              </div>
            </div>
          </div>
        </div>
      </>}
    </form>

    {Object.keys(grundanspruecheByThemenaspektId).map(themenaspektId => <Fragment key={themenaspektId}>
      <DialogSubtitle>{themenaspekteById[themenaspektId].code.id} | {themenaspekteById[themenaspektId].name}</DialogSubtitle>

      <table className="grundansprueche-table table is-fullwidth is-striped is-hoverable is-narrow mb-6">
        <thead>
        <tr>
          <th className="is-size-7 has-text-centered">Zyklus</th>
          {selectedKompetenzbereichId === '*' && <th className="is-size-7 has-text-centered">Kompetenzbereich</th>}
          <th className="is-size-7" colSpan={2}>Grundanspruch</th>
          <th className="is-size-7"/>
        </tr>
        </thead>
        <tbody>
        {grundanspruecheByThemenaspektId[themenaspektId].map(grundanspruch => {
          return <tr key={grundanspruch.grundanspruchId}>
            <td className="is-size-7 has-text-centered">{grundanspruch.zyklen.join(', ')}</td>
            {selectedKompetenzbereichId === '*' && <td className="is-size-7 has-text-grey">{kompetenzbereicheById[grundanspruch.kompetenzbereichId].name}</td>}
            <td className="is-size-7 has-text-grey">{grundanspruch.code.full}</td>
            <td className="is-size-7">
              {grundanspruch.texts.map((text, idx) => <Fragment key={idx}>
                {idx !== 0 && <hr className="mt-1 mb-1"/>}
                <p>{text}</p>
              </Fragment>)}
            </td>
            <td>
              {addGrundanspruecheById[grundanspruch.grundanspruchId] === undefined
              ? <button onClick={createHandleAddGrundanspruch(grundanspruch.grundanspruchId)}
                        className={`button is-small is-primary ${isSaving ? 'is-loading' : ''}`}
                        disabled={isSaving}>
                Hinzufügen
              </button> :
              <button onClick={createHandleRemoveGrundanspruch(grundanspruch.grundanspruchId)}
                      className={`button is-small is-danger ${isSaving ? 'is-loading' : ''}`}
                      disabled={isSaving}>
                Entfernen
              </button>}
            </td>
          </tr>
        })}
        </tbody>
      </table>
    </Fragment>)}
  </>
}