/* tslint:disable */
/* eslint-disable */
/**
 * kanban21 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BoardItem
 */
export interface BoardItem {
    /**
     * 
     * @type {number}
     * @memberof BoardItem
     */
    'sequence'?: number;
    /**
     * 
     * @type {BoardList}
     * @memberof BoardItem
     */
    'list': BoardList;
    /**
     * 
     * @type {string}
     * @memberof BoardItem
     */
    'pupil': string;
    /**
     * 
     * @type {Grundanspruch}
     * @memberof BoardItem
     */
    'grundanspruch': Grundanspruch;
    /**
     * 
     * @type {Array<BoardItemNote>}
     * @memberof BoardItem
     */
    'notes'?: Array<BoardItemNote>;
    /**
     * 
     * @type {number}
     * @memberof BoardItem
     */
    'boardItemId'?: number;
    /**
     * 
     * @type {Array<Upload>}
     * @memberof BoardItem
     */
    'files'?: Array<Upload>;
}
/**
 * 
 * @export
 * @interface BoardItemBoardItem
 */
export interface BoardItemBoardItem {
    /**
     * 
     * @type {number}
     * @memberof BoardItemBoardItem
     */
    'boardItemId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoardItemBoardItem
     */
    'sequence'?: number;
    /**
     * 
     * @type {BoardListBoardItem}
     * @memberof BoardItemBoardItem
     */
    'list': BoardListBoardItem;
    /**
     * 
     * @type {string}
     * @memberof BoardItemBoardItem
     */
    'pupil': string;
    /**
     * 
     * @type {GrundanspruchBoardItem}
     * @memberof BoardItemBoardItem
     */
    'grundanspruch': GrundanspruchBoardItem;
    /**
     * 
     * @type {Array<BoardItemNoteBoardItem>}
     * @memberof BoardItemBoardItem
     */
    'notes'?: Array<BoardItemNoteBoardItem>;
    /**
     * 
     * @type {Array<UploadBoardItem>}
     * @memberof BoardItemBoardItem
     */
    'files'?: Array<UploadBoardItem>;
}
/**
 * 
 * @export
 * @interface BoardItemBoardItems
 */
export interface BoardItemBoardItems {
    /**
     * 
     * @type {number}
     * @memberof BoardItemBoardItems
     */
    'boardItemId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoardItemBoardItems
     */
    'sequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardItemBoardItems
     */
    'list': string;
    /**
     * 
     * @type {string}
     * @memberof BoardItemBoardItems
     */
    'pupil': string;
    /**
     * 
     * @type {string}
     * @memberof BoardItemBoardItems
     */
    'grundanspruch': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BoardItemBoardItems
     */
    'notes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface BoardItemBoardList
 */
export interface BoardItemBoardList {
    /**
     * 
     * @type {number}
     * @memberof BoardItemBoardList
     */
    'boardItemId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoardItemBoardList
     */
    'sequence'?: number;
    /**
     * 
     * @type {PupilBoardList}
     * @memberof BoardItemBoardList
     */
    'pupil': PupilBoardList;
    /**
     * 
     * @type {GrundanspruchBoardList}
     * @memberof BoardItemBoardList
     */
    'grundanspruch': GrundanspruchBoardList;
    /**
     * 
     * @type {Array<BoardItemNoteBoardList>}
     * @memberof BoardItemBoardList
     */
    'notes'?: Array<BoardItemNoteBoardList>;
}
/**
 * 
 * @export
 * @interface BoardItemNote
 */
export interface BoardItemNote {
    /**
     * 
     * @type {string}
     * @memberof BoardItemNote
     */
    'item': string;
    /**
     * 
     * @type {string}
     * @memberof BoardItemNote
     */
    'text': string;
    /**
     * 
     * @type {User}
     * @memberof BoardItemNote
     */
    'author': User;
    /**
     * 
     * @type {string}
     * @memberof BoardItemNote
     */
    'addedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof BoardItemNote
     */
    'boardItemNoteId'?: number;
    /**
     * 
     * @type {User}
     * @memberof BoardItemNote
     */
    'currentUser'?: User;
}
/**
 * 
 * @export
 * @interface BoardItemNoteBoardItem
 */
export interface BoardItemNoteBoardItem {
    /**
     * 
     * @type {number}
     * @memberof BoardItemNoteBoardItem
     */
    'boardItemNoteId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardItemNoteBoardItem
     */
    'text': string;
    /**
     * 
     * @type {UserBoardItem}
     * @memberof BoardItemNoteBoardItem
     */
    'author': UserBoardItem;
    /**
     * 
     * @type {string}
     * @memberof BoardItemNoteBoardItem
     */
    'addedAt'?: string;
}
/**
 * 
 * @export
 * @interface BoardItemNoteBoardItemNote
 */
export interface BoardItemNoteBoardItemNote {
    /**
     * 
     * @type {number}
     * @memberof BoardItemNoteBoardItemNote
     */
    'boardItemNoteId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardItemNoteBoardItemNote
     */
    'item': string;
    /**
     * 
     * @type {string}
     * @memberof BoardItemNoteBoardItemNote
     */
    'text': string;
    /**
     * 
     * @type {UserBoardItemNote}
     * @memberof BoardItemNoteBoardItemNote
     */
    'author': UserBoardItemNote;
    /**
     * 
     * @type {string}
     * @memberof BoardItemNoteBoardItemNote
     */
    'addedAt'?: string;
}
/**
 * 
 * @export
 * @interface BoardItemNoteBoardItemNotes
 */
export interface BoardItemNoteBoardItemNotes {
    /**
     * 
     * @type {number}
     * @memberof BoardItemNoteBoardItemNotes
     */
    'boardItemNoteId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardItemNoteBoardItemNotes
     */
    'item': string;
    /**
     * 
     * @type {string}
     * @memberof BoardItemNoteBoardItemNotes
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof BoardItemNoteBoardItemNotes
     */
    'author': string;
    /**
     * 
     * @type {string}
     * @memberof BoardItemNoteBoardItemNotes
     */
    'addedAt'?: string;
}
/**
 * 
 * @export
 * @interface BoardItemNoteBoardList
 */
export interface BoardItemNoteBoardList {
    /**
     * 
     * @type {number}
     * @memberof BoardItemNoteBoardList
     */
    'boardItemNoteId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardItemNoteBoardList
     */
    'text': string;
    /**
     * 
     * @type {UserBoardList}
     * @memberof BoardItemNoteBoardList
     */
    'author': UserBoardList;
    /**
     * 
     * @type {string}
     * @memberof BoardItemNoteBoardList
     */
    'addedAt'?: string;
}
/**
 * 
 * @export
 * @interface BoardItemPupil
 */
export interface BoardItemPupil {
    /**
     * 
     * @type {number}
     * @memberof BoardItemPupil
     */
    'boardItemId'?: number;
    /**
     * 
     * @type {BoardListPupil}
     * @memberof BoardItemPupil
     */
    'list': BoardListPupil;
    /**
     * 
     * @type {GrundanspruchPupil}
     * @memberof BoardItemPupil
     */
    'grundanspruch': GrundanspruchPupil;
}
/**
 * 
 * @export
 * @interface BoardItemUpload
 */
export interface BoardItemUpload {
    /**
     * 
     * @type {number}
     * @memberof BoardItemUpload
     */
    'boardItemId'?: number;
    /**
     * 
     * @type {PupilUpload}
     * @memberof BoardItemUpload
     */
    'pupil': PupilUpload;
    /**
     * 
     * @type {GrundanspruchUpload}
     * @memberof BoardItemUpload
     */
    'grundanspruch': GrundanspruchUpload;
}
/**
 * 
 * @export
 * @interface BoardList
 */
export interface BoardList {
    /**
     * 
     * @type {string}
     * @memberof BoardList
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BoardList
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof BoardList
     */
    'sequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardList
     */
    'household': string;
    /**
     * 
     * @type {Array<BoardItem>}
     * @memberof BoardList
     */
    'items'?: Array<BoardItem>;
    /**
     * 
     * @type {number}
     * @memberof BoardList
     */
    'boardListId'?: number;
}
/**
 * 
 * @export
 * @interface BoardListBoardItem
 */
export interface BoardListBoardItem {
    /**
     * 
     * @type {number}
     * @memberof BoardListBoardItem
     */
    'boardListId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardListBoardItem
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface BoardListBoardList
 */
export interface BoardListBoardList {
    /**
     * 
     * @type {number}
     * @memberof BoardListBoardList
     */
    'boardListId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardListBoardList
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BoardListBoardList
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof BoardListBoardList
     */
    'sequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardListBoardList
     */
    'household': string;
    /**
     * 
     * @type {Array<BoardItemBoardList>}
     * @memberof BoardListBoardList
     */
    'items'?: Array<BoardItemBoardList>;
}
/**
 * 
 * @export
 * @interface BoardListBoardLists
 */
export interface BoardListBoardLists {
    /**
     * 
     * @type {number}
     * @memberof BoardListBoardLists
     */
    'boardListId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardListBoardLists
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BoardListBoardLists
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof BoardListBoardLists
     */
    'sequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardListBoardLists
     */
    'household': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BoardListBoardLists
     */
    'items'?: Array<string>;
}
/**
 * 
 * @export
 * @interface BoardListPupil
 */
export interface BoardListPupil {
    /**
     * 
     * @type {number}
     * @memberof BoardListPupil
     */
    'boardListId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardListPupil
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface BoardListUser
 */
export interface BoardListUser {
    /**
     * 
     * @type {number}
     * @memberof BoardListUser
     */
    'boardListId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardListUser
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BoardListUser
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof BoardListUser
     */
    'sequence'?: number;
}
/**
 * 
 * @export
 * @interface FachbereichFachbereich
 */
export interface FachbereichFachbereich {
    /**
     * 
     * @type {string}
     * @memberof FachbereichFachbereich
     */
    'fachbereichId'?: string;
    /**
     * 
     * @type {Lp21CodeFachbereich}
     * @memberof FachbereichFachbereich
     */
    'code': Lp21CodeFachbereich;
    /**
     * 
     * @type {string}
     * @memberof FachbereichFachbereich
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FachbereichFachbereich
     */
    'numGrundansprueche': Array<string>;
    /**
     * 
     * @type {Array<KompetenzbereichFachbereich>}
     * @memberof FachbereichFachbereich
     */
    'kompetenzbereiche'?: Array<KompetenzbereichFachbereich>;
}
/**
 * 
 * @export
 * @interface FachbereichFachbereiche
 */
export interface FachbereichFachbereiche {
    /**
     * 
     * @type {string}
     * @memberof FachbereichFachbereiche
     */
    'fachbereichId'?: string;
    /**
     * 
     * @type {Lp21CodeFachbereiche}
     * @memberof FachbereichFachbereiche
     */
    'code': Lp21CodeFachbereiche;
    /**
     * 
     * @type {string}
     * @memberof FachbereichFachbereiche
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FachbereichFachbereiche
     */
    'numGrundansprueche': Array<string>;
    /**
     * 
     * @type {Array<KompetenzbereichFachbereiche>}
     * @memberof FachbereichFachbereiche
     */
    'kompetenzbereiche'?: Array<KompetenzbereichFachbereiche>;
}
/**
 * 
 * @export
 * @interface FachbereichKompetenzbereich
 */
export interface FachbereichKompetenzbereich {
    /**
     * 
     * @type {string}
     * @memberof FachbereichKompetenzbereich
     */
    'fachbereichId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FachbereichKompetenzbereich
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FachbereichKompetenzbereiche
 */
export interface FachbereichKompetenzbereiche {
    /**
     * 
     * @type {string}
     * @memberof FachbereichKompetenzbereiche
     */
    'fachbereichId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FachbereichKompetenzbereiche
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Grundanspruch
 */
export interface Grundanspruch {
    /**
     * 
     * @type {Lp21Code}
     * @memberof Grundanspruch
     */
    'code': Lp21Code;
    /**
     * 
     * @type {Array<string>}
     * @memberof Grundanspruch
     */
    'texts': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Grundanspruch
     */
    'zyklen': Array<string>;
    /**
     * 
     * @type {Kompetenz}
     * @memberof Grundanspruch
     */
    'kompetenz': Kompetenz;
    /**
     * 
     * @type {Array<string>}
     * @memberof Grundanspruch
     */
    'boardItems'?: Array<string>;
    /**
     * 
     * @type {Array<Topic>}
     * @memberof Grundanspruch
     */
    'topics'?: Array<Topic>;
    /**
     * 
     * @type {string}
     * @memberof Grundanspruch
     */
    'grundanspruchId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Grundanspruch
     */
    'kompetenzbereichId'?: string;
}
/**
 * 
 * @export
 * @interface GrundanspruchBoardItem
 */
export interface GrundanspruchBoardItem {
    /**
     * 
     * @type {string}
     * @memberof GrundanspruchBoardItem
     */
    'grundanspruchId'?: string;
    /**
     * 
     * @type {Lp21CodeBoardItem}
     * @memberof GrundanspruchBoardItem
     */
    'code': Lp21CodeBoardItem;
    /**
     * 
     * @type {Array<string>}
     * @memberof GrundanspruchBoardItem
     */
    'texts': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GrundanspruchBoardItem
     */
    'zyklen': Array<string>;
}
/**
 * 
 * @export
 * @interface GrundanspruchBoardList
 */
export interface GrundanspruchBoardList {
    /**
     * 
     * @type {string}
     * @memberof GrundanspruchBoardList
     */
    'grundanspruchId'?: string;
    /**
     * 
     * @type {Lp21CodeBoardList}
     * @memberof GrundanspruchBoardList
     */
    'code': Lp21CodeBoardList;
    /**
     * 
     * @type {Array<string>}
     * @memberof GrundanspruchBoardList
     */
    'texts': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GrundanspruchBoardList
     */
    'zyklen': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GrundanspruchBoardList
     */
    'kompetenz': string;
    /**
     * 
     * @type {string}
     * @memberof GrundanspruchBoardList
     */
    'kompetenzbereichId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrundanspruchBoardList
     */
    'fachbereichId'?: string;
}
/**
 * 
 * @export
 * @interface GrundanspruchGrundanspruch
 */
export interface GrundanspruchGrundanspruch {
    /**
     * 
     * @type {string}
     * @memberof GrundanspruchGrundanspruch
     */
    'grundanspruchId'?: string;
    /**
     * 
     * @type {Lp21CodeGrundanspruch}
     * @memberof GrundanspruchGrundanspruch
     */
    'code': Lp21CodeGrundanspruch;
    /**
     * 
     * @type {Array<string>}
     * @memberof GrundanspruchGrundanspruch
     */
    'texts': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GrundanspruchGrundanspruch
     */
    'zyklen': Array<string>;
    /**
     * 
     * @type {KompetenzGrundanspruch}
     * @memberof GrundanspruchGrundanspruch
     */
    'kompetenz': KompetenzGrundanspruch;
    /**
     * 
     * @type {Array<string>}
     * @memberof GrundanspruchGrundanspruch
     */
    'boardItems'?: Array<string>;
    /**
     * 
     * @type {Array<TopicGrundanspruch>}
     * @memberof GrundanspruchGrundanspruch
     */
    'topics'?: Array<TopicGrundanspruch>;
    /**
     * 
     * @type {string}
     * @memberof GrundanspruchGrundanspruch
     */
    'kompetenzbereichId'?: string;
}
/**
 * 
 * @export
 * @interface GrundanspruchGrundansprueche
 */
export interface GrundanspruchGrundansprueche {
    /**
     * 
     * @type {string}
     * @memberof GrundanspruchGrundansprueche
     */
    'grundanspruchId'?: string;
    /**
     * 
     * @type {Lp21CodeGrundansprueche}
     * @memberof GrundanspruchGrundansprueche
     */
    'code': Lp21CodeGrundansprueche;
    /**
     * 
     * @type {Array<string>}
     * @memberof GrundanspruchGrundansprueche
     */
    'texts': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GrundanspruchGrundansprueche
     */
    'zyklen': Array<string>;
    /**
     * 
     * @type {KompetenzGrundansprueche}
     * @memberof GrundanspruchGrundansprueche
     */
    'kompetenz': KompetenzGrundansprueche;
    /**
     * 
     * @type {Array<string>}
     * @memberof GrundanspruchGrundansprueche
     */
    'boardItems'?: Array<string>;
    /**
     * 
     * @type {Array<TopicGrundansprueche>}
     * @memberof GrundanspruchGrundansprueche
     */
    'topics'?: Array<TopicGrundansprueche>;
    /**
     * 
     * @type {string}
     * @memberof GrundanspruchGrundansprueche
     */
    'kompetenzbereichId'?: string;
}
/**
 * 
 * @export
 * @interface GrundanspruchPupil
 */
export interface GrundanspruchPupil {
    /**
     * 
     * @type {string}
     * @memberof GrundanspruchPupil
     */
    'grundanspruchId'?: string;
}
/**
 * 
 * @export
 * @interface GrundanspruchTopic
 */
export interface GrundanspruchTopic {
    /**
     * 
     * @type {string}
     * @memberof GrundanspruchTopic
     */
    'grundanspruchId'?: string;
    /**
     * 
     * @type {Lp21CodeTopic}
     * @memberof GrundanspruchTopic
     */
    'code': Lp21CodeTopic;
    /**
     * 
     * @type {Array<string>}
     * @memberof GrundanspruchTopic
     */
    'texts': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GrundanspruchTopic
     */
    'zyklen': Array<string>;
}
/**
 * 
 * @export
 * @interface GrundanspruchTopics
 */
export interface GrundanspruchTopics {
    /**
     * 
     * @type {string}
     * @memberof GrundanspruchTopics
     */
    'grundanspruchId'?: string;
}
/**
 * 
 * @export
 * @interface GrundanspruchUpload
 */
export interface GrundanspruchUpload {
    /**
     * 
     * @type {string}
     * @memberof GrundanspruchUpload
     */
    'grundanspruchId'?: string;
    /**
     * 
     * @type {Lp21CodeUpload}
     * @memberof GrundanspruchUpload
     */
    'code': Lp21CodeUpload;
}
/**
 * 
 * @export
 * @interface Household
 */
export interface Household {
    /**
     * 
     * @type {string}
     * @memberof Household
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Household
     */
    'pupils'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Household
     */
    'boardLists'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Household
     */
    'topics'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Household
     */
    'users'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Household
     */
    'householdId'?: number;
}
/**
 * 
 * @export
 * @interface HouseholdHousehold
 */
export interface HouseholdHousehold {
    /**
     * 
     * @type {number}
     * @memberof HouseholdHousehold
     */
    'householdId'?: number;
    /**
     * 
     * @type {string}
     * @memberof HouseholdHousehold
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HouseholdHousehold
     */
    'pupils'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HouseholdHousehold
     */
    'boardLists'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HouseholdHousehold
     */
    'users'?: Array<string>;
}
/**
 * 
 * @export
 * @interface HouseholdHouseholds
 */
export interface HouseholdHouseholds {
    /**
     * 
     * @type {number}
     * @memberof HouseholdHouseholds
     */
    'householdId'?: number;
    /**
     * 
     * @type {string}
     * @memberof HouseholdHouseholds
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface HouseholdUser
 */
export interface HouseholdUser {
    /**
     * 
     * @type {number}
     * @memberof HouseholdUser
     */
    'householdId'?: number;
    /**
     * 
     * @type {string}
     * @memberof HouseholdUser
     */
    'name': string;
    /**
     * 
     * @type {Array<PupilUser>}
     * @memberof HouseholdUser
     */
    'pupils'?: Array<PupilUser>;
    /**
     * 
     * @type {Array<BoardListUser>}
     * @memberof HouseholdUser
     */
    'boardLists'?: Array<BoardListUser>;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * List of board item IRIs
     * @type {Array<string>}
     * @memberof InlineObject1
     */
    'sequence'?: Array<string>;
}
/**
 * 
 * @export
 * @interface JournalEntry
 */
export interface JournalEntry {
    /**
     * 
     * @type {string}
     * @memberof JournalEntry
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntry
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntry
     */
    'pupil': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntry
     */
    'author': string;
    /**
     * 
     * @type {number}
     * @memberof JournalEntry
     */
    'journalEntryId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof JournalEntry
     */
    'files'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof JournalEntry
     */
    'currentUser'?: string;
}
/**
 * 
 * @export
 * @interface JournalEntryJournal
 */
export interface JournalEntryJournal {
    /**
     * 
     * @type {number}
     * @memberof JournalEntryJournal
     */
    'journalEntryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryJournal
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryJournal
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryJournal
     */
    'pupil': string;
    /**
     * 
     * @type {Array<UploadJournal>}
     * @memberof JournalEntryJournal
     */
    'files'?: Array<UploadJournal>;
}
/**
 * 
 * @export
 * @interface JournalEntryJournalEntry
 */
export interface JournalEntryJournalEntry {
    /**
     * 
     * @type {number}
     * @memberof JournalEntryJournalEntry
     */
    'journalEntryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryJournalEntry
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryJournalEntry
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryJournalEntry
     */
    'pupil': string;
}
/**
 * 
 * @export
 * @interface JournalEntryUpload
 */
export interface JournalEntryUpload {
    /**
     * 
     * @type {number}
     * @memberof JournalEntryUpload
     */
    'journalEntryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryUpload
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryUpload
     */
    'text': string;
    /**
     * 
     * @type {PupilUpload}
     * @memberof JournalEntryUpload
     */
    'pupil': PupilUpload;
}
/**
 * 
 * @export
 * @interface Kompetenz
 */
export interface Kompetenz {
    /**
     * 
     * @type {Lp21Code}
     * @memberof Kompetenz
     */
    'code': Lp21Code;
    /**
     * 
     * @type {string}
     * @memberof Kompetenz
     */
    'beschreibung': string;
    /**
     * 
     * @type {string}
     * @memberof Kompetenz
     */
    'themenaspekt': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Kompetenz
     */
    'grundansprueche'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Kompetenz
     */
    'grundanspruch'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Kompetenz
     */
    'kompetenzId'?: string;
}
/**
 * 
 * @export
 * @interface KompetenzGrundanspruch
 */
export interface KompetenzGrundanspruch {
    /**
     * 
     * @type {string}
     * @memberof KompetenzGrundanspruch
     */
    'kompetenzId'?: string;
    /**
     * 
     * @type {ThemenaspektGrundanspruch}
     * @memberof KompetenzGrundanspruch
     */
    'themenaspekt': ThemenaspektGrundanspruch;
}
/**
 * 
 * @export
 * @interface KompetenzGrundansprueche
 */
export interface KompetenzGrundansprueche {
    /**
     * 
     * @type {string}
     * @memberof KompetenzGrundansprueche
     */
    'kompetenzId'?: string;
    /**
     * 
     * @type {ThemenaspektGrundansprueche}
     * @memberof KompetenzGrundansprueche
     */
    'themenaspekt': ThemenaspektGrundansprueche;
}
/**
 * 
 * @export
 * @interface KompetenzKompetenz
 */
export interface KompetenzKompetenz {
    /**
     * 
     * @type {string}
     * @memberof KompetenzKompetenz
     */
    'kompetenzId'?: string;
    /**
     * 
     * @type {Lp21CodeKompetenz}
     * @memberof KompetenzKompetenz
     */
    'code': Lp21CodeKompetenz;
    /**
     * 
     * @type {string}
     * @memberof KompetenzKompetenz
     */
    'beschreibung': string;
    /**
     * 
     * @type {string}
     * @memberof KompetenzKompetenz
     */
    'themenaspekt': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KompetenzKompetenz
     */
    'grundansprueche'?: Array<string>;
}
/**
 * 
 * @export
 * @interface KompetenzKompetenzen
 */
export interface KompetenzKompetenzen {
    /**
     * 
     * @type {string}
     * @memberof KompetenzKompetenzen
     */
    'kompetenzId'?: string;
    /**
     * 
     * @type {Lp21CodeKompetenzen}
     * @memberof KompetenzKompetenzen
     */
    'code': Lp21CodeKompetenzen;
    /**
     * 
     * @type {string}
     * @memberof KompetenzKompetenzen
     */
    'beschreibung': string;
    /**
     * 
     * @type {string}
     * @memberof KompetenzKompetenzen
     */
    'themenaspekt': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KompetenzKompetenzen
     */
    'grundansprueche'?: Array<string>;
}
/**
 * 
 * @export
 * @interface KompetenzbereichFachbereich
 */
export interface KompetenzbereichFachbereich {
    /**
     * 
     * @type {string}
     * @memberof KompetenzbereichFachbereich
     */
    'kompetenzbereichId'?: string;
    /**
     * 
     * @type {Lp21CodeFachbereich}
     * @memberof KompetenzbereichFachbereich
     */
    'code': Lp21CodeFachbereich;
    /**
     * 
     * @type {string}
     * @memberof KompetenzbereichFachbereich
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KompetenzbereichFachbereich
     */
    'numGrundansprueche': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof KompetenzbereichFachbereich
     */
    'themenaspekte'?: Array<string>;
}
/**
 * 
 * @export
 * @interface KompetenzbereichFachbereiche
 */
export interface KompetenzbereichFachbereiche {
    /**
     * 
     * @type {string}
     * @memberof KompetenzbereichFachbereiche
     */
    'kompetenzbereichId'?: string;
    /**
     * 
     * @type {Lp21CodeFachbereiche}
     * @memberof KompetenzbereichFachbereiche
     */
    'code': Lp21CodeFachbereiche;
    /**
     * 
     * @type {string}
     * @memberof KompetenzbereichFachbereiche
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KompetenzbereichFachbereiche
     */
    'numGrundansprueche': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof KompetenzbereichFachbereiche
     */
    'themenaspekte'?: Array<string>;
}
/**
 * 
 * @export
 * @interface KompetenzbereichKompetenzbereich
 */
export interface KompetenzbereichKompetenzbereich {
    /**
     * 
     * @type {string}
     * @memberof KompetenzbereichKompetenzbereich
     */
    'kompetenzbereichId'?: string;
    /**
     * 
     * @type {Lp21CodeKompetenzbereich}
     * @memberof KompetenzbereichKompetenzbereich
     */
    'code': Lp21CodeKompetenzbereich;
    /**
     * 
     * @type {string}
     * @memberof KompetenzbereichKompetenzbereich
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KompetenzbereichKompetenzbereich
     */
    'numGrundansprueche': Array<string>;
    /**
     * 
     * @type {FachbereichKompetenzbereich}
     * @memberof KompetenzbereichKompetenzbereich
     */
    'fachbereich': FachbereichKompetenzbereich;
}
/**
 * 
 * @export
 * @interface KompetenzbereichKompetenzbereiche
 */
export interface KompetenzbereichKompetenzbereiche {
    /**
     * 
     * @type {string}
     * @memberof KompetenzbereichKompetenzbereiche
     */
    'kompetenzbereichId'?: string;
    /**
     * 
     * @type {Lp21CodeKompetenzbereiche}
     * @memberof KompetenzbereichKompetenzbereiche
     */
    'code': Lp21CodeKompetenzbereiche;
    /**
     * 
     * @type {string}
     * @memberof KompetenzbereichKompetenzbereiche
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KompetenzbereichKompetenzbereiche
     */
    'numGrundansprueche': Array<string>;
    /**
     * 
     * @type {FachbereichKompetenzbereiche}
     * @memberof KompetenzbereichKompetenzbereiche
     */
    'fachbereich': FachbereichKompetenzbereiche;
}
/**
 * 
 * @export
 * @interface KompetenzbereichThemenaspekt
 */
export interface KompetenzbereichThemenaspekt {
    /**
     * 
     * @type {string}
     * @memberof KompetenzbereichThemenaspekt
     */
    'kompetenzbereichId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KompetenzbereichThemenaspekt
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface KompetenzbereichThemenaspekte
 */
export interface KompetenzbereichThemenaspekte {
    /**
     * 
     * @type {string}
     * @memberof KompetenzbereichThemenaspekte
     */
    'kompetenzbereichId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KompetenzbereichThemenaspekte
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Lp21Code
 */
export interface Lp21Code {
    /**
     * 
     * @type {string}
     * @memberof Lp21Code
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21Code
     */
    'full': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21Code
     */
    'fullUrlSafe'?: string;
}
/**
 * 
 * @export
 * @interface Lp21CodeBoardItem
 */
export interface Lp21CodeBoardItem {
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeBoardItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeBoardItem
     */
    'full': string;
}
/**
 * 
 * @export
 * @interface Lp21CodeBoardList
 */
export interface Lp21CodeBoardList {
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeBoardList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeBoardList
     */
    'full': string;
}
/**
 * 
 * @export
 * @interface Lp21CodeFachbereich
 */
export interface Lp21CodeFachbereich {
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeFachbereich
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeFachbereich
     */
    'full': string;
}
/**
 * 
 * @export
 * @interface Lp21CodeFachbereiche
 */
export interface Lp21CodeFachbereiche {
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeFachbereiche
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeFachbereiche
     */
    'full': string;
}
/**
 * 
 * @export
 * @interface Lp21CodeGrundanspruch
 */
export interface Lp21CodeGrundanspruch {
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeGrundanspruch
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeGrundanspruch
     */
    'full': string;
}
/**
 * 
 * @export
 * @interface Lp21CodeGrundansprueche
 */
export interface Lp21CodeGrundansprueche {
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeGrundansprueche
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeGrundansprueche
     */
    'full': string;
}
/**
 * 
 * @export
 * @interface Lp21CodeKompetenz
 */
export interface Lp21CodeKompetenz {
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeKompetenz
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeKompetenz
     */
    'full': string;
}
/**
 * 
 * @export
 * @interface Lp21CodeKompetenzbereich
 */
export interface Lp21CodeKompetenzbereich {
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeKompetenzbereich
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeKompetenzbereich
     */
    'full': string;
}
/**
 * 
 * @export
 * @interface Lp21CodeKompetenzbereiche
 */
export interface Lp21CodeKompetenzbereiche {
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeKompetenzbereiche
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeKompetenzbereiche
     */
    'full': string;
}
/**
 * 
 * @export
 * @interface Lp21CodeKompetenzen
 */
export interface Lp21CodeKompetenzen {
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeKompetenzen
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeKompetenzen
     */
    'full': string;
}
/**
 * 
 * @export
 * @interface Lp21CodeThemenaspekt
 */
export interface Lp21CodeThemenaspekt {
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeThemenaspekt
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeThemenaspekt
     */
    'full': string;
}
/**
 * 
 * @export
 * @interface Lp21CodeThemenaspekte
 */
export interface Lp21CodeThemenaspekte {
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeThemenaspekte
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeThemenaspekte
     */
    'full': string;
}
/**
 * 
 * @export
 * @interface Lp21CodeTopic
 */
export interface Lp21CodeTopic {
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeTopic
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeTopic
     */
    'full': string;
}
/**
 * 
 * @export
 * @interface Lp21CodeUpload
 */
export interface Lp21CodeUpload {
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeUpload
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lp21CodeUpload
     */
    'full': string;
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * Returns the mime type of the file.
     * @type {string}
     * @memberof ModelFile
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'path'?: string;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'filename'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'extension'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'basename'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'pathname'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'perms'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'inode'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'size'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'owner'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'group'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'ATime'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'MTime'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'CTime'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'type'?: any;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'writable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'readable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'executable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'file'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'dir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'link'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'linkTarget'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'realPath'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'fileInfo'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'pathInfo'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'fileClass'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'infoClass'?: any;
}
/**
 * 
 * @export
 * @interface Pupil
 */
export interface Pupil {
    /**
     * 
     * @type {string}
     * @memberof Pupil
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Pupil
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pupil
     */
    'avatar': string;
    /**
     * 
     * @type {number}
     * @memberof Pupil
     */
    'zyklus': number;
    /**
     * 
     * @type {string}
     * @memberof Pupil
     */
    'household': string;
    /**
     * 
     * @type {Array<BoardItem>}
     * @memberof Pupil
     */
    'boardItems'?: Array<BoardItem>;
    /**
     * 
     * @type {Array<Schedule>}
     * @memberof Pupil
     */
    'schedules'?: Array<Schedule>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pupil
     */
    'journal'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Pupil
     */
    'pupilId'?: number;
}
/**
 * 
 * @export
 * @interface PupilBoardList
 */
export interface PupilBoardList {
    /**
     * 
     * @type {number}
     * @memberof PupilBoardList
     */
    'pupilId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PupilBoardList
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PupilBoardList
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof PupilBoardList
     */
    'avatar': string;
    /**
     * 
     * @type {number}
     * @memberof PupilBoardList
     */
    'zyklus': number;
}
/**
 * 
 * @export
 * @interface PupilPupil
 */
export interface PupilPupil {
    /**
     * 
     * @type {number}
     * @memberof PupilPupil
     */
    'pupilId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PupilPupil
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PupilPupil
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof PupilPupil
     */
    'avatar': string;
    /**
     * 
     * @type {number}
     * @memberof PupilPupil
     */
    'zyklus': number;
    /**
     * 
     * @type {string}
     * @memberof PupilPupil
     */
    'household': string;
    /**
     * 
     * @type {Array<BoardItemPupil>}
     * @memberof PupilPupil
     */
    'boardItems'?: Array<BoardItemPupil>;
}
/**
 * 
 * @export
 * @interface PupilPupils
 */
export interface PupilPupils {
    /**
     * 
     * @type {number}
     * @memberof PupilPupils
     */
    'pupilId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PupilPupils
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PupilPupils
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof PupilPupils
     */
    'avatar': string;
    /**
     * 
     * @type {number}
     * @memberof PupilPupils
     */
    'zyklus': number;
    /**
     * 
     * @type {string}
     * @memberof PupilPupils
     */
    'household': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PupilPupils
     */
    'boardItems'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PupilSchedule
 */
export interface PupilSchedule {
    /**
     * 
     * @type {number}
     * @memberof PupilSchedule
     */
    'pupilId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PupilSchedule
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PupilSchedule
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof PupilSchedule
     */
    'avatar': string;
    /**
     * 
     * @type {number}
     * @memberof PupilSchedule
     */
    'zyklus': number;
}
/**
 * 
 * @export
 * @interface PupilSchedules
 */
export interface PupilSchedules {
    /**
     * 
     * @type {number}
     * @memberof PupilSchedules
     */
    'pupilId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PupilSchedules
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PupilSchedules
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof PupilSchedules
     */
    'avatar': string;
    /**
     * 
     * @type {number}
     * @memberof PupilSchedules
     */
    'zyklus': number;
}
/**
 * 
 * @export
 * @interface PupilUpload
 */
export interface PupilUpload {
    /**
     * 
     * @type {number}
     * @memberof PupilUpload
     */
    'pupilId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PupilUpload
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PupilUser
 */
export interface PupilUser {
    /**
     * 
     * @type {number}
     * @memberof PupilUser
     */
    'pupilId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PupilUser
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PupilUser
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof PupilUser
     */
    'avatar': string;
    /**
     * 
     * @type {number}
     * @memberof PupilUser
     */
    'zyklus': number;
}
/**
 * 
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'startsAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'endsAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'plan': string;
    /**
     * 
     * @type {Pupil}
     * @memberof Schedule
     */
    'pupil': Pupil;
    /**
     * 
     * @type {number}
     * @memberof Schedule
     */
    'scheduleId'?: number;
    /**
     * 
     * @type {Array<Upload>}
     * @memberof Schedule
     */
    'files'?: Array<Upload>;
}
/**
 * 
 * @export
 * @interface ScheduleSchedule
 */
export interface ScheduleSchedule {
    /**
     * 
     * @type {number}
     * @memberof ScheduleSchedule
     */
    'scheduleId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleSchedule
     */
    'startsAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleSchedule
     */
    'endsAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleSchedule
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleSchedule
     */
    'plan': string;
    /**
     * 
     * @type {PupilSchedule}
     * @memberof ScheduleSchedule
     */
    'pupil': PupilSchedule;
    /**
     * 
     * @type {Array<UploadSchedule>}
     * @memberof ScheduleSchedule
     */
    'files'?: Array<UploadSchedule>;
}
/**
 * 
 * @export
 * @interface ScheduleSchedules
 */
export interface ScheduleSchedules {
    /**
     * 
     * @type {number}
     * @memberof ScheduleSchedules
     */
    'scheduleId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleSchedules
     */
    'startsAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleSchedules
     */
    'endsAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleSchedules
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleSchedules
     */
    'plan': string;
    /**
     * 
     * @type {PupilSchedules}
     * @memberof ScheduleSchedules
     */
    'pupil': PupilSchedules;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduleSchedules
     */
    'files'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ScheduleUpload
 */
export interface ScheduleUpload {
    /**
     * 
     * @type {number}
     * @memberof ScheduleUpload
     */
    'scheduleId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleUpload
     */
    'label': string;
    /**
     * 
     * @type {PupilUpload}
     * @memberof ScheduleUpload
     */
    'pupil': PupilUpload;
}
/**
 * 
 * @export
 * @interface ThemenaspektGrundanspruch
 */
export interface ThemenaspektGrundanspruch {
    /**
     * 
     * @type {string}
     * @memberof ThemenaspektGrundanspruch
     */
    'themenaspektId'?: string;
    /**
     * 
     * @type {Lp21CodeGrundanspruch}
     * @memberof ThemenaspektGrundanspruch
     */
    'code': Lp21CodeGrundanspruch;
    /**
     * 
     * @type {string}
     * @memberof ThemenaspektGrundanspruch
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ThemenaspektGrundansprueche
 */
export interface ThemenaspektGrundansprueche {
    /**
     * 
     * @type {string}
     * @memberof ThemenaspektGrundansprueche
     */
    'themenaspektId'?: string;
    /**
     * 
     * @type {Lp21CodeGrundansprueche}
     * @memberof ThemenaspektGrundansprueche
     */
    'code': Lp21CodeGrundansprueche;
    /**
     * 
     * @type {string}
     * @memberof ThemenaspektGrundansprueche
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ThemenaspektThemenaspekt
 */
export interface ThemenaspektThemenaspekt {
    /**
     * 
     * @type {string}
     * @memberof ThemenaspektThemenaspekt
     */
    'themenaspektId'?: string;
    /**
     * 
     * @type {Lp21CodeThemenaspekt}
     * @memberof ThemenaspektThemenaspekt
     */
    'code': Lp21CodeThemenaspekt;
    /**
     * 
     * @type {string}
     * @memberof ThemenaspektThemenaspekt
     */
    'name': string;
    /**
     * 
     * @type {KompetenzbereichThemenaspekt}
     * @memberof ThemenaspektThemenaspekt
     */
    'kompetenzbereich': KompetenzbereichThemenaspekt;
    /**
     * 
     * @type {Array<string>}
     * @memberof ThemenaspektThemenaspekt
     */
    'kompetenzen'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ThemenaspektThemenaspekte
 */
export interface ThemenaspektThemenaspekte {
    /**
     * 
     * @type {string}
     * @memberof ThemenaspektThemenaspekte
     */
    'themenaspektId'?: string;
    /**
     * 
     * @type {Lp21CodeThemenaspekte}
     * @memberof ThemenaspektThemenaspekte
     */
    'code': Lp21CodeThemenaspekte;
    /**
     * 
     * @type {string}
     * @memberof ThemenaspektThemenaspekte
     */
    'name': string;
    /**
     * 
     * @type {KompetenzbereichThemenaspekte}
     * @memberof ThemenaspektThemenaspekte
     */
    'kompetenzbereich': KompetenzbereichThemenaspekte;
    /**
     * 
     * @type {Array<string>}
     * @memberof ThemenaspektThemenaspekte
     */
    'kompetenzen'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Topic
 */
export interface Topic {
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'abstract': string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'household': string;
    /**
     * 
     * @type {TopicCover}
     * @memberof Topic
     */
    'cover'?: TopicCover | null;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'topicId'?: string;
    /**
     * 
     * @type {Array<Upload>}
     * @memberof Topic
     */
    'files'?: Array<Upload>;
    /**
     * 
     * @type {Array<Grundanspruch>}
     * @memberof Topic
     */
    'grundanspruch'?: Array<Grundanspruch>;
    /**
     * 
     * @type {Array<Grundanspruch>}
     * @memberof Topic
     */
    'grundansprueche'?: Array<Grundanspruch>;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'addedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'updatedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface TopicCover
 */
export interface TopicCover {
    /**
     * 
     * @type {string}
     * @memberof TopicCover
     */
    'filePath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicCover
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicCover
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TopicCover
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TopicCover
     */
    'uploader': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TopicCover
     */
    'topics'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TopicCover
     */
    'topicCovers'?: Array<string>;
    /**
     * 
     * @type {Array<BoardItem>}
     * @memberof TopicCover
     */
    'boardItems'?: Array<BoardItem>;
    /**
     * 
     * @type {Array<Schedule>}
     * @memberof TopicCover
     */
    'schedules'?: Array<Schedule>;
    /**
     * 
     * @type {Array<JournalEntry>}
     * @memberof TopicCover
     */
    'journalEntries'?: Array<JournalEntry>;
    /**
     * 
     * @type {number}
     * @memberof TopicCover
     */
    'uploadId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TopicCover
     */
    'contentUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TopicCover
     */
    'image'?: boolean;
    /**
     * 
     * @type {UploadFile}
     * @memberof TopicCover
     */
    'file'?: UploadFile | null;
    /**
     * 
     * @type {string}
     * @memberof TopicCover
     */
    'uploadedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicCover
     */
    'currentUser'?: string;
}
/**
 * 
 * @export
 * @interface TopicGrundanspruch
 */
export interface TopicGrundanspruch {
    /**
     * 
     * @type {string}
     * @memberof TopicGrundanspruch
     */
    'topicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicGrundanspruch
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TopicGrundanspruch
     */
    'slug'?: string;
}
/**
 * 
 * @export
 * @interface TopicGrundansprueche
 */
export interface TopicGrundansprueche {
    /**
     * 
     * @type {string}
     * @memberof TopicGrundansprueche
     */
    'topicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicGrundansprueche
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TopicGrundansprueche
     */
    'slug'?: string;
}
/**
 * 
 * @export
 * @interface TopicTopic
 */
export interface TopicTopic {
    /**
     * 
     * @type {string}
     * @memberof TopicTopic
     */
    'topicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicTopic
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TopicTopic
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicTopic
     */
    'abstract': string;
    /**
     * 
     * @type {string}
     * @memberof TopicTopic
     */
    'description': string | null;
    /**
     * 
     * @type {TopicTopicCover}
     * @memberof TopicTopic
     */
    'cover'?: TopicTopicCover | null;
    /**
     * 
     * @type {Array<GrundanspruchTopic>}
     * @memberof TopicTopic
     */
    'grundansprueche'?: Array<GrundanspruchTopic>;
    /**
     * 
     * @type {Array<UploadTopic>}
     * @memberof TopicTopic
     */
    'files'?: Array<UploadTopic>;
    /**
     * 
     * @type {string}
     * @memberof TopicTopic
     */
    'addedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicTopic
     */
    'updatedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface TopicTopicCover
 */
export interface TopicTopicCover {
    /**
     * 
     * @type {number}
     * @memberof TopicTopicCover
     */
    'uploadId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TopicTopicCover
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicTopicCover
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TopicTopicCover
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TopicTopicCover
     */
    'contentUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TopicTopicCover
     */
    'image'?: boolean;
}
/**
 * 
 * @export
 * @interface TopicTopics
 */
export interface TopicTopics {
    /**
     * 
     * @type {string}
     * @memberof TopicTopics
     */
    'topicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicTopics
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TopicTopics
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicTopics
     */
    'abstract': string;
    /**
     * 
     * @type {string}
     * @memberof TopicTopics
     */
    'description': string | null;
    /**
     * 
     * @type {TopicTopicsCover}
     * @memberof TopicTopics
     */
    'cover'?: TopicTopicsCover | null;
    /**
     * 
     * @type {Array<GrundanspruchTopics>}
     * @memberof TopicTopics
     */
    'grundansprueche'?: Array<GrundanspruchTopics>;
    /**
     * 
     * @type {string}
     * @memberof TopicTopics
     */
    'addedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicTopics
     */
    'updatedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface TopicTopicsCover
 */
export interface TopicTopicsCover {
    /**
     * 
     * @type {number}
     * @memberof TopicTopicsCover
     */
    'uploadId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TopicTopicsCover
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicTopicsCover
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TopicTopicsCover
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TopicTopicsCover
     */
    'contentUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TopicTopicsCover
     */
    'image'?: boolean;
}
/**
 * 
 * @export
 * @interface Upload
 */
export interface Upload {
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    'filePath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Upload
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    'uploader': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Upload
     */
    'topics'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Upload
     */
    'topicCovers'?: Array<string>;
    /**
     * 
     * @type {Array<BoardItem>}
     * @memberof Upload
     */
    'boardItems'?: Array<BoardItem>;
    /**
     * 
     * @type {Array<Schedule>}
     * @memberof Upload
     */
    'schedules'?: Array<Schedule>;
    /**
     * 
     * @type {Array<JournalEntry>}
     * @memberof Upload
     */
    'journalEntries'?: Array<JournalEntry>;
    /**
     * 
     * @type {number}
     * @memberof Upload
     */
    'uploadId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    'contentUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Upload
     */
    'image'?: boolean;
    /**
     * 
     * @type {UploadFile}
     * @memberof Upload
     */
    'file'?: UploadFile | null;
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    'uploadedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    'currentUser'?: string;
}
/**
 * 
 * @export
 * @interface UploadBoardItem
 */
export interface UploadBoardItem {
    /**
     * 
     * @type {number}
     * @memberof UploadBoardItem
     */
    'uploadId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadBoardItem
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadBoardItem
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadBoardItem
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UploadBoardItem
     */
    'contentUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UploadBoardItem
     */
    'image'?: boolean;
}
/**
 * 
 * @export
 * @interface UploadFile
 */
export interface UploadFile {
    /**
     * Returns the mime type of the file.
     * @type {string}
     * @memberof UploadFile
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadFile
     */
    'path'?: string;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'filename'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'extension'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'basename'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'pathname'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'perms'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'inode'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'size'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'owner'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'group'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'ATime'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'MTime'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'CTime'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'type'?: any;
    /**
     * 
     * @type {boolean}
     * @memberof UploadFile
     */
    'writable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadFile
     */
    'readable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadFile
     */
    'executable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadFile
     */
    'file'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadFile
     */
    'dir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadFile
     */
    'link'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'linkTarget'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'realPath'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'fileInfo'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'pathInfo'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'fileClass'?: any;
    /**
     * 
     * @type {any}
     * @memberof UploadFile
     */
    'infoClass'?: any;
}
/**
 * 
 * @export
 * @interface UploadJournal
 */
export interface UploadJournal {
    /**
     * 
     * @type {number}
     * @memberof UploadJournal
     */
    'uploadId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadJournal
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadJournal
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadJournal
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UploadJournal
     */
    'contentUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UploadJournal
     */
    'image'?: boolean;
}
/**
 * 
 * @export
 * @interface UploadSchedule
 */
export interface UploadSchedule {
    /**
     * 
     * @type {number}
     * @memberof UploadSchedule
     */
    'uploadId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadSchedule
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadSchedule
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadSchedule
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UploadSchedule
     */
    'contentUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UploadSchedule
     */
    'image'?: boolean;
}
/**
 * 
 * @export
 * @interface UploadTopic
 */
export interface UploadTopic {
    /**
     * 
     * @type {number}
     * @memberof UploadTopic
     */
    'uploadId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadTopic
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadTopic
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadTopic
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UploadTopic
     */
    'contentUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UploadTopic
     */
    'image'?: boolean;
}
/**
 * 
 * @export
 * @interface UploadTopics
 */
export interface UploadTopics {
    /**
     * 
     * @type {number}
     * @memberof UploadTopics
     */
    'uploadId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadTopics
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadTopics
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadTopics
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UploadTopics
     */
    'contentUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UploadTopics
     */
    'image'?: boolean;
}
/**
 * 
 * @export
 * @interface UploadUpload
 */
export interface UploadUpload {
    /**
     * 
     * @type {number}
     * @memberof UploadUpload
     */
    'uploadId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadUpload
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadUpload
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadUpload
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UploadUpload
     */
    'uploadedAt'?: string;
    /**
     * 
     * @type {Array<BoardItemUpload>}
     * @memberof UploadUpload
     */
    'boardItems'?: Array<BoardItemUpload>;
    /**
     * 
     * @type {Array<ScheduleUpload>}
     * @memberof UploadUpload
     */
    'schedules'?: Array<ScheduleUpload>;
    /**
     * 
     * @type {Array<JournalEntryUpload>}
     * @memberof UploadUpload
     */
    'journalEntries'?: Array<JournalEntryUpload>;
    /**
     * 
     * @type {string}
     * @memberof UploadUpload
     */
    'contentUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UploadUpload
     */
    'image'?: boolean;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {Household}
     * @memberof User
     */
    'household': Household;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'boardItemNotes'?: Array<string>;
    /**
     * 
     * @type {Array<Upload>}
     * @memberof User
     */
    'uploads'?: Array<Upload>;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'userId'?: number;
}
/**
 * 
 * @export
 * @interface UserBoardItem
 */
export interface UserBoardItem {
    /**
     * 
     * @type {number}
     * @memberof UserBoardItem
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserBoardItem
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UserBoardItemNote
 */
export interface UserBoardItemNote {
    /**
     * 
     * @type {number}
     * @memberof UserBoardItemNote
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserBoardItemNote
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UserBoardList
 */
export interface UserBoardList {
    /**
     * 
     * @type {number}
     * @memberof UserBoardList
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserBoardList
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UserUser
 */
export interface UserUser {
    /**
     * 
     * @type {number}
     * @memberof UserUser
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUser
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserUser
     */
    'email': string;
    /**
     * 
     * @type {HouseholdUser}
     * @memberof UserUser
     */
    'household': HouseholdUser;
}
/**
 * 
 * @export
 * @interface UserUsers
 */
export interface UserUsers {
    /**
     * 
     * @type {number}
     * @memberof UserUsers
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUsers
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserUsers
     */
    'email': string;
}

/**
 * BoardItemApi - axios parameter creator
 * @export
 */
export const BoardItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Removes the BoardItem resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoardItemItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBoardItemItem', 'id', id)
            const localVarPath = `/board_items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the collection of BoardItem resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardItemCollection: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/board_items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a BoardItem resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardItemItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBoardItemItem', 'id', id)
            const localVarPath = `/board_items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the BoardItem resource.
         * @param {string} id 
         * @param {BoardItem} [boardItem] The updated BoardItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchBoardItemItem: async (id: string, boardItem?: BoardItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchBoardItemItem', 'id', id)
            const localVarPath = `/board_items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boardItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a BoardItem resource.
         * @param {BoardItem} [boardItem] The new BoardItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBoardItemCollection: async (boardItem?: BoardItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/board_items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boardItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoardItemApi - functional programming interface
 * @export
 */
export const BoardItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoardItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Removes the BoardItem resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBoardItemItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBoardItemItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the collection of BoardItem resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoardItemCollection(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoardItemBoardItems>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoardItemCollection(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a BoardItem resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoardItemItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardItemBoardItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoardItemItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the BoardItem resource.
         * @param {string} id 
         * @param {BoardItem} [boardItem] The updated BoardItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchBoardItemItem(id: string, boardItem?: BoardItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardItemBoardItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchBoardItemItem(id, boardItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a BoardItem resource.
         * @param {BoardItem} [boardItem] The new BoardItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBoardItemCollection(boardItem?: BoardItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardItemBoardItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBoardItemCollection(boardItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BoardItemApi - factory interface
 * @export
 */
export const BoardItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoardItemApiFp(configuration)
    return {
        /**
         * 
         * @summary Removes the BoardItem resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoardItemItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBoardItemItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the collection of BoardItem resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardItemCollection(page?: number, options?: any): AxiosPromise<Array<BoardItemBoardItems>> {
            return localVarFp.getBoardItemCollection(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a BoardItem resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardItemItem(id: string, options?: any): AxiosPromise<BoardItemBoardItem> {
            return localVarFp.getBoardItemItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the BoardItem resource.
         * @param {string} id 
         * @param {BoardItem} [boardItem] The updated BoardItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchBoardItemItem(id: string, boardItem?: BoardItem, options?: any): AxiosPromise<BoardItemBoardItem> {
            return localVarFp.patchBoardItemItem(id, boardItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a BoardItem resource.
         * @param {BoardItem} [boardItem] The new BoardItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBoardItemCollection(boardItem?: BoardItem, options?: any): AxiosPromise<BoardItemBoardItem> {
            return localVarFp.postBoardItemCollection(boardItem, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BoardItemApi - object-oriented interface
 * @export
 * @class BoardItemApi
 * @extends {BaseAPI}
 */
export class BoardItemApi extends BaseAPI {
    /**
     * 
     * @summary Removes the BoardItem resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardItemApi
     */
    public deleteBoardItemItem(id: string, options?: AxiosRequestConfig) {
        return BoardItemApiFp(this.configuration).deleteBoardItemItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the collection of BoardItem resources.
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardItemApi
     */
    public getBoardItemCollection(page?: number, options?: AxiosRequestConfig) {
        return BoardItemApiFp(this.configuration).getBoardItemCollection(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a BoardItem resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardItemApi
     */
    public getBoardItemItem(id: string, options?: AxiosRequestConfig) {
        return BoardItemApiFp(this.configuration).getBoardItemItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the BoardItem resource.
     * @param {string} id 
     * @param {BoardItem} [boardItem] The updated BoardItem resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardItemApi
     */
    public patchBoardItemItem(id: string, boardItem?: BoardItem, options?: AxiosRequestConfig) {
        return BoardItemApiFp(this.configuration).patchBoardItemItem(id, boardItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a BoardItem resource.
     * @param {BoardItem} [boardItem] The new BoardItem resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardItemApi
     */
    public postBoardItemCollection(boardItem?: BoardItem, options?: AxiosRequestConfig) {
        return BoardItemApiFp(this.configuration).postBoardItemCollection(boardItem, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BoardItemNoteApi - axios parameter creator
 * @export
 */
export const BoardItemNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Removes the BoardItemNote resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoardItemNoteItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBoardItemNoteItem', 'id', id)
            const localVarPath = `/board_item_notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the collection of BoardItemNote resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardItemNoteCollection: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/board_item_notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a BoardItemNote resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardItemNoteItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBoardItemNoteItem', 'id', id)
            const localVarPath = `/board_item_notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the BoardItemNote resource.
         * @param {string} id 
         * @param {BoardItemNote} [boardItemNote] The updated BoardItemNote resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchBoardItemNoteItem: async (id: string, boardItemNote?: BoardItemNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchBoardItemNoteItem', 'id', id)
            const localVarPath = `/board_item_notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boardItemNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a BoardItemNote resource.
         * @param {BoardItemNote} [boardItemNote] The new BoardItemNote resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBoardItemNoteCollection: async (boardItemNote?: BoardItemNote, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/board_item_notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boardItemNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoardItemNoteApi - functional programming interface
 * @export
 */
export const BoardItemNoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoardItemNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Removes the BoardItemNote resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBoardItemNoteItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBoardItemNoteItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the collection of BoardItemNote resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoardItemNoteCollection(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoardItemNoteBoardItemNotes>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoardItemNoteCollection(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a BoardItemNote resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoardItemNoteItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardItemNoteBoardItemNote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoardItemNoteItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the BoardItemNote resource.
         * @param {string} id 
         * @param {BoardItemNote} [boardItemNote] The updated BoardItemNote resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchBoardItemNoteItem(id: string, boardItemNote?: BoardItemNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardItemNoteBoardItemNote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchBoardItemNoteItem(id, boardItemNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a BoardItemNote resource.
         * @param {BoardItemNote} [boardItemNote] The new BoardItemNote resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBoardItemNoteCollection(boardItemNote?: BoardItemNote, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardItemNoteBoardItemNote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBoardItemNoteCollection(boardItemNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BoardItemNoteApi - factory interface
 * @export
 */
export const BoardItemNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoardItemNoteApiFp(configuration)
    return {
        /**
         * 
         * @summary Removes the BoardItemNote resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoardItemNoteItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBoardItemNoteItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the collection of BoardItemNote resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardItemNoteCollection(page?: number, options?: any): AxiosPromise<Array<BoardItemNoteBoardItemNotes>> {
            return localVarFp.getBoardItemNoteCollection(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a BoardItemNote resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardItemNoteItem(id: string, options?: any): AxiosPromise<BoardItemNoteBoardItemNote> {
            return localVarFp.getBoardItemNoteItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the BoardItemNote resource.
         * @param {string} id 
         * @param {BoardItemNote} [boardItemNote] The updated BoardItemNote resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchBoardItemNoteItem(id: string, boardItemNote?: BoardItemNote, options?: any): AxiosPromise<BoardItemNoteBoardItemNote> {
            return localVarFp.patchBoardItemNoteItem(id, boardItemNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a BoardItemNote resource.
         * @param {BoardItemNote} [boardItemNote] The new BoardItemNote resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBoardItemNoteCollection(boardItemNote?: BoardItemNote, options?: any): AxiosPromise<BoardItemNoteBoardItemNote> {
            return localVarFp.postBoardItemNoteCollection(boardItemNote, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BoardItemNoteApi - object-oriented interface
 * @export
 * @class BoardItemNoteApi
 * @extends {BaseAPI}
 */
export class BoardItemNoteApi extends BaseAPI {
    /**
     * 
     * @summary Removes the BoardItemNote resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardItemNoteApi
     */
    public deleteBoardItemNoteItem(id: string, options?: AxiosRequestConfig) {
        return BoardItemNoteApiFp(this.configuration).deleteBoardItemNoteItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the collection of BoardItemNote resources.
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardItemNoteApi
     */
    public getBoardItemNoteCollection(page?: number, options?: AxiosRequestConfig) {
        return BoardItemNoteApiFp(this.configuration).getBoardItemNoteCollection(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a BoardItemNote resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardItemNoteApi
     */
    public getBoardItemNoteItem(id: string, options?: AxiosRequestConfig) {
        return BoardItemNoteApiFp(this.configuration).getBoardItemNoteItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the BoardItemNote resource.
     * @param {string} id 
     * @param {BoardItemNote} [boardItemNote] The updated BoardItemNote resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardItemNoteApi
     */
    public patchBoardItemNoteItem(id: string, boardItemNote?: BoardItemNote, options?: AxiosRequestConfig) {
        return BoardItemNoteApiFp(this.configuration).patchBoardItemNoteItem(id, boardItemNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a BoardItemNote resource.
     * @param {BoardItemNote} [boardItemNote] The new BoardItemNote resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardItemNoteApi
     */
    public postBoardItemNoteCollection(boardItemNote?: BoardItemNote, options?: AxiosRequestConfig) {
        return BoardItemNoteApiFp(this.configuration).postBoardItemNoteCollection(boardItemNote, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BoardListApi - axios parameter creator
 * @export
 */
export const BoardListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Reorder items in a list
         * @param {string} id 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardListReorderList: async (id: string, inlineObject1?: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('boardListReorderList', 'id', id)
            const localVarPath = `/board_lists/{id}/reorder`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the BoardList resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoardListItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBoardListItem', 'id', id)
            const localVarPath = `/board_lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the collection of BoardList resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardListCollection: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/board_lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a BoardList resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardListItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBoardListItem', 'id', id)
            const localVarPath = `/board_lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the BoardList resource.
         * @param {string} id 
         * @param {BoardList} [boardList] The updated BoardList resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchBoardListItem: async (id: string, boardList?: BoardList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchBoardListItem', 'id', id)
            const localVarPath = `/board_lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boardList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a BoardList resource.
         * @param {BoardList} [boardList] The new BoardList resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBoardListCollection: async (boardList?: BoardList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/board_lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boardList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoardListApi - functional programming interface
 * @export
 */
export const BoardListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoardListApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Reorder items in a list
         * @param {string} id 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardListReorderList(id: string, inlineObject1?: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardListReorderList(id, inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes the BoardList resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBoardListItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBoardListItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the collection of BoardList resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoardListCollection(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoardListBoardLists>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoardListCollection(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a BoardList resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoardListItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardListBoardList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoardListItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the BoardList resource.
         * @param {string} id 
         * @param {BoardList} [boardList] The updated BoardList resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchBoardListItem(id: string, boardList?: BoardList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardListBoardList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchBoardListItem(id, boardList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a BoardList resource.
         * @param {BoardList} [boardList] The new BoardList resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBoardListCollection(boardList?: BoardList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardListBoardList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBoardListCollection(boardList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BoardListApi - factory interface
 * @export
 */
export const BoardListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoardListApiFp(configuration)
    return {
        /**
         * 
         * @summary Reorder items in a list
         * @param {string} id 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardListReorderList(id: string, inlineObject1?: InlineObject1, options?: any): AxiosPromise<void> {
            return localVarFp.boardListReorderList(id, inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the BoardList resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoardListItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBoardListItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the collection of BoardList resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardListCollection(page?: number, options?: any): AxiosPromise<Array<BoardListBoardLists>> {
            return localVarFp.getBoardListCollection(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a BoardList resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardListItem(id: string, options?: any): AxiosPromise<BoardListBoardList> {
            return localVarFp.getBoardListItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the BoardList resource.
         * @param {string} id 
         * @param {BoardList} [boardList] The updated BoardList resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchBoardListItem(id: string, boardList?: BoardList, options?: any): AxiosPromise<BoardListBoardList> {
            return localVarFp.patchBoardListItem(id, boardList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a BoardList resource.
         * @param {BoardList} [boardList] The new BoardList resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBoardListCollection(boardList?: BoardList, options?: any): AxiosPromise<BoardListBoardList> {
            return localVarFp.postBoardListCollection(boardList, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BoardListApi - object-oriented interface
 * @export
 * @class BoardListApi
 * @extends {BaseAPI}
 */
export class BoardListApi extends BaseAPI {
    /**
     * 
     * @summary Reorder items in a list
     * @param {string} id 
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardListApi
     */
    public boardListReorderList(id: string, inlineObject1?: InlineObject1, options?: AxiosRequestConfig) {
        return BoardListApiFp(this.configuration).boardListReorderList(id, inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the BoardList resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardListApi
     */
    public deleteBoardListItem(id: string, options?: AxiosRequestConfig) {
        return BoardListApiFp(this.configuration).deleteBoardListItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the collection of BoardList resources.
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardListApi
     */
    public getBoardListCollection(page?: number, options?: AxiosRequestConfig) {
        return BoardListApiFp(this.configuration).getBoardListCollection(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a BoardList resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardListApi
     */
    public getBoardListItem(id: string, options?: AxiosRequestConfig) {
        return BoardListApiFp(this.configuration).getBoardListItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the BoardList resource.
     * @param {string} id 
     * @param {BoardList} [boardList] The updated BoardList resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardListApi
     */
    public patchBoardListItem(id: string, boardList?: BoardList, options?: AxiosRequestConfig) {
        return BoardListApiFp(this.configuration).patchBoardListItem(id, boardList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a BoardList resource.
     * @param {BoardList} [boardList] The new BoardList resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardListApi
     */
    public postBoardListCollection(boardList?: BoardList, options?: AxiosRequestConfig) {
        return BoardListApiFp(this.configuration).postBoardListCollection(boardList, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FachbereichApi - axios parameter creator
 * @export
 */
export const FachbereichApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of Fachbereich resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFachbereichCollection: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fachbereiche`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Fachbereich resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFachbereichItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFachbereichItem', 'id', id)
            const localVarPath = `/fachbereiche/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FachbereichApi - functional programming interface
 * @export
 */
export const FachbereichApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FachbereichApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Fachbereich resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFachbereichCollection(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FachbereichFachbereiche>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFachbereichCollection(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Fachbereich resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFachbereichItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FachbereichFachbereich>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFachbereichItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FachbereichApi - factory interface
 * @export
 */
export const FachbereichApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FachbereichApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Fachbereich resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFachbereichCollection(page?: number, options?: any): AxiosPromise<Array<FachbereichFachbereiche>> {
            return localVarFp.getFachbereichCollection(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Fachbereich resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFachbereichItem(id: string, options?: any): AxiosPromise<FachbereichFachbereich> {
            return localVarFp.getFachbereichItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FachbereichApi - object-oriented interface
 * @export
 * @class FachbereichApi
 * @extends {BaseAPI}
 */
export class FachbereichApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of Fachbereich resources.
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FachbereichApi
     */
    public getFachbereichCollection(page?: number, options?: AxiosRequestConfig) {
        return FachbereichApiFp(this.configuration).getFachbereichCollection(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Fachbereich resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FachbereichApi
     */
    public getFachbereichItem(id: string, options?: AxiosRequestConfig) {
        return FachbereichApiFp(this.configuration).getFachbereichItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GrundanspruchApi - axios parameter creator
 * @export
 */
export const GrundanspruchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of Grundanspruch resources.
         * @param {string} [kompetenzThemenaspekt] 
         * @param {Array<string>} [kompetenzThemenaspekt2] 
         * @param {string} [kompetenzThemenaspektKompetenzbereich] 
         * @param {Array<string>} [kompetenzThemenaspektKompetenzbereich2] 
         * @param {string} [kompetenzThemenaspektKompetenzbereichFachbereich] 
         * @param {Array<string>} [kompetenzThemenaspektKompetenzbereichFachbereich2] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrundanspruchCollection: async (kompetenzThemenaspekt?: string, kompetenzThemenaspekt2?: Array<string>, kompetenzThemenaspektKompetenzbereich?: string, kompetenzThemenaspektKompetenzbereich2?: Array<string>, kompetenzThemenaspektKompetenzbereichFachbereich?: string, kompetenzThemenaspektKompetenzbereichFachbereich2?: Array<string>, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/grundansprueche`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (kompetenzThemenaspekt !== undefined) {
                localVarQueryParameter['kompetenz.themenaspekt'] = kompetenzThemenaspekt;
            }

            if (kompetenzThemenaspekt2) {
                localVarQueryParameter['kompetenz.themenaspekt[]'] = kompetenzThemenaspekt2;
            }

            if (kompetenzThemenaspektKompetenzbereich !== undefined) {
                localVarQueryParameter['kompetenz.themenaspekt.kompetenzbereich'] = kompetenzThemenaspektKompetenzbereich;
            }

            if (kompetenzThemenaspektKompetenzbereich2) {
                localVarQueryParameter['kompetenz.themenaspekt.kompetenzbereich[]'] = kompetenzThemenaspektKompetenzbereich2;
            }

            if (kompetenzThemenaspektKompetenzbereichFachbereich !== undefined) {
                localVarQueryParameter['kompetenz.themenaspekt.kompetenzbereich.fachbereich'] = kompetenzThemenaspektKompetenzbereichFachbereich;
            }

            if (kompetenzThemenaspektKompetenzbereichFachbereich2) {
                localVarQueryParameter['kompetenz.themenaspekt.kompetenzbereich.fachbereich[]'] = kompetenzThemenaspektKompetenzbereichFachbereich2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Grundanspruch resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrundanspruchItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGrundanspruchItem', 'id', id)
            const localVarPath = `/grundansprueche/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GrundanspruchApi - functional programming interface
 * @export
 */
export const GrundanspruchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GrundanspruchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Grundanspruch resources.
         * @param {string} [kompetenzThemenaspekt] 
         * @param {Array<string>} [kompetenzThemenaspekt2] 
         * @param {string} [kompetenzThemenaspektKompetenzbereich] 
         * @param {Array<string>} [kompetenzThemenaspektKompetenzbereich2] 
         * @param {string} [kompetenzThemenaspektKompetenzbereichFachbereich] 
         * @param {Array<string>} [kompetenzThemenaspektKompetenzbereichFachbereich2] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGrundanspruchCollection(kompetenzThemenaspekt?: string, kompetenzThemenaspekt2?: Array<string>, kompetenzThemenaspektKompetenzbereich?: string, kompetenzThemenaspektKompetenzbereich2?: Array<string>, kompetenzThemenaspektKompetenzbereichFachbereich?: string, kompetenzThemenaspektKompetenzbereichFachbereich2?: Array<string>, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GrundanspruchGrundansprueche>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGrundanspruchCollection(kompetenzThemenaspekt, kompetenzThemenaspekt2, kompetenzThemenaspektKompetenzbereich, kompetenzThemenaspektKompetenzbereich2, kompetenzThemenaspektKompetenzbereichFachbereich, kompetenzThemenaspektKompetenzbereichFachbereich2, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Grundanspruch resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGrundanspruchItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GrundanspruchGrundanspruch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGrundanspruchItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GrundanspruchApi - factory interface
 * @export
 */
export const GrundanspruchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GrundanspruchApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Grundanspruch resources.
         * @param {string} [kompetenzThemenaspekt] 
         * @param {Array<string>} [kompetenzThemenaspekt2] 
         * @param {string} [kompetenzThemenaspektKompetenzbereich] 
         * @param {Array<string>} [kompetenzThemenaspektKompetenzbereich2] 
         * @param {string} [kompetenzThemenaspektKompetenzbereichFachbereich] 
         * @param {Array<string>} [kompetenzThemenaspektKompetenzbereichFachbereich2] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrundanspruchCollection(kompetenzThemenaspekt?: string, kompetenzThemenaspekt2?: Array<string>, kompetenzThemenaspektKompetenzbereich?: string, kompetenzThemenaspektKompetenzbereich2?: Array<string>, kompetenzThemenaspektKompetenzbereichFachbereich?: string, kompetenzThemenaspektKompetenzbereichFachbereich2?: Array<string>, page?: number, options?: any): AxiosPromise<Array<GrundanspruchGrundansprueche>> {
            return localVarFp.getGrundanspruchCollection(kompetenzThemenaspekt, kompetenzThemenaspekt2, kompetenzThemenaspektKompetenzbereich, kompetenzThemenaspektKompetenzbereich2, kompetenzThemenaspektKompetenzbereichFachbereich, kompetenzThemenaspektKompetenzbereichFachbereich2, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Grundanspruch resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrundanspruchItem(id: string, options?: any): AxiosPromise<GrundanspruchGrundanspruch> {
            return localVarFp.getGrundanspruchItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GrundanspruchApi - object-oriented interface
 * @export
 * @class GrundanspruchApi
 * @extends {BaseAPI}
 */
export class GrundanspruchApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of Grundanspruch resources.
     * @param {string} [kompetenzThemenaspekt] 
     * @param {Array<string>} [kompetenzThemenaspekt2] 
     * @param {string} [kompetenzThemenaspektKompetenzbereich] 
     * @param {Array<string>} [kompetenzThemenaspektKompetenzbereich2] 
     * @param {string} [kompetenzThemenaspektKompetenzbereichFachbereich] 
     * @param {Array<string>} [kompetenzThemenaspektKompetenzbereichFachbereich2] 
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GrundanspruchApi
     */
    public getGrundanspruchCollection(kompetenzThemenaspekt?: string, kompetenzThemenaspekt2?: Array<string>, kompetenzThemenaspektKompetenzbereich?: string, kompetenzThemenaspektKompetenzbereich2?: Array<string>, kompetenzThemenaspektKompetenzbereichFachbereich?: string, kompetenzThemenaspektKompetenzbereichFachbereich2?: Array<string>, page?: number, options?: AxiosRequestConfig) {
        return GrundanspruchApiFp(this.configuration).getGrundanspruchCollection(kompetenzThemenaspekt, kompetenzThemenaspekt2, kompetenzThemenaspektKompetenzbereich, kompetenzThemenaspektKompetenzbereich2, kompetenzThemenaspektKompetenzbereichFachbereich, kompetenzThemenaspektKompetenzbereichFachbereich2, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Grundanspruch resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GrundanspruchApi
     */
    public getGrundanspruchItem(id: string, options?: AxiosRequestConfig) {
        return GrundanspruchApiFp(this.configuration).getGrundanspruchItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HouseholdApi - axios parameter creator
 * @export
 */
export const HouseholdApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of Topic resources.
         * @param {string} id 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHouseholdsTopicsGetSubresource: async (id: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiHouseholdsTopicsGetSubresource', 'id', id)
            const localVarPath = `/households/{id}/topics`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the Household resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHouseholdItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteHouseholdItem', 'id', id)
            const localVarPath = `/households/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the collection of Household resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHouseholdCollection: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/households`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Household resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHouseholdItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getHouseholdItem', 'id', id)
            const localVarPath = `/households/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the Household resource.
         * @param {string} id 
         * @param {Household} [household] The updated Household resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchHouseholdItem: async (id: string, household?: Household, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchHouseholdItem', 'id', id)
            const localVarPath = `/households/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(household, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a Household resource.
         * @param {Household} [household] The new Household resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postHouseholdCollection: async (household?: Household, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/households`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(household, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HouseholdApi - functional programming interface
 * @export
 */
export const HouseholdApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HouseholdApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Topic resources.
         * @param {string} id 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHouseholdsTopicsGetSubresource(id: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopicTopic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHouseholdsTopicsGetSubresource(id, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes the Household resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHouseholdItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHouseholdItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the collection of Household resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHouseholdCollection(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HouseholdHouseholds>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHouseholdCollection(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Household resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHouseholdItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HouseholdHousehold>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHouseholdItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the Household resource.
         * @param {string} id 
         * @param {Household} [household] The updated Household resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchHouseholdItem(id: string, household?: Household, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HouseholdHousehold>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchHouseholdItem(id, household, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a Household resource.
         * @param {Household} [household] The new Household resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postHouseholdCollection(household?: Household, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HouseholdHousehold>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postHouseholdCollection(household, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HouseholdApi - factory interface
 * @export
 */
export const HouseholdApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HouseholdApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Topic resources.
         * @param {string} id 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHouseholdsTopicsGetSubresource(id: string, page?: number, options?: any): AxiosPromise<Array<TopicTopic>> {
            return localVarFp.apiHouseholdsTopicsGetSubresource(id, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the Household resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHouseholdItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteHouseholdItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the collection of Household resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHouseholdCollection(page?: number, options?: any): AxiosPromise<Array<HouseholdHouseholds>> {
            return localVarFp.getHouseholdCollection(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Household resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHouseholdItem(id: string, options?: any): AxiosPromise<HouseholdHousehold> {
            return localVarFp.getHouseholdItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the Household resource.
         * @param {string} id 
         * @param {Household} [household] The updated Household resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchHouseholdItem(id: string, household?: Household, options?: any): AxiosPromise<HouseholdHousehold> {
            return localVarFp.patchHouseholdItem(id, household, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a Household resource.
         * @param {Household} [household] The new Household resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postHouseholdCollection(household?: Household, options?: any): AxiosPromise<HouseholdHousehold> {
            return localVarFp.postHouseholdCollection(household, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HouseholdApi - object-oriented interface
 * @export
 * @class HouseholdApi
 * @extends {BaseAPI}
 */
export class HouseholdApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of Topic resources.
     * @param {string} id 
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HouseholdApi
     */
    public apiHouseholdsTopicsGetSubresource(id: string, page?: number, options?: AxiosRequestConfig) {
        return HouseholdApiFp(this.configuration).apiHouseholdsTopicsGetSubresource(id, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the Household resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HouseholdApi
     */
    public deleteHouseholdItem(id: string, options?: AxiosRequestConfig) {
        return HouseholdApiFp(this.configuration).deleteHouseholdItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the collection of Household resources.
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HouseholdApi
     */
    public getHouseholdCollection(page?: number, options?: AxiosRequestConfig) {
        return HouseholdApiFp(this.configuration).getHouseholdCollection(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Household resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HouseholdApi
     */
    public getHouseholdItem(id: string, options?: AxiosRequestConfig) {
        return HouseholdApiFp(this.configuration).getHouseholdItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the Household resource.
     * @param {string} id 
     * @param {Household} [household] The updated Household resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HouseholdApi
     */
    public patchHouseholdItem(id: string, household?: Household, options?: AxiosRequestConfig) {
        return HouseholdApiFp(this.configuration).patchHouseholdItem(id, household, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a Household resource.
     * @param {Household} [household] The new Household resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HouseholdApi
     */
    public postHouseholdCollection(household?: Household, options?: AxiosRequestConfig) {
        return HouseholdApiFp(this.configuration).postHouseholdCollection(household, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JournalEntryApi - axios parameter creator
 * @export
 */
export const JournalEntryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Removes the JournalEntry resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalEntryItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteJournalEntryItem', 'id', id)
            const localVarPath = `/journal_entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the collection of JournalEntry resources.
         * @param {string} [pupil] 
         * @param {Array<string>} [pupil2] 
         * @param {string} [dateBefore] 
         * @param {string} [dateStrictlyBefore] 
         * @param {string} [dateAfter] 
         * @param {string} [dateStrictlyAfter] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalEntryCollection: async (pupil?: string, pupil2?: Array<string>, dateBefore?: string, dateStrictlyBefore?: string, dateAfter?: string, dateStrictlyAfter?: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/journal_entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pupil !== undefined) {
                localVarQueryParameter['pupil'] = pupil;
            }

            if (pupil2) {
                localVarQueryParameter['pupil[]'] = pupil2;
            }

            if (dateBefore !== undefined) {
                localVarQueryParameter['date[before]'] = dateBefore;
            }

            if (dateStrictlyBefore !== undefined) {
                localVarQueryParameter['date[strictly_before]'] = dateStrictlyBefore;
            }

            if (dateAfter !== undefined) {
                localVarQueryParameter['date[after]'] = dateAfter;
            }

            if (dateStrictlyAfter !== undefined) {
                localVarQueryParameter['date[strictly_after]'] = dateStrictlyAfter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a JournalEntry resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalEntryItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getJournalEntryItem', 'id', id)
            const localVarPath = `/journal_entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the JournalEntry resource.
         * @param {string} id 
         * @param {JournalEntry} [journalEntry] The updated JournalEntry resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchJournalEntryItem: async (id: string, journalEntry?: JournalEntry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchJournalEntryItem', 'id', id)
            const localVarPath = `/journal_entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(journalEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a JournalEntry resource.
         * @param {JournalEntry} [journalEntry] The new JournalEntry resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJournalEntryCollection: async (journalEntry?: JournalEntry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/journal_entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(journalEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JournalEntryApi - functional programming interface
 * @export
 */
export const JournalEntryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JournalEntryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Removes the JournalEntry resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJournalEntryItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJournalEntryItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the collection of JournalEntry resources.
         * @param {string} [pupil] 
         * @param {Array<string>} [pupil2] 
         * @param {string} [dateBefore] 
         * @param {string} [dateStrictlyBefore] 
         * @param {string} [dateAfter] 
         * @param {string} [dateStrictlyAfter] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournalEntryCollection(pupil?: string, pupil2?: Array<string>, dateBefore?: string, dateStrictlyBefore?: string, dateAfter?: string, dateStrictlyAfter?: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JournalEntryJournal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalEntryCollection(pupil, pupil2, dateBefore, dateStrictlyBefore, dateAfter, dateStrictlyAfter, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a JournalEntry resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournalEntryItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalEntryJournalEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalEntryItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the JournalEntry resource.
         * @param {string} id 
         * @param {JournalEntry} [journalEntry] The updated JournalEntry resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchJournalEntryItem(id: string, journalEntry?: JournalEntry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalEntryJournalEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchJournalEntryItem(id, journalEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a JournalEntry resource.
         * @param {JournalEntry} [journalEntry] The new JournalEntry resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJournalEntryCollection(journalEntry?: JournalEntry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalEntryJournalEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJournalEntryCollection(journalEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JournalEntryApi - factory interface
 * @export
 */
export const JournalEntryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JournalEntryApiFp(configuration)
    return {
        /**
         * 
         * @summary Removes the JournalEntry resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalEntryItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteJournalEntryItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the collection of JournalEntry resources.
         * @param {string} [pupil] 
         * @param {Array<string>} [pupil2] 
         * @param {string} [dateBefore] 
         * @param {string} [dateStrictlyBefore] 
         * @param {string} [dateAfter] 
         * @param {string} [dateStrictlyAfter] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalEntryCollection(pupil?: string, pupil2?: Array<string>, dateBefore?: string, dateStrictlyBefore?: string, dateAfter?: string, dateStrictlyAfter?: string, page?: number, options?: any): AxiosPromise<Array<JournalEntryJournal>> {
            return localVarFp.getJournalEntryCollection(pupil, pupil2, dateBefore, dateStrictlyBefore, dateAfter, dateStrictlyAfter, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a JournalEntry resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalEntryItem(id: string, options?: any): AxiosPromise<JournalEntryJournalEntry> {
            return localVarFp.getJournalEntryItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the JournalEntry resource.
         * @param {string} id 
         * @param {JournalEntry} [journalEntry] The updated JournalEntry resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchJournalEntryItem(id: string, journalEntry?: JournalEntry, options?: any): AxiosPromise<JournalEntryJournalEntry> {
            return localVarFp.patchJournalEntryItem(id, journalEntry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a JournalEntry resource.
         * @param {JournalEntry} [journalEntry] The new JournalEntry resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJournalEntryCollection(journalEntry?: JournalEntry, options?: any): AxiosPromise<JournalEntryJournalEntry> {
            return localVarFp.postJournalEntryCollection(journalEntry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JournalEntryApi - object-oriented interface
 * @export
 * @class JournalEntryApi
 * @extends {BaseAPI}
 */
export class JournalEntryApi extends BaseAPI {
    /**
     * 
     * @summary Removes the JournalEntry resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JournalEntryApi
     */
    public deleteJournalEntryItem(id: string, options?: AxiosRequestConfig) {
        return JournalEntryApiFp(this.configuration).deleteJournalEntryItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the collection of JournalEntry resources.
     * @param {string} [pupil] 
     * @param {Array<string>} [pupil2] 
     * @param {string} [dateBefore] 
     * @param {string} [dateStrictlyBefore] 
     * @param {string} [dateAfter] 
     * @param {string} [dateStrictlyAfter] 
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JournalEntryApi
     */
    public getJournalEntryCollection(pupil?: string, pupil2?: Array<string>, dateBefore?: string, dateStrictlyBefore?: string, dateAfter?: string, dateStrictlyAfter?: string, page?: number, options?: AxiosRequestConfig) {
        return JournalEntryApiFp(this.configuration).getJournalEntryCollection(pupil, pupil2, dateBefore, dateStrictlyBefore, dateAfter, dateStrictlyAfter, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a JournalEntry resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JournalEntryApi
     */
    public getJournalEntryItem(id: string, options?: AxiosRequestConfig) {
        return JournalEntryApiFp(this.configuration).getJournalEntryItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the JournalEntry resource.
     * @param {string} id 
     * @param {JournalEntry} [journalEntry] The updated JournalEntry resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JournalEntryApi
     */
    public patchJournalEntryItem(id: string, journalEntry?: JournalEntry, options?: AxiosRequestConfig) {
        return JournalEntryApiFp(this.configuration).patchJournalEntryItem(id, journalEntry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a JournalEntry resource.
     * @param {JournalEntry} [journalEntry] The new JournalEntry resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JournalEntryApi
     */
    public postJournalEntryCollection(journalEntry?: JournalEntry, options?: AxiosRequestConfig) {
        return JournalEntryApiFp(this.configuration).postJournalEntryCollection(journalEntry, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KompetenzApi - axios parameter creator
 * @export
 */
export const KompetenzApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of Kompetenz resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKompetenzCollection: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/kompetenzen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Kompetenz resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKompetenzItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getKompetenzItem', 'id', id)
            const localVarPath = `/kompetenzen/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KompetenzApi - functional programming interface
 * @export
 */
export const KompetenzApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KompetenzApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Kompetenz resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKompetenzCollection(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KompetenzKompetenzen>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKompetenzCollection(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Kompetenz resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKompetenzItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KompetenzKompetenz>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKompetenzItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KompetenzApi - factory interface
 * @export
 */
export const KompetenzApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KompetenzApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Kompetenz resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKompetenzCollection(page?: number, options?: any): AxiosPromise<Array<KompetenzKompetenzen>> {
            return localVarFp.getKompetenzCollection(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Kompetenz resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKompetenzItem(id: string, options?: any): AxiosPromise<KompetenzKompetenz> {
            return localVarFp.getKompetenzItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KompetenzApi - object-oriented interface
 * @export
 * @class KompetenzApi
 * @extends {BaseAPI}
 */
export class KompetenzApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of Kompetenz resources.
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KompetenzApi
     */
    public getKompetenzCollection(page?: number, options?: AxiosRequestConfig) {
        return KompetenzApiFp(this.configuration).getKompetenzCollection(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Kompetenz resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KompetenzApi
     */
    public getKompetenzItem(id: string, options?: AxiosRequestConfig) {
        return KompetenzApiFp(this.configuration).getKompetenzItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KompetenzbereichApi - axios parameter creator
 * @export
 */
export const KompetenzbereichApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of Kompetenzbereich resources.
         * @param {string} [fachbereich] 
         * @param {Array<string>} [fachbereich2] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKompetenzbereichCollection: async (fachbereich?: string, fachbereich2?: Array<string>, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/kompetenzbereiche`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fachbereich !== undefined) {
                localVarQueryParameter['fachbereich'] = fachbereich;
            }

            if (fachbereich2) {
                localVarQueryParameter['fachbereich[]'] = fachbereich2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Kompetenzbereich resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKompetenzbereichItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getKompetenzbereichItem', 'id', id)
            const localVarPath = `/kompetenzbereiche/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KompetenzbereichApi - functional programming interface
 * @export
 */
export const KompetenzbereichApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KompetenzbereichApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Kompetenzbereich resources.
         * @param {string} [fachbereich] 
         * @param {Array<string>} [fachbereich2] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKompetenzbereichCollection(fachbereich?: string, fachbereich2?: Array<string>, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KompetenzbereichKompetenzbereiche>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKompetenzbereichCollection(fachbereich, fachbereich2, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Kompetenzbereich resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKompetenzbereichItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KompetenzbereichKompetenzbereich>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKompetenzbereichItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KompetenzbereichApi - factory interface
 * @export
 */
export const KompetenzbereichApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KompetenzbereichApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Kompetenzbereich resources.
         * @param {string} [fachbereich] 
         * @param {Array<string>} [fachbereich2] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKompetenzbereichCollection(fachbereich?: string, fachbereich2?: Array<string>, page?: number, options?: any): AxiosPromise<Array<KompetenzbereichKompetenzbereiche>> {
            return localVarFp.getKompetenzbereichCollection(fachbereich, fachbereich2, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Kompetenzbereich resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKompetenzbereichItem(id: string, options?: any): AxiosPromise<KompetenzbereichKompetenzbereich> {
            return localVarFp.getKompetenzbereichItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KompetenzbereichApi - object-oriented interface
 * @export
 * @class KompetenzbereichApi
 * @extends {BaseAPI}
 */
export class KompetenzbereichApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of Kompetenzbereich resources.
     * @param {string} [fachbereich] 
     * @param {Array<string>} [fachbereich2] 
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KompetenzbereichApi
     */
    public getKompetenzbereichCollection(fachbereich?: string, fachbereich2?: Array<string>, page?: number, options?: AxiosRequestConfig) {
        return KompetenzbereichApiFp(this.configuration).getKompetenzbereichCollection(fachbereich, fachbereich2, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Kompetenzbereich resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KompetenzbereichApi
     */
    public getKompetenzbereichItem(id: string, options?: AxiosRequestConfig) {
        return KompetenzbereichApiFp(this.configuration).getKompetenzbereichItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PupilApi - axios parameter creator
 * @export
 */
export const PupilApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Removes the Pupil resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePupilItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePupilItem', 'id', id)
            const localVarPath = `/pupils/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the collection of Pupil resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPupilCollection: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pupils`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Pupil resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPupilItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPupilItem', 'id', id)
            const localVarPath = `/pupils/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the Pupil resource.
         * @param {string} id 
         * @param {Pupil} [pupil] The updated Pupil resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPupilItem: async (id: string, pupil?: Pupil, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchPupilItem', 'id', id)
            const localVarPath = `/pupils/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pupil, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a Pupil resource.
         * @param {Pupil} [pupil] The new Pupil resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPupilCollection: async (pupil?: Pupil, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pupils`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pupil, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PupilApi - functional programming interface
 * @export
 */
export const PupilApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PupilApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Removes the Pupil resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePupilItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePupilItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the collection of Pupil resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPupilCollection(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PupilPupils>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPupilCollection(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Pupil resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPupilItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PupilPupil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPupilItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the Pupil resource.
         * @param {string} id 
         * @param {Pupil} [pupil] The updated Pupil resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPupilItem(id: string, pupil?: Pupil, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PupilPupil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPupilItem(id, pupil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a Pupil resource.
         * @param {Pupil} [pupil] The new Pupil resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPupilCollection(pupil?: Pupil, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PupilPupil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPupilCollection(pupil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PupilApi - factory interface
 * @export
 */
export const PupilApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PupilApiFp(configuration)
    return {
        /**
         * 
         * @summary Removes the Pupil resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePupilItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePupilItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the collection of Pupil resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPupilCollection(page?: number, options?: any): AxiosPromise<Array<PupilPupils>> {
            return localVarFp.getPupilCollection(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Pupil resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPupilItem(id: string, options?: any): AxiosPromise<PupilPupil> {
            return localVarFp.getPupilItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the Pupil resource.
         * @param {string} id 
         * @param {Pupil} [pupil] The updated Pupil resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPupilItem(id: string, pupil?: Pupil, options?: any): AxiosPromise<PupilPupil> {
            return localVarFp.patchPupilItem(id, pupil, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a Pupil resource.
         * @param {Pupil} [pupil] The new Pupil resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPupilCollection(pupil?: Pupil, options?: any): AxiosPromise<PupilPupil> {
            return localVarFp.postPupilCollection(pupil, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PupilApi - object-oriented interface
 * @export
 * @class PupilApi
 * @extends {BaseAPI}
 */
export class PupilApi extends BaseAPI {
    /**
     * 
     * @summary Removes the Pupil resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PupilApi
     */
    public deletePupilItem(id: string, options?: AxiosRequestConfig) {
        return PupilApiFp(this.configuration).deletePupilItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the collection of Pupil resources.
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PupilApi
     */
    public getPupilCollection(page?: number, options?: AxiosRequestConfig) {
        return PupilApiFp(this.configuration).getPupilCollection(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Pupil resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PupilApi
     */
    public getPupilItem(id: string, options?: AxiosRequestConfig) {
        return PupilApiFp(this.configuration).getPupilItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the Pupil resource.
     * @param {string} id 
     * @param {Pupil} [pupil] The updated Pupil resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PupilApi
     */
    public patchPupilItem(id: string, pupil?: Pupil, options?: AxiosRequestConfig) {
        return PupilApiFp(this.configuration).patchPupilItem(id, pupil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a Pupil resource.
     * @param {Pupil} [pupil] The new Pupil resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PupilApi
     */
    public postPupilCollection(pupil?: Pupil, options?: AxiosRequestConfig) {
        return PupilApiFp(this.configuration).postPupilCollection(pupil, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScheduleApi - axios parameter creator
 * @export
 */
export const ScheduleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Removes the Schedule resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduleItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteScheduleItem', 'id', id)
            const localVarPath = `/schedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the collection of Schedule resources.
         * @param {string} [pupil] 
         * @param {Array<string>} [pupil2] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleCollection: async (pupil?: string, pupil2?: Array<string>, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pupil !== undefined) {
                localVarQueryParameter['pupil'] = pupil;
            }

            if (pupil2) {
                localVarQueryParameter['pupil[]'] = pupil2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Schedule resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScheduleItem', 'id', id)
            const localVarPath = `/schedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the Schedule resource.
         * @param {string} id 
         * @param {Schedule} [schedule] The updated Schedule resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchScheduleItem: async (id: string, schedule?: Schedule, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchScheduleItem', 'id', id)
            const localVarPath = `/schedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schedule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a Schedule resource.
         * @param {Schedule} [schedule] The new Schedule resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postScheduleCollection: async (schedule?: Schedule, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schedule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScheduleApi - functional programming interface
 * @export
 */
export const ScheduleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScheduleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Removes the Schedule resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScheduleItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScheduleItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the collection of Schedule resources.
         * @param {string} [pupil] 
         * @param {Array<string>} [pupil2] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduleCollection(pupil?: string, pupil2?: Array<string>, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduleSchedules>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduleCollection(pupil, pupil2, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Schedule resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduleItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduleItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the Schedule resource.
         * @param {string} id 
         * @param {Schedule} [schedule] The updated Schedule resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchScheduleItem(id: string, schedule?: Schedule, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchScheduleItem(id, schedule, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a Schedule resource.
         * @param {Schedule} [schedule] The new Schedule resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postScheduleCollection(schedule?: Schedule, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postScheduleCollection(schedule, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScheduleApi - factory interface
 * @export
 */
export const ScheduleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScheduleApiFp(configuration)
    return {
        /**
         * 
         * @summary Removes the Schedule resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduleItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteScheduleItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the collection of Schedule resources.
         * @param {string} [pupil] 
         * @param {Array<string>} [pupil2] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleCollection(pupil?: string, pupil2?: Array<string>, page?: number, options?: any): AxiosPromise<Array<ScheduleSchedules>> {
            return localVarFp.getScheduleCollection(pupil, pupil2, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Schedule resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleItem(id: string, options?: any): AxiosPromise<ScheduleSchedule> {
            return localVarFp.getScheduleItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the Schedule resource.
         * @param {string} id 
         * @param {Schedule} [schedule] The updated Schedule resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchScheduleItem(id: string, schedule?: Schedule, options?: any): AxiosPromise<ScheduleSchedule> {
            return localVarFp.patchScheduleItem(id, schedule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a Schedule resource.
         * @param {Schedule} [schedule] The new Schedule resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postScheduleCollection(schedule?: Schedule, options?: any): AxiosPromise<ScheduleSchedule> {
            return localVarFp.postScheduleCollection(schedule, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScheduleApi - object-oriented interface
 * @export
 * @class ScheduleApi
 * @extends {BaseAPI}
 */
export class ScheduleApi extends BaseAPI {
    /**
     * 
     * @summary Removes the Schedule resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public deleteScheduleItem(id: string, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).deleteScheduleItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the collection of Schedule resources.
     * @param {string} [pupil] 
     * @param {Array<string>} [pupil2] 
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public getScheduleCollection(pupil?: string, pupil2?: Array<string>, page?: number, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).getScheduleCollection(pupil, pupil2, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Schedule resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public getScheduleItem(id: string, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).getScheduleItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the Schedule resource.
     * @param {string} id 
     * @param {Schedule} [schedule] The updated Schedule resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public patchScheduleItem(id: string, schedule?: Schedule, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).patchScheduleItem(id, schedule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a Schedule resource.
     * @param {Schedule} [schedule] The new Schedule resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public postScheduleCollection(schedule?: Schedule, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).postScheduleCollection(schedule, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ThemenaspektApi - axios parameter creator
 * @export
 */
export const ThemenaspektApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of Themenaspekt resources.
         * @param {string} [kompetenzbereich] 
         * @param {Array<string>} [kompetenzbereich2] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThemenaspektCollection: async (kompetenzbereich?: string, kompetenzbereich2?: Array<string>, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/themenaspekte`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (kompetenzbereich !== undefined) {
                localVarQueryParameter['kompetenzbereich'] = kompetenzbereich;
            }

            if (kompetenzbereich2) {
                localVarQueryParameter['kompetenzbereich[]'] = kompetenzbereich2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Themenaspekt resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThemenaspektItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getThemenaspektItem', 'id', id)
            const localVarPath = `/themenaspekte/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ThemenaspektApi - functional programming interface
 * @export
 */
export const ThemenaspektApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ThemenaspektApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Themenaspekt resources.
         * @param {string} [kompetenzbereich] 
         * @param {Array<string>} [kompetenzbereich2] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThemenaspektCollection(kompetenzbereich?: string, kompetenzbereich2?: Array<string>, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThemenaspektThemenaspekte>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThemenaspektCollection(kompetenzbereich, kompetenzbereich2, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Themenaspekt resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThemenaspektItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThemenaspektThemenaspekt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThemenaspektItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ThemenaspektApi - factory interface
 * @export
 */
export const ThemenaspektApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ThemenaspektApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Themenaspekt resources.
         * @param {string} [kompetenzbereich] 
         * @param {Array<string>} [kompetenzbereich2] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThemenaspektCollection(kompetenzbereich?: string, kompetenzbereich2?: Array<string>, page?: number, options?: any): AxiosPromise<Array<ThemenaspektThemenaspekte>> {
            return localVarFp.getThemenaspektCollection(kompetenzbereich, kompetenzbereich2, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Themenaspekt resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThemenaspektItem(id: string, options?: any): AxiosPromise<ThemenaspektThemenaspekt> {
            return localVarFp.getThemenaspektItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ThemenaspektApi - object-oriented interface
 * @export
 * @class ThemenaspektApi
 * @extends {BaseAPI}
 */
export class ThemenaspektApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of Themenaspekt resources.
     * @param {string} [kompetenzbereich] 
     * @param {Array<string>} [kompetenzbereich2] 
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThemenaspektApi
     */
    public getThemenaspektCollection(kompetenzbereich?: string, kompetenzbereich2?: Array<string>, page?: number, options?: AxiosRequestConfig) {
        return ThemenaspektApiFp(this.configuration).getThemenaspektCollection(kompetenzbereich, kompetenzbereich2, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Themenaspekt resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThemenaspektApi
     */
    public getThemenaspektItem(id: string, options?: AxiosRequestConfig) {
        return ThemenaspektApiFp(this.configuration).getThemenaspektItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TopicApi - axios parameter creator
 * @export
 */
export const TopicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of Topic resources.
         * @param {string} id 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHouseholdsTopicsGetSubresource: async (id: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiHouseholdsTopicsGetSubresource', 'id', id)
            const localVarPath = `/households/{id}/topics`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the Topic resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTopicItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTopicItem', 'id', id)
            const localVarPath = `/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the collection of Topic resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicCollection: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Topic resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTopicItem', 'id', id)
            const localVarPath = `/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the Topic resource.
         * @param {string} id 
         * @param {Topic} [topic] The updated Topic resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTopicItem: async (id: string, topic?: Topic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchTopicItem', 'id', id)
            const localVarPath = `/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(topic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a Topic resource.
         * @param {Topic} [topic] The new Topic resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTopicCollection: async (topic?: Topic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(topic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TopicApi - functional programming interface
 * @export
 */
export const TopicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TopicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Topic resources.
         * @param {string} id 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHouseholdsTopicsGetSubresource(id: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopicTopic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHouseholdsTopicsGetSubresource(id, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes the Topic resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTopicItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTopicItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the collection of Topic resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopicCollection(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopicTopics>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopicCollection(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Topic resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopicItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicTopic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopicItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the Topic resource.
         * @param {string} id 
         * @param {Topic} [topic] The updated Topic resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTopicItem(id: string, topic?: Topic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicTopic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchTopicItem(id, topic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a Topic resource.
         * @param {Topic} [topic] The new Topic resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTopicCollection(topic?: Topic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicTopic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTopicCollection(topic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TopicApi - factory interface
 * @export
 */
export const TopicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TopicApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of Topic resources.
         * @param {string} id 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHouseholdsTopicsGetSubresource(id: string, page?: number, options?: any): AxiosPromise<Array<TopicTopic>> {
            return localVarFp.apiHouseholdsTopicsGetSubresource(id, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the Topic resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTopicItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTopicItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the collection of Topic resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicCollection(page?: number, options?: any): AxiosPromise<Array<TopicTopics>> {
            return localVarFp.getTopicCollection(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Topic resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicItem(id: string, options?: any): AxiosPromise<TopicTopic> {
            return localVarFp.getTopicItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the Topic resource.
         * @param {string} id 
         * @param {Topic} [topic] The updated Topic resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTopicItem(id: string, topic?: Topic, options?: any): AxiosPromise<TopicTopic> {
            return localVarFp.patchTopicItem(id, topic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a Topic resource.
         * @param {Topic} [topic] The new Topic resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTopicCollection(topic?: Topic, options?: any): AxiosPromise<TopicTopic> {
            return localVarFp.postTopicCollection(topic, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TopicApi - object-oriented interface
 * @export
 * @class TopicApi
 * @extends {BaseAPI}
 */
export class TopicApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of Topic resources.
     * @param {string} id 
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public apiHouseholdsTopicsGetSubresource(id: string, page?: number, options?: AxiosRequestConfig) {
        return TopicApiFp(this.configuration).apiHouseholdsTopicsGetSubresource(id, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the Topic resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public deleteTopicItem(id: string, options?: AxiosRequestConfig) {
        return TopicApiFp(this.configuration).deleteTopicItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the collection of Topic resources.
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public getTopicCollection(page?: number, options?: AxiosRequestConfig) {
        return TopicApiFp(this.configuration).getTopicCollection(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Topic resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public getTopicItem(id: string, options?: AxiosRequestConfig) {
        return TopicApiFp(this.configuration).getTopicItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the Topic resource.
     * @param {string} id 
     * @param {Topic} [topic] The updated Topic resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public patchTopicItem(id: string, topic?: Topic, options?: AxiosRequestConfig) {
        return TopicApiFp(this.configuration).patchTopicItem(id, topic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a Topic resource.
     * @param {Topic} [topic] The new Topic resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public postTopicCollection(topic?: Topic, options?: AxiosRequestConfig) {
        return TopicApiFp(this.configuration).postTopicCollection(topic, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Removes the Upload resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUploadItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUploadItem', 'id', id)
            const localVarPath = `/uploads/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the collection of Upload resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadCollection: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/uploads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a Upload resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUploadItem', 'id', id)
            const localVarPath = `/uploads/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the Upload resource.
         * @param {string} id 
         * @param {Upload} [upload] The updated Upload resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUploadItem: async (id: string, upload?: Upload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchUploadItem', 'id', id)
            const localVarPath = `/uploads/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a Upload resource.
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadCollection: async (file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/uploads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Removes the Upload resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUploadItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUploadItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the collection of Upload resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadCollection(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UploadUpload>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadCollection(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a Upload resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the Upload resource.
         * @param {string} id 
         * @param {Upload} [upload] The updated Upload resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUploadItem(id: string, upload?: Upload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUploadItem(id, upload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a Upload resource.
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploadCollection(file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploadCollection(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration)
    return {
        /**
         * 
         * @summary Removes the Upload resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUploadItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUploadItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the collection of Upload resources.
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadCollection(page?: number, options?: any): AxiosPromise<Array<UploadUpload>> {
            return localVarFp.getUploadCollection(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a Upload resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadItem(id: string, options?: any): AxiosPromise<UploadUpload> {
            return localVarFp.getUploadItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the Upload resource.
         * @param {string} id 
         * @param {Upload} [upload] The updated Upload resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUploadItem(id: string, upload?: Upload, options?: any): AxiosPromise<UploadUpload> {
            return localVarFp.patchUploadItem(id, upload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a Upload resource.
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadCollection(file?: any, options?: any): AxiosPromise<UploadUpload> {
            return localVarFp.postUploadCollection(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * 
     * @summary Removes the Upload resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public deleteUploadItem(id: string, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).deleteUploadItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the collection of Upload resources.
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public getUploadCollection(page?: number, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).getUploadCollection(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a Upload resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public getUploadItem(id: string, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).getUploadItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the Upload resource.
     * @param {string} id 
     * @param {Upload} [upload] The updated Upload resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public patchUploadItem(id: string, upload?: Upload, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).patchUploadItem(id, upload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a Upload resource.
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public postUploadCollection(file?: any, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).postUploadCollection(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the collection of User resources.
         * @param {string} [email] 
         * @param {Array<string>} [email2] 
         * @param {Array<string>} [groups] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCollection: async (email?: string, email2?: Array<string>, groups?: Array<string>, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (email2) {
                localVarQueryParameter['email[]'] = email2;
            }

            if (groups) {
                localVarQueryParameter['groups[]'] = groups;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a User resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserItem', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a User resource.
         * @param {User} [user] The new User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserCollection: async (user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of User resources.
         * @param {string} [email] 
         * @param {Array<string>} [email2] 
         * @param {Array<string>} [groups] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCollection(email?: string, email2?: Array<string>, groups?: Array<string>, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserUsers>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCollection(email, email2, groups, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a User resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a User resource.
         * @param {User} [user] The new User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserCollection(user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserCollection(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the collection of User resources.
         * @param {string} [email] 
         * @param {Array<string>} [email2] 
         * @param {Array<string>} [groups] 
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCollection(email?: string, email2?: Array<string>, groups?: Array<string>, page?: number, options?: any): AxiosPromise<Array<UserUsers>> {
            return localVarFp.getUserCollection(email, email2, groups, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a User resource.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserItem(id: string, options?: any): AxiosPromise<UserUser> {
            return localVarFp.getUserItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a User resource.
         * @param {User} [user] The new User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserCollection(user?: User, options?: any): AxiosPromise<UserUser> {
            return localVarFp.postUserCollection(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the collection of User resources.
     * @param {string} [email] 
     * @param {Array<string>} [email2] 
     * @param {Array<string>} [groups] 
     * @param {number} [page] The collection page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserCollection(email?: string, email2?: Array<string>, groups?: Array<string>, page?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserCollection(email, email2, groups, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a User resource.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserItem(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a User resource.
     * @param {User} [user] The new User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUserCollection(user?: User, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postUserCollection(user, options).then((request) => request(this.axios, this.basePath));
    }
}


