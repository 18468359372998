import React, { useEffect, useMemo, useState } from 'react';
import { useApi, useUser } from "../../lib/contexts";
import { Loader, PageTitle } from "../../components";
import { natsort } from "../../lib/helpers";

export default function ProgressReport({ pupil }) {
  const api = useApi();
  const user = useUser();

  const lists = user.household.boardLists;
  const lastListId = lists[lists.length - 1].boardListId;

  const [zyklus, setZyklus] = useState(pupil.zyklus.toString());
  const [fachbereiche, setFachbereiche] = useState(null);
  const [statsByListAndKompetenzbereichId, setStatsByListAndKompetenzbereichId] = useState({});
  const [statsByListAndFachbereichId, setStatsByListAndFachbereichId] = useState({});

  useEffect(() => {
    api.fachbereiche().getFachbereichCollection()
      .then(rsp => rsp.data)
      .then(fachbereiche => fachbereiche.filter(f => (f.numGrundansprueche[Number(zyklus)] || 0) > 0))
      .then(setFachbereiche)
      .catch(err => console.log(err));
    
    for (const list of lists) {
      api.boardLists().getBoardListItem(list.boardListId)
        .then(rsp => {
          const items = rsp.data.items.filter(i => i.pupil.pupilId === pupil.pupilId);

          const byKompetenzbereich = {};
          const byFachbereich = {};
          for (const item of items) {
            if (!item.grundanspruch.zyklen.includes(Number(zyklus))) {
              continue;
            }

            const kompetenzbereichId = item.grundanspruch.kompetenzbereichId;
            if (byKompetenzbereich[kompetenzbereichId] === undefined) {
              byKompetenzbereich[kompetenzbereichId] = 0;
            }
            byKompetenzbereich[kompetenzbereichId]++;

            const fachbereichId = item.grundanspruch.fachbereichId;
            if (byFachbereich[fachbereichId] === undefined) {
              byFachbereich[fachbereichId] = 0;
            }
            byFachbereich[fachbereichId]++;
          }

          setStatsByListAndKompetenzbereichId(stats => ({
            ...stats,
            [list.boardListId]: byKompetenzbereich,
          }));
          setStatsByListAndFachbereichId(stats => ({
            ...stats,
            [list.boardListId]: byFachbereich,
          }));
        })
        .catch(err => console.error(err));
    }
  }, [zyklus]);

  const sorter = natsort();
  
  const totalGrundansprueche = useMemo(() => {
    if (!fachbereiche) {
      return 0;
    }
    return fachbereiche.reduce((tot, f) => tot + f.numGrundansprueche[Number(zyklus)] || 0, 0);
  }, [fachbereiche, zyklus]);

  const totalGrundanspruecheByListId = useMemo(() => {
    if (Object.keys(statsByListAndFachbereichId).length !== lists.length) {
      return [];
    }

    const tots = [];
    for (const list of lists) {
      tots[list.boardListId] = Object.values(statsByListAndFachbereichId[list.boardListId]).reduce((tot, stats) => tot + stats, 0);
    }

    return tots;
  }, [lists, statsByListAndFachbereichId]);

  const totalOpenGrundansprueche = useMemo(() => {
    const onBoard = Object.values(totalGrundanspruecheByListId).reduce((a, b) => a + b, 0);
    
    return totalGrundansprueche - onBoard;
  }, [totalGrundansprueche, totalGrundanspruecheByListId]);

  if (fachbereiche === null || Object.keys(statsByListAndKompetenzbereichId).length !== lists.length || Object.keys(statsByListAndFachbereichId).length !== lists.length) {
    return <Loader/>;
  }

  return <>
    <PageTitle title="Fortschritt Grundansprüche"/>

    <nav className="level">
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Total</p>
          <p className="title">{totalGrundansprueche}</p>
        </div>
      </div>
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Offen</p>
          <p className="title">
            <ValueAndProgress highlight large value={totalOpenGrundansprueche} total={totalGrundansprueche}/>
          </p>
        </div>
      </div>
      {lists.map(list => (
        <div key={list.boardListId} className="level-item has-text-centered">
          <div>
            <p className="heading">{list.name}</p>
            <p className="title">
              <ValueAndProgress highlight large value={totalGrundanspruecheByListId[list.boardListId]} total={totalGrundansprueche}/>
            </p>
          </div>
        </div>
      ))}
    </nav>

    <div className="p-3">
      <div className="select">
        <select className="has-text-centered" value={zyklus} onChange={e => setZyklus(e.target.value)}>
          <option value="1">1. Zyklus (KiGa - 2. Klasse)</option>
          <option value="2">2. Zyklus (3. - 6. Klasse)</option>
          <option value="3">3. Zyklus (7. - 9. Klasse)</option>
        </select>
      </div>
    </div>

    {fachbereiche.map(fachbereich => {
      const total = fachbereich.numGrundansprueche[zyklus];

      let onBoard = 0;
      for (const [_, statsByFachbereichId] of Object.entries(statsByListAndFachbereichId)) {
        onBoard += statsByFachbereichId[fachbereich.fachbereichId] || 0;
      }
      const open = total - onBoard;

      return <div key={fachbereich.fachbereichId} className="box is-radiusless">
        <table className="table is-striped is-hoverable is-fullwidth">
          <thead>
          <tr>
            <th colSpan="2">{fachbereich.name}</th>
            <th className="has-text-centered">Total</th>
            <th className="has-text-centered">Offen</th>
            {lists.map(list => <th key={list.boardListId} className="has-text-centered">{list.name}</th>)}
          </tr>
          </thead>
          <tfoot>
          <tr>
            <td/>
            <th>Alle Kompetenzbereiche</th>
            <th className="has-text-centered">{total}</th>
            <th className="has-text-centered"><ValueAndProgress value={open} total={total} highlight highIsGood={false}/></th>
            {lists.map(list => {
              const count = statsByListAndFachbereichId[list.boardListId][fachbereich.fachbereichId] || 0;

              return <th key={list.boardListId} className="has-text-centered">
                <ValueAndProgress value={count} total={total} highlight={list.boardListId === lastListId}/>
              </th>;
            })}
          </tr>
          </tfoot>
          <tbody>
          {fachbereich.kompetenzbereiche.sort((a, b) => sorter(a.code.id, b.code.id)).map(kompetenzbereich => {
            const total = kompetenzbereich.numGrundansprueche[zyklus];

            let onBoard = 0;
            for (const [_, statsByKompetenzbereichId] of Object.entries(statsByListAndKompetenzbereichId)) {
              onBoard += statsByKompetenzbereichId[kompetenzbereich.kompetenzbereichId] || 0;
            }
            const open = total - onBoard;

            return <tr key={kompetenzbereich.kompetenzbereichId}>
              <td width={50} className="has-text-right">{kompetenzbereich.code.id}</td>
              <td>{kompetenzbereich.name}</td>
              <td width={150} className="has-text-centered">{total}</td>
              <td width={150} className="has-text-centered"><ValueAndProgress value={open} total={total} highlight highIsGood={false}/></td>
              {lists.map(list => {
                const count = statsByListAndKompetenzbereichId[list.boardListId][kompetenzbereich.kompetenzbereichId] || 0;

                return <td key={list.boardListId} width={150} className="has-text-centered">
                  <ValueAndProgress value={count} total={total} highlight={list.boardListId === lastListId}/>
                </td>;
              })}
            </tr>
          })}
          </tbody>
        </table>
      </div>
    })}
  </>
}

function ValueAndProgress({ value, total, highlight = false, large = false, highIsGood = true }) {
  const progress = Math.round(value / total * 100);

  let classes = '';
  if (highlight) {
    const indicator = highIsGood ? progress : 100 - progress;

    classes = 'tag ';
    if (indicator > 75) {
      classes += 'is-success ';
    } else if (indicator > 50) {
      classes += 'is-info ';
    } else if (indicator > 25) {
      classes += 'is-warning ';
    } else {
      classes += 'is-danger ';
    }

    if (large) {
      classes += 'is-large';
    }
  }

  return <span className={classes}>{value} ({progress}%)</span>
}