import React from 'react';
import {render} from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import de from 'date-fns/locale/de';
import App from './App';

library.add(fas);

registerLocale('de', de)
setDefaultLocale('de');

render(
<React.StrictMode>
  <App/>
</React.StrictMode>,
document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
