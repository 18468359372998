import React, {useEffect, useState} from 'react';
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import {Loader} from "./components";
import {ApiContext, AuthContext, DialogsProvider} from "./lib/contexts";
import LoginScreen from "./screens/LoginScreen";
import Navigator from "./screens/Navigator";

import './assets/styles/style.scss';

export default function App() {
  return <Auth0Provider domain="kanban21.eu.auth0.com"
                        clientId="fc8yIU16IQ1OLsgjI2qfEGaOSk8OysHM"
                        audience="api"
                        scope="read:current_user"
                        redirectUri={window.location.origin}>
    <AuthContextSwitch/>
  </Auth0Provider>
}

function AuthContextSwitch() {
  const {isAuthenticated, isLoading} = useAuth0();

  if (isLoading) {
    return <Loader/>
  }

  if (isAuthenticated) {
    return <Contexts>
      <Navigator/>
    </Contexts>
  }

  return <LoginScreen/>
}

function Contexts({children}) {
  const {user, getAccessTokenSilently} = useAuth0();
  const [accessToken, setAccessToken] = useState();

  useEffect(() => {
    getAccessTokenSilently().then(data => setAccessToken(data));
  }, []);

  if (!accessToken) {
    return <Loader/>
  }

  const apiConfig = {
    apiKey: 'Bearer ' + accessToken,
    basePath: process.env.REACT_APP_API_DOMAIN,
  };

  return <ApiContext.Provider value={apiConfig}>
    <AuthContext.Provider value={{user, accessToken}}>
      <DialogsProvider>
        {children}
      </DialogsProvider>
    </AuthContext.Provider>
  </ApiContext.Provider>
}