import React, {useEffect, useReducer, useState} from 'react';
import {useApi, useDialogs, useUser} from "../lib/contexts";

import Layout from "./Layout";
import {ActionButton, Loader, PageTitle} from "../components";
import {AddTopicDialog} from "./Topic/AddTopicDialog";
import {TopicDialog} from "./Topic/TopicDialog";
import {formatShortDate} from "../lib/time";

export default function TopicsScreen() {
  const api = useApi();
  const user = useUser();
  const dialogs = useDialogs();

  const [topics, setTopics] = useState(null);

  const loadTopics = () => {
    api.households().apiHouseholdsTopicsGetSubresource(user.household.householdId)
    .then(rsp => setTopics(rsp.data))
    .catch(err => console.log(err));
  };

  useEffect(() => {
    loadTopics();
  }, []);

  if (topics === null) {
    return <Layout activeMenuKey="topics">
      <Loader/>
    </Layout>
  }

  const handleAddTopic = () => {
    dialogs.open(<AddTopicDialog onTopicAdded={loadTopics} onClose={dialogs.close}/>);
  };

  const createHandleRowClick = topic => () => {
    dialogs.open(<TopicDialog topicId={topic.topicId} onTopicChanged={loadTopics} onClose={dialogs.close}/>);
  };

  return <Layout activeMenuKey="topics">
    <section id="content">
      <PageTitle title="Themen" actionButtons={<ActionButton label="Thema hinzufügen" onClick={handleAddTopic}/>}/>

      {topics.map(topic => <div key={topic.topicId} className="box topic-item mb-3">
        <div className="columns is-clickable" onClick={createHandleRowClick(topic)}>
          <div className="column is-2">
            {topic.cover ? <figure className="image is-16by9">
              <img src={process.env.REACT_APP_API_DOMAIN + topic.cover.contentUrl} alt={`Foto zum Thema ${topic.title}`}/>
            </figure> : null}
          </div>
          <div className="column is-10">
            <h2 className="title is-5">{topic.title}</h2>
            <p className="subtitle is-7">Geändert am {formatShortDate(topic.updatedAt || topic.addedAt)}</p>
            <p>{topic.abstract}</p>
          </div>
        </div>
      </div>)}
    </section>
  </Layout>
}
